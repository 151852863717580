<template>
    <div class="checklist">
        <ul>
            <li v-for="(item,index) in items" v-bind:key="index+item">
                <Checked v-if="!numbers"/>
                <span class="checklist_index" v-else>{{parseInt(index)+1}}</span>
                <div class="checklist_item">
                    <span v-html="item"></span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Checked from './../../svg/checked'
export default {
    name: 'quote',
    components:
    {
        Checked
    },
    props:
    {
        items: Object,
        numbers: Boolean
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .checklist
    {
        display:block;
        ul
        {

        }
        svg,
        .checklist_index
        {
            color:$primary;
            font-size: 24px;
            font-weight: 700;

            width:24px;
            margin-right:18px;
        }
        .checklist_index:after
        {
            content:".";
        }
        li
        {
            display:flex;
            margin-bottom:$grid-gutter-width/2;
            .checklist_title
            {
                color:$primary;
                font-weight: bold;
                display:block;
                min-width: 100%;
                font-size: 18px;
                margin-top:0.5rem;
                margin-bottom:0.5rem;
            }
            span
            {
                font-size:16px;
            }
            .checklist_item
            {
                flex:1;
                width:calc(100% - 42px);
                display:flex;
                flex-wrap: wrap;
                align-items:center;
            }
        }
    }
</style>