<template>
    <div :class="'blog-topbar ' + categorySlug">
        <div @click="mixinGoTo(goto,{})">
            <div class="topbar">
                <b-container fluid>
                    <h1 class="page-title pointer" v-html="title"></h1>
                    <p class="page-subtitle" v-html="subtitle"></p>
                </b-container>
            </div>
        </div>
    </div>
</template>


<script>
    //import Vue from 'vue'

    export default {
        name: 'blog-topbar',
        components: {
        },
        props:
        {
            categorySlug: String,
            goto: String,
        },
        computed:
        {
        }, 
        data(){
            return {
                title: '',
                subtitle: '',
                //category: '',
            }
        },
        methods:
        {
        },
        mounted()
        {
            switch (this.categorySlug) {
                case 'business':
                    //this.category = this.config.posts.categoryBusiness
                    this.title = 'BLOG - <span class="normal">IN | Biz</span>'
                    this.subtitle = 'Lasciati ispirare! Qui troverai idee, consigli, suggestioni per vivere esperienze di viaggio straordinarie con Salabam.'
                    break;
            
                default:
                    //this.category = this.config.posts.category
                    this.title = 'BLOG - <span class="normal">La scelta dello staff</span>'
                    this.subtitle = 'Viaggi, welfare aziendale, loyalty e marketing. Approfondimenti su travel e aziende.'
                    break;
            }
        }
    }
</script>


<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .blog-topbar
    {
        .page-subtitle
        {
            margin-bottom:0;
        }
        .topbar
        {
            padding:$grid-gutter-width/2 0;
        }

        &.consumer
        {
            .topbar
            {
                background:$primary;
            }
            .page-title,
            .page-subtitle
            {
                color:$black;
            }
        }

        &.business
        {
            .topbar
            {
                background:$dark-blue;
            }
            .page-title,
            .page-subtitle
            {
                color:$white;
            }
        }
    }
</style>