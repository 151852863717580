var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "90",
        height: "67",
        viewBox: "0 0 90 67",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M64.3002 41.2C64.3002 35.1 51.7002 34.2 51.7002 19.2C51.7002 8.2 59.6002 0 70.6002 0C82.3002 0.1 90.0002 10.6 90.0002 21.7C90.0002 40.9 72.0002 66.3 60.8002 66.3C58.0002 66.3 52.9002 64.3 52.9002 60.9C53.0002 57.5 64.3002 51.6 64.3002 41.2Z",
          fill: "#FBB03B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.6 41.2C12.6 35.2 0 34.2 0 19.3C0 8.29997 7.9 0.0999756 18.9 0.0999756C30.7 0.0999756 38.4 10.6 38.4 21.7C38.4 40.9 20.4 66.3 9.2 66.3C6.4 66.3 1.3 64.3 1.3 60.9C1.3 57.5 12.6 51.6 12.6 41.2Z",
          fill: "#FBB03B",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }