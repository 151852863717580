<template>
    <div class="blog">
        <div class="blog-header">
            <HeaderSmall />
            <ScrollDown :targetId="'scrollTo'" />
        </div>
        <div class="blog-content">
            <b-container fluid>
                <h1 class="page-title black text-left">
                    BLOG <span class="normal">- Approfondimenti, consigli e curiosità da Salabam</span>
                </h1>
            </b-container>

            <section class="blog-section consumer" id="scrollTo">
                <b-row>
                    <b-col sm="12" lg=5>
                        <div class="blog-info">
                            <h2 class="blog-title">
                                BLOG <span class="normal">- La scelta dello staff <InfoBlue /></span>
                            </h2>
                            <p>
                                Lasciati ispirare! Qui troverai idee, consigli, suggestioni per vivere esperienze di viaggio straordinarie con Salabam.
                            </p>
                            <router-link :to="{name: 'blog-consumer'}">
                                <b-button size="md">Vedi tutti i post</b-button>
                            </router-link>
                        </div>
                    </b-col>
                    <b-col sm="12" lg=7>
                        <PostSlider  :category="config.posts.category" :maxSlidesToShow="2" />
                    </b-col>
                </b-row>
            </section>

            <!--
            <section class="blog-section business">
                <b-row>
                    <b-col sm="12" lg=5>
                        <div class="blog-info">
                            <h2 class="blog-title">
                                BLOG <span class="normal">- IN | Biz <InfoBlue /></span>
                            </h2>
                            <p>
                                Viaggi, welfare aziendale, loyalty e marketing. Approfondimenti su travel e aziende
                            </p>
                            <router-link :to="{name: 'blog-business'}">
                                <b-button size="md">Vedi tutti i post</b-button>
                            </router-link>
                        </div>
                    </b-col>
                    <b-col sm="12" lg=7>
                        <PostSlider  :category="config.posts.categoryBusiness" :maxSlidesToShow="2" />
                    </b-col>
                </b-row>
            </section>
            -->
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import HeaderSmall from '@/components/blocks/header-small'
    import PostSlider from '@/components/blocks/post-slider'
    import InfoBlue from '@/components/svg/info-blue'
    import ScrollDown from '@/components/atoms/scroll-down'

    export default {
        name: 'blog',
        metaInfo: {
            title: 'Blog',
            meta: [
              { vmid: 'metaTitle', name: 'Title', content: 'Blog' },
              { vmid: 'metaDescription', name: 'Description', content: 'Approfondimenti, consigli e curiosità da Salabam.' },
            ],
        },
        components: {
            HeaderSmall,
            PostSlider,
            InfoBlue,
            ScrollDown,
        },
        computed:
        {
        }, 
        data(){
            return {
                config: Vue.prototype.$config,
            }
        },
        methods:
        {
        }
    }
</script>


<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .blog
    {
        .blog-header
        {
            position:relative;
            #scrollDown
            {
                position:absolute;
                bottom: -36px !important;
            }
        }

        .topbar
        {
            margin-bottom:$grid-gutter-width/2;
            @include media-breakpoint-up(md)
            {
                margin-bottom:$grid-gutter-width;
            }
        }

        .page-title
        {
            padding:$grid-gutter-width $grid-gutter-width/2;
            @include media-breakpoint-up(lg)
            {
                padding:$grid-gutter-width*1.5 $grid-gutter-width/2;
            }
        }

        .blog-content
        {
            //padding:$grid-gutter-width $grid-gutter-width/2;
        }

        .postSlider
        {
            padding:0 $grid-gutter-width/2;
            .slick
            {
                margin-top:0;
            }
            .sliderTitle,
            .postPad__date
            {
                display:none !important;
            }
            .slick-slider
            {
                max-width:calc(100% - 80px) !important;
            }
        }

        &-section
        {
            margin-bottom:$grid-gutter-width;
            @include media-breakpoint-up(lg)
            {
                margin-bottom:$grid-gutter-width*3;
            }
            .blog-info
            {
                height:100%;
                padding:$grid-gutter-width/2;
                margin-bottom:2rem;
                @include media-breakpoint-up(lg)
                {
                    margin-bottom:0;
                    padding:$grid-gutter-width;
                    display:flex;
                    flex-direction:column;
                    justify-content: center;
                }
                .btn
                {
                    box-shadow:none !important;
                    margin-top:1rem;
                    @include media-breakpoint-up(lg)
                    {
                        margin-top:2rem;
                        float:right;
                    }
                }
            }
            .blog-title
            {
                margin-bottom:3rem;
                svg
                {
                    width:16px;
                    height:auto;
                    transform:translateY(-50%);
                }
                @include media-breakpoint-down(md)
                {
                    margin-bottom:1rem;
                    font-size: 1rem;
                }
            }
            &.consumer
            {
                .blog-info
                {
                    background:$primary;
                    @include media-breakpoint-up(lg)
                    {
                        border-top-right-radius:$border-radius-large;
                        border-bottom-right-radius:$border-radius-large;
                    }
                }
                .blog-title
                {
                    color:$black;
                }
                @include media-breakpoint-up(lg)
                {
                    .postSlider
                    {
                        padding-left:0;
                    }
                }
            }
            &.business
            {
                .blog-info
                {
                    background:$dark-blue;
                    @include media-breakpoint-up(lg)
                    {
                        border-top-left-radius:$border-radius-large;
                        border-bottom-left-radius:$border-radius-large;
                    }
                }
                .blog-title, p
                {
                    color:$white;
                }
                @include media-breakpoint-up(lg)
                {
                    .row
                    {
                        flex-direction: row-reverse;
                    }
                    .postSlider
                    {
                        padding-right:0;
                    }
                }
            }
        }
    }
</style>