var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage landingPage landingPinkPalace" },
    [
      _c(
        "div",
        {
          style: "background-image:url(assets/scalapay/bg-pink-palace.svg)",
          attrs: { id: "hero" },
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", {
                    staticClass: "heroCol",
                    attrs: { sm: "12", lg: "6" },
                  }),
                  _c(
                    "b-col",
                    { staticClass: "heroCol", attrs: { sm: "12", lg: "6" } },
                    [
                      _c("img", {
                        staticClass: "heroLogo",
                        attrs: { src: "assets/scalapay/logo-black.svg" },
                      }),
                      _c("p", { staticClass: "hero--title" }, [
                        _vm._v("Salabam fa parte del Pink Palace Scalapay!"),
                      ]),
                      _c("p", { staticClass: "hero--subtitle" }, [
                        _vm._v(
                          "\n                        *Montepremi complessivo concorso € 2000 Iva inclusa\n                        "
                        ),
                        _c("br"),
                        _vm._v("\n                        *Regolamento su "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "www.scalapay.com/it/regolamento",
                            },
                          },
                          [_vm._v("www.scalapay.com/it/regolamento")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-container", { staticClass: "pageContent content" }, [
        _c("section", { staticClass: "first" }, [
          _c("p", { staticClass: "text-center" }, [
            _c("strong", [
              _vm._v("Vinci 2 biglietti per la finale di X Factor!"),
            ]),
          ]),
          _c("br"),
          _c("p", { staticClass: "text-center" }, [
            _c("strong", [_vm._v("Come funziona")]),
          ]),
        ]),
        _c(
          "section",
          [
            _c(
              "b-row",
              { staticClass: "icons" },
              [
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-scalapay-1.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _c("b", [
                        _vm._v("Scegli online il Salabam che preferisci"),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-scalapay-2.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _c("b", [
                        _vm._v("Seleziona Scalapay come metodo di pagamento"),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-scalapay-3.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _c("b", [
                        _vm._v("Hai tempo fino al 29 Novembre per partecipare"),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c("br"),
        _c("p", { staticClass: "text-center" }, [
          _c("strong", [
            _vm._v(
              "Scopri subito le disponibilità oppure visita il nostro Shop e ordina entro il 29 Novembre con Scalapay!"
            ),
          ]),
          _c("br"),
          _c("br"),
          _vm._v(
            "\n            *Montepremi complessivo concorso € 2000 Iva inclusa\n            "
          ),
          _c("br"),
          _vm._v("\n            *Regolamento su "),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "www.scalapay.com/it/regolamento",
              },
            },
            [_vm._v("www.scalapay.com/it/regolamento")]
          ),
        ]),
        _c("section", [
          _c("p", { staticClass: "textAbove linkScalapay text-center" }, [
            _vm._v("\n                Visita "),
            _c(
              "a",
              { attrs: { href: "https://www.scalapay.com", target: "_blank" } },
              [_vm._v("www.scalapay.com")]
            ),
            _vm._v(" per tutte le condizioni.\n            "),
          ]),
        ]),
        _c("section", [
          _c(
            "div",
            { staticClass: "boxed text-center" },
            [
              _c("br"),
              _c(
                "b-button",
                { attrs: { variant: "secondary" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "white",
                      attrs: { to: { name: "homepage" } },
                    },
                    [_vm._v("Inizia")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }