<template>
  <div class="form">

    <b-row>
      <b-col sm="12">
        <h5 v-if="formTitle != ''" class="formTitle">{{formTitle}}</h5>
        <br>

        <b-form-group :state="validateFields()">
          <b-row>
            <b-col sd="12" md="6">
              <div class="customInputBlock">
                <b-form-input ref="email" :required="true" v-model="email" type="email" placeholder="la tua email" data-e2e="salabamInput"/>
                <span class="formError"></span>
                <span class="formClear" @click="clearField('email')"></span>
              </div>
            </b-col>
            
            <b-col sd="12" md="6">
                <div v-if="loading">
                    <Spinner />
                </div>
                <div v-else>

                    <div v-if="apiResponse !== ''">
                    <b-alert variant="info" show>
                        {{apiResponse}}
                    </b-alert>
                    <br>
                    </div>

                    <div v-else>
                    <b-button :disabled="!validateFields()" @click="submitForm">RESTA AGGIORNATO</b-button>
                    </div>

                </div>
                
                <!--
              <div class="customInputBlock">
                <b-form-input ref="emailConfirm" :required="true" v-model="emailConfirm" type="email" placeholder="conferma email" data-e2e="salabamInput"/>
                <span class="formError"></span>
                <span class="formClear" @click="clearField('email')"></span>
              </div>-->
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <div class="customCheckboxBlock">
                <b-form-checkbox v-model="privacy" ref="privacy" type="checkbox" name="privacy" :id="_uid+'-privacy'" />
                <label :for="_uid+'-privacy'">
                  <a :href="privacyPolicyUrl" target="_blank" class="secondary">Ho letto ed accetto la Privacy Policy</a>
                </label>
              </div>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
  import Vue from 'vue'
  import Api from '../../../api'
  import { VueReCaptcha } from 'vue-recaptcha-v3'
  import Spinner from '../../atoms/spinner'

  export default {
    name: 'form-gift-card',
    props:
    {
      formTitle:
      {
        type: String,
        default: '', // titolo del form, renderizzato in alto se != ''
      },
      type:
      {
        type: String,
        default: 'form-gift-card', // tipo form, usato per distinguere i form e per tracking
      },
      label:
      {
        type: String,
        default: '', // per distinguere lo stesso form messo in posti diversi, usato anche per tracking
      },
      recipient:
      {
        type: String,
        default: 'sales@salabam.com', // email destinatario
      }
    },
    components:
    {
      Spinner,
    },
    data()
    {
      return {
        uid: this._uid,
        loading: false,
        recaptchaEnabled: Vue.prototype.$config.recaptcha.enabled,
        recaptchaToken: '',
        email: '',
        //emailConfirm: '',
        privacy: false,
        privacyPolicyUrl: Vue.prototype.$config.site.urlPrivacy,
        apiResponse: '',
      }
    },
    mounted()
    {
      if(Vue.prototype.$config.recaptcha.enabled)
      {
        Vue.use(VueReCaptcha, { siteKey: Vue.prototype.$config.recaptcha.siteKey })
      }
    },
    methods:
    {
      async recaptcha() {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')
        this.recaptchaToken = token
        this.sendToApi()
      },
      clearField(ref)
      {
        this[ref] = ''
        this.$refs[ref].focus()
        this.validateFields()
      },       
      submitForm()
      {
        this.loading = true
        if(this.recaptchaEnabled)
        {
          this.recaptcha()
        }
        else
        {
          this.sendToApi()
        }
      },
      sendToApi()
      {
        let _this = this

        Api.formsSubmitter({
          recaptcha: _this.recaptchaToken,
          fields: {
            company: _this.company,
            firstName: _this.firstName,
            lastName: _this.lastName,
            email: _this.email,
            message: _this.message,
          },
          settings:
          {
            type: _this.type,
            label: _this.label,
            recipient: _this.recipient,
            formTitle: _this.formTitle,
          }
        })
        .then((data) => {

          if(process.env.VUE_APP_MODE != 'production') window.console.log(data)
          if(process.env.VUE_APP_MODE != 'production') window.console.log(data.msg)
          
          _this.apiResponse = data.msg
          _this.loading = false

          if(this.$gtm) this.$gtm.trackEvent({
            event: 'customEvent',
            category: 'userBehaviour',
            action: 'formSubmit',
            label: _this.type,
            value: _this.label,
          })

        })
      },
      validateFields()
      {
        if(Object.keys(this.$refs).length == 0) return false

        return this.mixinValidateFields([
          {
            ref: this.$refs.email,
            type: 'email',
            value: this.email,
            optional: false,
          },/*
          {
            ref: this.$refs.emailConfirm,
            type: 'emailConfirm',
            value: (this.email == this.emailConfirm),
            optional: false,
          },*/
          {
            ref: this.$refs.privacy,
            type: 'checkbox',
            value: this.privacy,
            optional: false,
          }
        ])
      },        
    }
  }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .form
    {
      max-width: 700px;
      margin:auto;
    }
</style>