<template>
    <div id="page" class="publicPage faq">

      <b-container>
        <section>
          <b-row>

            <b-col sm="12" lg="6" offset-lg="3">
                <h1 class="text-center pageTitle">Domande frequenti</h1>
            </b-col>

            <b-col sm="12" lg="3">
                <TornaIndietro />
            </b-col>

          </b-row>

          <Spinner v-if="loading" />
          <div v-else v-for="(faq, index) in faqs" v-bind:key="index">

            <br/><br/>
            <h3 class="text-center pageTitle">{{faq.category}}</h3>
            <Accordion :items="faq.items" :accordionId="faq.catcode" :showTornaIndietro="true"/>

          </div>

        </section>
      </b-container>

    </div>
</template>

<script>
  //import Vue from 'vue'
  //import SearchForm from '../../blocks/search-form/search-form'
  import Api from '../../../api'
  import Spinner from '../../atoms/spinner'
  import Accordion from '../../blocks/common/accordion'
  import TornaIndietro from '@/components/blocks/common/torna-indietro'

  export default {
    name: 'page',
    metaInfo: {
      title: 'Domande frequenti',
    },
    components: {
        Accordion,
        Spinner,
        TornaIndietro,
    },
    data() {
        return {
            loading: true,
            faqs: [],
        }
    },
    mounted() {
        this.getFaqs()
    },
    methods:{
        getFaqs()
        {

            let _this = this

            Api.getFaqs().then((response) => {
                _this.faqs = response.faqs
                this.loading = false
            })
        }
    }
  }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .faq
    {
        .pageTitle
        {
            color:$primary;
            margin-bottom:2rem;
        }
        @include media-breakpoint-down(sm)
        {
            .container
            {
                padding-left:0;
                padding-right:0;
            }
        }
        @include media-breakpoint-down(md)
        {
            .pageTitle
            {
                margin-bottom:0;
            }
        }
        @include media-breakpoint-up(lg)
        {
            .pageTitle
            {
                margin-bottom:3rem;
            }
            .torna-indietro
            {
                padding-top:2rem;
            }
        }
    }
</style>