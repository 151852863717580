var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "235",
        height: "200",
        viewBox: "0 0 235 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M215.557 21.7282C199.144 5.8201 181.216 -1.50268 162.53 0.264883C142.329 2.28496 126.421 14.6579 116.826 24.2533C107.23 14.6579 91.0699 2.28496 71.1216 0.264883C52.4359 -1.50268 34.7602 5.56759 18.0946 21.7282C-2.3587 41.6765 -3.36874 72.4826 4.71157 94.451C19.3571 134.095 62.7888 164.649 97.6351 189.142C101.928 192.172 105.968 194.95 110.008 197.727C111.271 198.737 112.786 199.242 114.301 199.747C114.553 199.747 114.553 199.747 114.806 199.747C115.563 200 116.573 200 117.331 200C117.331 200 117.331 200 117.583 200C118.341 200 119.098 200 119.856 199.747C120.108 199.747 120.361 199.747 120.613 199.495C121.371 199.242 122.129 198.99 122.886 198.737C122.886 198.737 123.139 198.737 123.139 198.485C123.644 198.232 124.149 197.98 124.654 197.475C128.694 194.697 132.734 191.667 137.027 188.637C171.873 164.144 215.052 133.59 229.698 93.9459C237.273 72.4826 236.263 41.6765 215.557 21.7282Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }