var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "publicPage", attrs: { id: "info" } }, [
    _c("img", {
      attrs: {
        src: require("../../../assets/hero-splash-2.jpeg"),
        id: "about",
      },
    }),
    _c(
      "div",
      { attrs: { id: "page" } },
      [
        _c("br"),
        _c("br"),
        _c("b-container", [
          _c(
            "section",
            [
              _c("h1", { staticClass: "text-center" }, [_vm._v("Chi siamo")]),
              _c("br"),
              _c("br"),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12" } }, [
                    _c("p", [
                      _vm._v(
                        "\n                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                            "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n                        "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12", lg: "3" } }, [
                    _c("img", {
                      attrs: { src: "https://via.placeholder.com/300" },
                    }),
                  ]),
                  _c("b-col", { attrs: { sm: "12", lg: "9" } }, [
                    _c("p", [
                      _vm._v(
                        "\n                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                            "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n                        "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12", lg: "9" } }, [
                    _c("p", [
                      _vm._v(
                        "\n                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                            "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n                        "
                      ),
                    ]),
                  ]),
                  _c("b-col", { attrs: { sm: "12", lg: "3" } }, [
                    _c("img", {
                      attrs: { src: "https://via.placeholder.com/300" },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c(
            "section",
            [
              _c(
                "h1",
                { staticClass: "text-center", attrs: { id: "come-funziona" } },
                [_vm._v("Come funziona")]
              ),
              _c("br"),
              _c("br"),
              _c("p", [
                _vm._v(
                  "\n                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                "
                ),
              ]),
              _c("br"),
              _c("br"),
              _c("Checklist", {
                attrs: { items: _vm.checklistItems_cosaVadobay, numbers: true },
              }),
              _c("ComeFunzionaSalabam"),
            ],
            1
          ),
          _c("br"),
          _c("section", [
            _c(
              "h1",
              { staticClass: "text-center", attrs: { id: "assistenza" } },
              [_vm._v("Assistenza")]
            ),
            _c("br"),
            _c("br"),
            _c("p", [
              _vm._v(
                "\n                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                "
              ),
            ]),
          ]),
        ]),
        _c("br"),
        _c("br"),
        _c("Help"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("b-container", [
          _c(
            "section",
            [
              _c(
                "h1",
                { staticClass: "text-center", attrs: { id: "assistenza" } },
                [_vm._v("Titolo prova")]
              ),
              _c("br"),
              _c("br"),
              _c("p", { staticClass: "has-2-column" }, [
                _vm._v("\n                    Lorem ipsum dolor sit amet, "),
                _c("strong", [_vm._v("consectetur adipiscing")]),
                _vm._v(
                  " elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                "
                ),
              ]),
              _c("br"),
              _c("br"),
              _c("p", { staticClass: "has-2-column" }, [
                _vm._v("\n                    Lorem ipsum dolor sit amet, "),
                _c("strong", [_vm._v("consectetur adipiscing")]),
                _vm._v(
                  " elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                "
                ),
              ]),
              _c("br"),
              _c("br"),
              _c(
                "b-row",
                { staticClass: "nogutter" },
                [
                  _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                    _c("img", {
                      attrs: { src: "https://via.placeholder.com/300x150" },
                    }),
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass: "hasBackground",
                      attrs: { sm: "12", md: "6" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "\n                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                        "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c(
            "section",
            [
              _c(
                "h1",
                { staticClass: "text-center", attrs: { id: "assistenza" } },
                [_vm._v("Titolo prova")]
              ),
              _c("br"),
              _c("br"),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "svgIcon" },
                      [_c("SvgCartLarge")],
                      1
                    ),
                    _c("p", [
                      _vm._v(
                        "\n                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                        "
                      ),
                    ]),
                  ]),
                  _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "svgIcon" },
                      [_c("SvgMailLarge")],
                      1
                    ),
                    _c("p", [
                      _vm._v(
                        "\n                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n                        "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("br"),
        ]),
        _c(
          "div",
          { staticClass: "mt-4 mb-4 d-flex justify-content-center" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "homepage" } } },
              [_c("BtnBack", { attrs: { text: "Torna alla Home" } })],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }