var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checklist" }, [
    _c(
      "ul",
      _vm._l(_vm.items, function (item, index) {
        return _c(
          "li",
          { key: index + item },
          [
            !_vm.numbers
              ? _c("Checked")
              : _c("span", { staticClass: "checklist_index" }, [
                  _vm._v(_vm._s(parseInt(index) + 1)),
                ]),
            _c("div", { staticClass: "checklist_item" }, [
              _c("span", { domProps: { innerHTML: _vm._s(item) } }),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }