var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "quote" }, [
    _c("div", { staticClass: "start" }, [_c("SvgQuoteStart")], 1),
    _c("p", {
      staticClass: "content",
      domProps: { innerHTML: _vm._s(_vm.text) },
    }),
    _c(
      "div",
      { staticClass: "stop" },
      [_c("SvgQuoteStop", { staticClass: "stop" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }