var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "icons" } },
    [
      _c("br"),
      _c("br"),
      _vm._v("\n\n  SvgPacco\n  "),
      _c("SvgPacco"),
      _c("hr"),
      _vm._v("\n\n  SvgTicket\n  "),
      _c("SvgTicket"),
      _c("hr"),
      _vm._v("\n\n  SvgLoader\n  "),
      _c("SvgLoader"),
      _c("hr"),
      _vm._v("\n\n  SvgHeartOrangeLarge\n  "),
      _c("SvgHeartOrangeLarge"),
      _c("hr"),
      _vm._v("\n\n  SvgChartArrow\n  "),
      _c("SvgChartArrow"),
      _c("hr"),
      _vm._v("\n\n  SvgLamp\n  "),
      _c("SvgLamp"),
      _c("hr"),
      _vm._v("\n\n  SvgPalette\n  "),
      _c("SvgPalette"),
      _c("hr"),
      _vm._v("\n\n  SvgBrush\n  "),
      _c("SvgBrush"),
      _c("hr"),
      _vm._v("\n\n  SvgSmile\n  "),
      _c("SvgSmile"),
      _c("hr"),
      _vm._v("\n\n  SvgCursors\n  "),
      _c("SvgCursors"),
      _c("hr"),
      _vm._v("\n\n  SvgPeople\n  "),
      _c("SvgPeople"),
      _c("hr"),
      _vm._v("\n\n  SvgWa\n  "),
      _c("SvgWa"),
      _c("hr"),
      _vm._v("\n\n  SvgCup\n  "),
      _c("SvgCup"),
      _c("hr"),
      _vm._v("\n\n  SvgRocket\n  "),
      _c("SvgRocket"),
      _c("hr"),
      _vm._v("\n\n  SvgLogoOrange\n  "),
      _c("SvgLogoOrange"),
      _c("hr"),
      _vm._v("\n\n  SvgLogo\n  "),
      _c(
        "div",
        { staticStyle: { "background-color": "gray" } },
        [_c("SvgLogo")],
        1
      ),
      _c("hr"),
      _vm._v("\n\n\n  SvgValidita\n  "),
      _c("div", [_c("SvgValidita")], 1),
      _c("hr"),
      _vm._v("\n\n\n  SvgRefund\n  "),
      _c("div", [_c("SvgRefund")], 1),
      _c("hr"),
      _vm._v("\n\n  SvgBranding\n  "),
      _c("div", [_c("SvgBranding")], 1),
      _c("hr"),
      _vm._v("\n\n  SvgIntegrazione\n  "),
      _c("div", [_c("SvgIntegrazione")], 1),
      _c("hr"),
      _vm._v("\n\n  SvgDiamond\n  "),
      _c("div", [_c("SvgDiamond")], 1),
      _c("hr"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }