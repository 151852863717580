var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { staticClass: "text-center" }, [
    _c("br"),
    _c("br"),
    _vm.jpToken == "" || _vm.jpToken == undefined
      ? _c("div", [
          _vm._v("\n        C'è stato un problema, token non trovato!\n    "),
        ])
      : _c(
          "div",
          [
            this.$router.currentRoute.query.status == "PLACED"
              ? _c("span", [
                  _vm._v(
                    "\n          Attendi, stiamo attendendo l'autorizzazione dal tuo portale welfare\n        "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n          Attendi, stiamo processando la tua richiesta...\n        "
                  ),
                ]),
            _c("br"),
            _c("br"),
            _vm.loading ? _c("Spinner") : _vm._e(),
          ],
          1
        ),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }