var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion", attrs: { role: "tablist" } },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "b-card",
        { key: index, attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            { attrs: { "header-tag": "header", role: "tab" } },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: _vm.accordionId + "-" + index,
                      expression: "accordionId+'-' + index",
                    },
                  ],
                  attrs: { block: "", href: "#", variant: "gold" },
                },
                [_vm._v(_vm._s(item.title))]
              ),
            ],
            1
          ),
          _c(
            "b-collapse",
            {
              attrs: {
                id: _vm.accordionId + "-" + index,
                accordion: "accordion",
                role: "tabpanel",
              },
            },
            [
              _c(
                "b-card-body",
                [
                  _c("b-card-text", {
                    domProps: { innerHTML: _vm._s(item.content) },
                  }),
                  _c(
                    "p",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: _vm.accordionId + "-" + index,
                              expression: "accordionId+'-' + index",
                            },
                          ],
                          attrs: { size: "sm" },
                        },
                        [_vm._v("Chiudi")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }