var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "137",
        height: "200",
        viewBox: "0 0 137 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M61.2924 63.6557C61.9376 64.7309 63.0128 65.161 64.0881 65.161C65.1634 65.161 66.2387 64.5159 66.8838 63.6557L82.3677 37.2041C83.2279 35.6987 82.7978 33.7632 81.2924 32.6879C79.787 31.8277 77.8516 32.2578 76.7763 33.7632L64.0881 55.4836L60.2172 48.817C59.3569 47.3116 57.4214 46.6664 55.701 47.7417C54.1956 48.6019 53.5505 50.5374 54.6257 52.2578L61.2924 63.6557Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M68.3854 81.2902C86.45 81.2902 101.074 66.6665 101.074 48.602C101.074 30.5375 86.45 16.1289 68.3854 16.1289C50.3209 16.1289 35.6973 30.7526 35.6973 48.8171C35.6973 66.8816 50.3209 81.2902 68.3854 81.2902ZM68.3854 22.5805C82.794 22.5805 94.622 34.4085 94.622 48.8171C94.622 63.2257 82.794 74.8386 68.3854 74.8386C53.9768 74.8386 42.1489 63.0106 42.1489 48.602C42.1489 34.1934 53.9768 22.5805 68.3854 22.5805Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M133.548 0H3.22581C1.50538 0 0 1.50538 0 3.22581V196.774C0 198.495 1.50538 200 3.22581 200H133.333C135.054 200 136.559 198.495 136.559 196.774V3.22581C136.774 1.50538 135.269 0 133.548 0ZM130.323 193.548H6.45161V6.45161H130.108V193.548H130.323Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.1516 167.742H112.689C114.41 167.742 115.915 166.237 115.915 164.516C115.915 162.796 114.41 161.291 112.689 161.291H22.1516C20.4312 161.291 18.9258 162.796 18.9258 164.516C18.9258 166.237 20.4312 167.742 22.1516 167.742Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.1516 146.237H112.689C114.41 146.237 115.915 144.731 115.915 143.011C115.915 141.291 114.41 139.785 112.689 139.785H22.1516C20.4312 139.785 18.9258 141.291 18.9258 143.011C18.9258 144.731 20.4312 146.237 22.1516 146.237Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.1516 124.731H112.689C114.41 124.731 115.915 123.226 115.915 121.505C115.915 119.785 114.41 118.279 112.689 118.279H22.1516C20.4312 118.279 18.9258 119.785 18.9258 121.505C18.9258 123.226 20.4312 124.731 22.1516 124.731Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.1516 103.226H112.689C114.41 103.226 115.915 101.72 115.915 99.9997C115.915 98.2793 114.41 96.7739 112.689 96.7739H22.1516C20.4312 96.7739 18.9258 98.2793 18.9258 99.9997C18.9258 101.72 20.4312 103.226 22.1516 103.226Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }