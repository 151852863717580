var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "header-small" } },
    [
      _c("img", { attrs: { src: _vm.backgroundImage } }),
      _vm.scrollTo !== ""
        ? _c("ScrollDown", { attrs: { targetId: _vm.scrollTo } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }