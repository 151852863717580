var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "200",
        height: "216",
        viewBox: "0 0 200 216",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M103.994 17.7818C157.429 19.2706 200 63.0783 200 116.551C200 171.12 155.761 215.358 101.19 215.358C54.8466 215.358 14.5508 182.989 4.66061 137.693C2.12771 125.953 19.9746 122.102 22.5075 133.839C30.578 170.8 63.3891 197.086 101.19 197.086C145.67 197.086 181.725 161.029 181.725 116.551C181.725 73.1719 147.333 37.5514 103.994 36.0653V51.5609C103.994 57.4755 97.3608 60.8491 92.5879 57.6166C79.9668 50.3324 67.3511 43.0294 54.7273 35.7398C49.7836 32.9005 49.9354 25.7981 54.7951 23.0808C67.5518 15.7153 80.2759 8.35527 93.0299 0.992516C97.9357 -1.84953 103.986 1.77083 103.978 7.30035L103.994 17.7818ZM68.0643 133.183L83.541 131.648C85.3011 141.414 90.4699 146.68 100.642 146.68C107.33 146.68 116.021 143.971 116.021 135.873C116.021 127.442 102.437 125.888 96.0699 124.291C83.5275 121.153 71.0311 115.645 71.0311 100.627C71.0311 87.2113 81.537 80.814 93.0543 79.1163V69.1746H106.947V79.1462C119.482 80.9577 129.111 87.8676 129.632 102.677L113.722 103.222C112.303 94.7422 107.777 91.8106 99.4164 91.8106C94.5757 91.8106 86.1716 93.2588 86.1716 99.5666C86.1716 106.357 98.1581 108.456 103.072 109.625C118.332 113.213 131.935 117.739 131.935 135.781C131.935 145.281 126.34 153.555 117.575 157.199C114.524 158.452 110.985 159.32 106.947 159.751V169.536H93.0543V159.578C78.939 157.582 69.9979 149.392 68.0643 133.183ZM10.0735 96.9415C16.4112 96.1117 22.2227 100.575 23.0499 106.913C23.877 113.251 19.4132 119.057 13.0783 119.889C6.74062 120.719 0.931775 116.256 0.0992279 109.915C-0.730607 103.58 3.73586 97.7714 10.0735 96.9415ZM22.3394 66.6064C28.574 67.9949 32.5116 74.178 31.1204 80.4153C29.7319 86.6554 23.5516 90.5849 17.3088 89.1991C11.0742 87.8133 7.14198 81.6302 8.53046 75.3902C9.91352 69.1502 16.102 65.218 22.3394 66.6064ZM44.2378 42.2944C49.6236 45.7331 51.2046 52.887 47.7686 58.2783C44.3273 63.664 37.176 65.2451 31.7875 61.8064C26.4017 58.3705 24.8207 51.2138 28.2567 45.8253C31.6926 40.4395 38.8493 38.8585 44.2378 42.2944Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }