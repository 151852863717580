var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "196",
        height: "200",
        viewBox: "0 0 196 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M52.0614 148.256C59.0452 155.24 56.5056 168.572 46.3473 178.731C34.6018 190.794 6.0315 198.413 4.76171 198.73L0 200L1.26979 195.238C1.58724 193.968 10.1583 164.763 21.269 153.97C27.3005 147.938 34.9192 144.447 41.5856 144.447C45.7124 144.129 49.2043 145.716 52.0614 148.256Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M195.551 0.00807957V5.40469C195.551 52.3869 186.345 87.6236 141.585 128.892C142.22 151.113 135.553 168.89 121.268 183.175L116.824 187.62L89.2061 146.986C79.6827 151.113 66.3499 147.621 56.8265 138.098C46.9856 128.257 43.8111 114.924 47.938 105.401L7.30469 77.4653L12.0664 73.021C26.3515 58.7359 44.446 52.0695 66.6674 53.0218C108.253 8.57916 143.49 -0.309368 190.472 0.00807957H195.551ZM150.791 65.0848C151.108 53.9741 142.537 44.4507 131.109 43.8158C119.999 43.1809 110.475 52.0695 109.84 63.4976C109.523 74.6082 118.094 84.1317 129.522 84.7666C140.95 85.4015 150.156 76.5129 150.791 65.0848Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }