var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "blog" }, [
    _c(
      "div",
      { staticClass: "blog-header" },
      [
        _c("HeaderSmall"),
        _c("ScrollDown", { attrs: { targetId: "scrollTo" } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "blog-content" },
      [
        _c("b-container", { attrs: { fluid: "" } }, [
          _c("h1", { staticClass: "page-title black text-left" }, [
            _vm._v("\n                BLOG "),
            _c("span", { staticClass: "normal" }, [
              _vm._v("- Approfondimenti, consigli e curiosità da Salabam"),
            ]),
          ]),
        ]),
        _c(
          "section",
          { staticClass: "blog-section consumer", attrs: { id: "scrollTo" } },
          [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { sm: "12", lg: "5" } }, [
                  _c(
                    "div",
                    { staticClass: "blog-info" },
                    [
                      _c("h2", { staticClass: "blog-title" }, [
                        _vm._v("\n                            BLOG "),
                        _c(
                          "span",
                          { staticClass: "normal" },
                          [_vm._v("- La scelta dello staff "), _c("InfoBlue")],
                          1
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                            Lasciati ispirare! Qui troverai idee, consigli, suggestioni per vivere esperienze di viaggio straordinarie con Salabam.\n                        "
                        ),
                      ]),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "blog-consumer" } } },
                        [
                          _c("b-button", { attrs: { size: "md" } }, [
                            _vm._v("Vedi tutti i post"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "12", lg: "7" } },
                  [
                    _c("PostSlider", {
                      attrs: {
                        category: _vm.config.posts.category,
                        maxSlidesToShow: 2,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }