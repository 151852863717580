var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "206",
        height: "200",
        viewBox: "0 0 206 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M205.84 13.61C205.769 9.31288 204.264 5.66569 201.491 3.07058C187.543 -10.1061 143.786 21.2878 109.03 56.0436C87.6753 77.3984 70.3231 99.2722 60.7189 116.737C58.9654 116.498 57.1839 116.344 55.3416 116.344C43.6098 116.344 31.3964 121.268 23.4708 129.193C8.83998 143.824 -8.9798 166.801 5.17409 197.288C5.87547 198.798 7.32967 199.827 8.99429 199.977C9.13924 199.995 9.27952 200 9.41979 200C10.9301 200 12.3562 199.271 13.2353 198.027C22.4234 185.042 28.9556 183.377 33.6222 183.377C37.1852 183.377 40.795 184.49 44.6152 185.668C48.8749 186.978 53.2796 188.338 58.1378 188.338C64.2866 188.338 69.893 186.103 75.2749 181.516C84.3601 173.768 89.5971 163.566 90.0226 152.792C90.1489 149.613 89.8029 146.466 89.1062 143.403C106.36 133.701 127.818 116.587 148.7 95.7091C170.191 74.2281 206.186 34.9835 205.84 13.61ZM69.2056 174.404C65.5397 177.532 62.0235 178.987 58.1378 178.987C54.687 178.987 51.1333 177.892 47.3692 176.733C43.0534 175.4 38.5926 174.025 33.6222 174.025C25.4768 174.025 18.131 177.836 10.902 185.916C5.01044 164.646 17.7662 148.121 30.0872 135.805C36.3201 129.572 45.9992 125.696 55.3463 125.696C60.3261 125.696 67.3773 126.804 72.6564 132.087C78.0524 137.483 80.9795 144.895 80.6802 152.428C80.4511 158.188 78.2768 166.67 69.2056 174.404ZM79.2681 125.48C76.6543 122.867 73.5074 120.809 70.0052 119.29C73.0819 113.945 76.8974 108.156 81.4003 102.045L102.713 123.362C96.7466 127.758 91.1028 131.47 85.8798 134.5C84.1544 131.199 81.938 128.155 79.2681 125.48ZM110.204 117.63L87.1189 94.5402C95.3157 84.1737 105.042 73.2556 115.642 62.6553C158.174 20.1235 190.409 5.47398 195.085 9.87866C195.67 10.4257 196.45 11.4264 196.488 13.7596C196.694 26.4734 175.848 55.3423 142.089 89.1021C131.502 99.6836 120.575 109.428 110.204 117.63Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }