var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage chi-siamo", attrs: { id: "info" } },
    [
      _c("div", { staticClass: "heroContainer" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/chi_siamo_header.jpg"),
            id: "about",
          },
        }),
        _c(
          "div",
          { staticClass: "publicBookingEngine" },
          [
            _c("SearchBar", {
              attrs: {
                loading: false,
                band: _vm.activeBand,
                focus: "",
                geo: _vm.geo,
                dates: _vm.dates,
                occupancy: _vm.occupancy,
                hasResults: false,
                offset: 0,
                lastSearch: "",
                showUserBox: false,
              },
              on: {
                "submit-search": function ($event) {
                  _vm.mixinSendMutation("setTriggerSearch", true)
                  _vm.mixinGoTo("search", {})
                },
                "filter-changed": function ($event) {
                  {
                  }
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { attrs: { id: "page" } },
        [
          _c("b-container", [
            _c(
              "section",
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "\n                Salabam è una azienda italiana che fornisce servizi e soluzioni di online travel booking a viaggiatori e aziende.\n\n                "
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          "\n\n                Impieghiamo, tra Rimini e Verona, professionalità provenienti dal mondo del travel, dell’incentive, della regalistica di consumo e dell’Information Technology.\n              "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "per-i-viaggiatori" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("h3", [
                        _vm._v("Chi siamo: Salabam per i viaggiatori"),
                      ]),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "\n                Su Salabam.com puoi acquistare voucher per prenotare viaggi. Puoi acquistare voucher per te o per farne un regalo lasciando al destinatario la scelta di quando e dove viaggiare. Tu o il destinatario del regalo avrete sempre tante opzioni di prenotazione perché Salabam attinge alle disponibilità dinamiche di oltre " +
                            _vm._s(_vm.totalPropertiesCount) +
                            " hotel, B&B e appartamenti in Italia e nel mondo.\n              "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/chi_siamo_1.png"),
                        },
                      }),
                    ]),
                    _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                      _c("p", [
                        _vm._v(
                          "\n                La prenotazione viene fatta online con conferma in tempo reale, senza necessità di verifiche, telefonate o conferme di disponibilità.\n              "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("p", [
                        _vm._v(
                          "\n                Puoi comperare un voucher Salabam con la tua carta di credito, con il tuo welfare aziendale"
                        ),
                        _vm.mixinUsePromoshopping()
                          ? _c("span", [_vm._v(", con i tuoi PromoShopping")])
                          : _vm._e(),
                        _vm.mixinUseTicketCompliments()
                          ? _c("span", [
                              _vm._v(" e con Edenred Ticket Compliments"),
                            ])
                          : _vm._e(),
                        _vm._v(".\n              "),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "per-le-aziende" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("h3", [_vm._v("Chi siamo: Salabam per le aziende")]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                      _c("p", [
                        _vm._v(
                          "\n                Forniamo soluzioni di online travel booking utilizzate nei concorsi a premi, nel loyalty, nelle promozioni e nelle incentivazioni.\n              "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                Le nostre soluzioni, grazie alla straordinaria flessibilità e alla possibilità di integrazione tecnologica con le piattaforme IT dei clienti, permettono un utilizzo rivoluzionario dei viaggi all'interno di campagne di marketing e comunicazione.\n              "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/chi_siamo_2.png"),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("p", [
                        _vm._v(
                          "\n                Salabam opera anche nell’ambito del welfare aziendale: la nostra è la soluzione più avanzata, semplice e flessibile per inserire viaggi all'interno dei piani welfare. Salabam è stata scelta da operatori leader come AON, Double-You, Edenred EasyWelfare, Eudaimon, Jakala, Jointly, Sodexo, Willis Towers Watson e molti altri.\n              "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  { staticClass: "nogutter" },
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/chi_siamo_3.png"),
                        },
                      }),
                    ]),
                    _c(
                      "b-col",
                      {
                        staticClass: "hasBackground",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "\n                Ogni giorno forniamo un servizio a valore aggiunto ai dipendenti di importanti aziende italiane ed internazionali quali Accenture, Auchan, Banca Intesa, Banco BPM, Chiesi, Ferragamo, Fincantieri, Gucci, IMA, Luxottica, Unicredit, ecc.\n              "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "per-gli-hotel-manager" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c("h3", [
                          _vm._v(
                            "Chi siamo: Salabam per gli hotel manager e i gestori di B&B e appartamenti"
                          ),
                        ]),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                Tramite formali accordi di fornitura e partnership, Salabam attinge alle disponibilità online delle principali Online Travel Agency globali e bed banks.\n              "
                          ),
                        ]),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                Non è possibile essere inseriti direttamente tra le nostre disponibilità ma probabilmente la tua struttura è presente tra i nostri risultati se hai inserito le tue disponibilità sui channel più importanti e conosciuti.\n              "
                          ),
                        ]),
                        _c("br"),
                        _c("Quote", {
                          attrs: {
                            text:
                              "E' probabile che tu venga contattato dal nostro staff via email o telefonicamente in quanto, nell'ottica di fornire un servizio a valore aggiunto alle nostre aziende clienti e ai loro viaggiatori, verifichiamo tutte le prenotazioni qualche giorno prima dell'arrivo del viaggiatore.\n" +
                              "\n",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "per-le-ota" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c("h3", [
                          _vm._v(
                            "Chi siamo: Salabam per OTA, bed banks e fornitori di servizi travel"
                          ),
                        ]),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                La nostra è una realtà in rapida crescita nel panorama dell'online travel italiano. Abbiamo un approccio di business che privilegia la fornitura di servizi a valore aggiunto a clienti aziendali sviluppando soluzioni integrate per il corporate welfare, il loyalty e il marketing in generale.\n              "
                          ),
                        ]),
                        _c("br"),
                        _c("br"),
                        _c(
                          "b-row",
                          { staticClass: "nogutter" },
                          [
                            _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/chi_siamo_4.png"),
                                },
                              }),
                            ]),
                            _c(
                              "b-col",
                              {
                                staticClass: "hasBackground",
                                attrs: { sm: "12", md: "6" },
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    "\n                    Siamo sempre interessati a valutare la qualità di nuovi canali di fornitura di servizi online di tipo travel che abbiano un approccio tecnologicamente integrabile tramite API (Application Programming Interface)\n                  "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }