var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage servizi-business", attrs: { id: "info" } },
    [
      _c("div", { staticClass: "heroContainer" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/servizi_business_header.png"),
            id: "about",
          },
        }),
        _c(
          "div",
          { staticClass: "publicBookingEngine" },
          [
            _c("SearchBar", {
              attrs: {
                loading: false,
                band: _vm.activeBand,
                focus: "",
                geo: _vm.geo,
                dates: _vm.dates,
                occupancy: _vm.occupancy,
                hasResults: false,
                offset: 0,
                lastSearch: "",
                showUserBox: false,
              },
              on: {
                "submit-search": function ($event) {
                  _vm.mixinSendMutation("setTriggerSearch", true)
                  _vm.mixinGoTo("search", {})
                },
                "filter-changed": function ($event) {
                  {
                  }
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { attrs: { id: "page" } },
        [
          _c("b-container", [
            _c(
              "section",
              { attrs: { id: "soluzioni-per-il-marketing" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("br"),
                      _c("br"),
                      _c("h3", [
                        _vm._v(
                          "Soluzioni per il marketing, le promozioni, il loyalty e gli incentive"
                        ),
                      ]),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "\n              Le nostre soluzioni permettono di gestire premi e reward di tipo travel in modo totalmente innovativo.\n            "
                        ),
                      ]),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "\n              Travel branding, customizzazione tematica, drastica riduzione della burocrazia e dei problemi di assistenza ai vincitori/viaggiatori.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgPacco"),
                          _c("h6", [_vm._v("Concorsi a premio")]),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgTicket"),
                          _c("h6", [_vm._v("Operazioni a premio certo")]),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [_c("SvgLoader"), _c("h6", [_vm._v("Instant win")])],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgHeartOrangeLarge"),
                          _c("h6", [_vm._v("Loyalty")]),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [_c("SvgChartArrow"), _c("h6", [_vm._v("Incentive")])],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  { staticClass: "nogutter" },
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/servizi_business_1.png"),
                        },
                      }),
                    ]),
                    _c(
                      "b-col",
                      {
                        staticClass: "hasBackground",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "\n              Possiamo agilmente creare cofanetti viaggio tematici coordinati con il tema e con il mood della tua campagna marketing e con i tuoi valori aziendali\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgLamp"),
                          _c("h6", [_vm._v("Innovazione e semplicità")]),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              Con Salabam puoi realizzare soluzioni integrate con le tue piattaforme IT e implementare meccaniche totalmente innovative con erogazione dei premi in real time e con una significativa riduzione dei passaggi burocratici e dei costi di gestione\n            "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [_c("SvgPalette"), _c("h6", [_vm._v("Branding")])],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              La facile brandizzazione delle nostre soluzioni online permette un'integrazione dei premi all'interno della tua campagna e delle sue piattaforme IT, \"senza cuciture\" e senza soluzione di continuità.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [_c("SvgBrush"), _c("h6", [_vm._v("Customizzazione")])],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              Costruire cofanetti digitali con temi coerenti con i messaggi della tua campagna e con i tuoi valori aziendali con Salabam è facile. Contattaci e scopri come.\n            "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgSmile"),
                          _c("h6", [_vm._v("Cura del viaggiatore")]),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              Salabam è tecnologia, Salabam è automatizzazione. Ma Salabam è anche fattore umano. Salabam ha uno staff di assistenza interno all'azienda, che lavora con un approccio multicanale e soprattutto con attenzione e cura al viaggiatore.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              Abbiamo approntato meccanismi semi-automatizzati di checking delle prenotazioni e selezione dei fornitori di servizi che riducono al minimo il tasso di reclamo e il tasso di insoddisfazione da parte del viaggiatore.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("FormContattiBusinness", {
                  attrs: {
                    formTitle: "Richiedi una demo o un preventivo",
                    label: "soluzioni-per-il-marketing",
                  },
                }),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "per-il-welfare-aziendale" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("h3", [_vm._v("Salabam per il welfare aziendale")]),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "\n              I voucher digitali Salabam sono presenti in tutte le principali piattaforme di welfare aziendale italiane e vengono abitualmente utilizzati per prenotare viaggi online, per sè e per la propria famiglia, da decine di migliaia di lavoratori italiani.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/servizi_business_2.png"),
                        },
                      }),
                    ]),
                    _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                      _c("p", [
                        _vm._v(
                          "\n              I lavoratori italiani scelgono di usare il loro credito welfare per viaggiare con noi perchè salabam è semplice da usare ed è sicuro\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgLoader"),
                          _c("h6", [_vm._v("Semplice e real time")]),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              Salabam è facile da utilizzare perchè permette di acquistare un voucher ed utilizzarlo per prenotare un viaggio, in pochi click.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              Tutte le nostre disponibilità sono prenotabili online senza necessità di richieste di conferma, preventivi e burocrazia.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              La spesa viene automaticamente addebitata sul conto welfare del lavoratore\n            "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgSmile"),
                          _c("h6", [_vm._v("Cura del viaggiatore")]),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              Salabam è tecnologia, Salabam è automatizzazione. Ma Salabam è anche fattore umano. Abbiamo uno staff di assistenza ai viaggiatori interno all'azienda, che lavora con un approccio multicanale e orientamento al cliente.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              Salabam ha inoltre approntato meccanismi semi-automatizzati di checking delle prenotazioni e selezione dei fornitori di servizi che riducono al minimo il tasso di reclamo e insoddisfazione da parte del lavoratore.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("FormContattiBusinness", {
                  attrs: {
                    formTitle: "Contattaci per maggiori informazioni",
                    label: "per-il-welfare-aziendale",
                  },
                }),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "soluzioni-per-regali-e-pubbliche-relazioni" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("h3", [
                        _vm._v("Soluzioni per regali e pubbliche relazioni"),
                      ]),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "\n              Con Salabam è facile omaggiare collaboratori, dipendenti e partner d'affari con straordinari voucher di viaggio.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgCursors"),
                          _c("h6", [_vm._v("Tutti i budget")]),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              I voucher Salabam partono da un valore minimo di qualche decina di Euro ma possono arrivare anche a svariate migliaia di Euro.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              Per questo motivo sono ottime idee regalo sia per vaste operazioni sui dipendenti sia per realizzare regali prestigiosi e di alto valore per clienti e partner d'affari.\n            "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgPeople"),
                          _c("h6", [_vm._v("Tu regali, l'altro sceglie")]),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              Regalare viaggi per fare pubbliche relazioni è un'idea che ricorre spesso in azienda salvo poi arenarsi nelle difficoltà di scelta e gestione.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              Salabam ti permette di fare un regalo che lascia al beneficiario la scelta di come, dove e con chi viaggiare.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              E con Salabam,a differenza dei controversi cofanetti tradizionali, prenotare è facile perchè tutte le disponibilità sono online e prenotabili in tempo reale con pochi click\n            "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [_c("SvgWa"), _c("h6", [_vm._v("Con cura")])],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              Il nostro staff di assistenza, interno all'azienda, si prende cura di qualsiasi eventuale problema dovesse verificarsi durante il viaggio.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              La nostra assistenza ha un approccio multicanale che va dall'email al telefono passando per webchat e WhatsApp\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("FormContattiBusinness", {
                  attrs: {
                    formTitle: "Richiedi una demo o un preventivo",
                    label: "soluzioni-per-regali-e-pubbliche-relazioni",
                  },
                }),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "vivamod-modern-travel" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "12" } }, [
                      _c("h3", [_vm._v("Vivamod | modern travel")]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c("p", [
                        _vm._v(
                          "\n              Vivamod è il nostro servizio online di prenotazione viaggi e soggiorni. Si caratterizza per la sua semplicità e la sua sicurezza.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              Con Vivamod forniamo soluzioni di travel booking integrabili e brandizzabili per i concorsi a premi, i piani loyalty, i programmi fedeltà, i viaggi incentive e i regali aziendali.\n            "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c("div", { staticClass: "incentiveIcons" }, [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [_c("SvgPacco")],
                            1
                          ),
                          _c("div", { staticClass: "text" }, [
                            _c("h6", [
                              _vm._v("Concorsi ed operazioni a premio"),
                            ]),
                            _c("p", [
                              _vm._v(
                                "\n                    Con Vivamod, finalmente potrai davvero integrare il travel booking all'interno delle tue operazioni a premio e concorsi. Immediatezza, semplicità e sicurezza per i tuoi migliori clienti.\n                  "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [_c("SvgHeartOrangeLarge")],
                            1
                          ),
                          _c("div", { staticClass: "text" }, [
                            _c("h6", [_vm._v("Programmi fedeltà e loyalty")]),
                            _c("p", [
                              _vm._v(
                                "\n                    Soluzioni di travel reward flessibili e dinamiche pensate per le operazioni di loyalty più avanzate e innovative.\n                  "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [_c("SvgChartArrow")],
                            1
                          ),
                          _c("div", { staticClass: "text" }, [
                            _c("h6", [_vm._v("Viaggi incentive")]),
                            _c("p", [
                              _vm._v(
                                "\n                    Soluzioni di incentivazione della forza vendita e della rete distributiva dalla gestione semplice e digitale e caratterizzate da grande flessibilità e soddisfazione dei destinatari.\n                  "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "12", align: "center" } },
                      [
                        _c("p", [
                          _c("b", [_vm._v("Visita Vivamod | modern travel")]),
                        ]),
                        _c("b-button", { attrs: { variant: "secondary" } }, [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "white!important" },
                              attrs: {
                                href: "https://www.vivamod.com",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Vai al sito")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c(
              "section",
              { attrs: { id: "blufreccia-sport-event-travel" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "12" } }, [
                      _c("h3", [_vm._v("Blufreccia.com | Sport event travel")]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c("p", [
                        _vm._v(
                          "\n              Salabam ha recentemente acquisito il marchio Blufreccia, azienda che si occupa di viaggi incentive ed eventi aziendali con un forte focus sul mondo degli eventi sportivi.\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              Blufreccia offre una gamma di servizi di progettazione e realizzazione di viaggi incentive, eventi e formazione costruiti su misura per le tue esigenze di marketing e comunicazione\n            "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c("div", { staticClass: "incentiveIcons" }, [
                        _c("div", { staticClass: "box" }, [
                          _c("div", { staticClass: "icon" }, [_c("SvgCup")], 1),
                          _c("div", { staticClass: "text" }, [
                            _c("h6", [_vm._v("Viaggi incentive")]),
                            _c("p", [
                              _vm._v(
                                "\n                    Viaggi di gruppo e individuali per portare i migliori partner della tua azienda sui palcoscenici dei più grandi eventi sportivi internazionali\n                  "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [_c("SvgRocket")],
                            1
                          ),
                          _c("div", { staticClass: "text" }, [
                            _c("h6", [_vm._v("Team building e formazione")]),
                            _c("p", [
                              _vm._v(
                                "\n                    Team building, formazione, speech motivazionali con i campioni dello sport.\n                  "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgPacco"),
                          _c("h6", [_vm._v("Concorsi a premio")]),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [
                          _c("SvgHeartOrangeLarge"),
                          _c("h6", [_vm._v("Loyalty")]),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { sm: "12", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "svgIcon" },
                        [_c("SvgChartArrow"), _c("h6", [_vm._v("Incentive")])],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "12", align: "center" } },
                      [
                        _c("p", [_c("b", [_vm._v("Comincia da qui")])]),
                        _c("b-button", { attrs: { variant: "secondary" } }, [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "white!important" },
                              attrs: {
                                href: "https://www.blufreccia.com",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Visita Blufreccia.com")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("FormContattiBusinness", {
                  attrs: {
                    formTitle: "Oppure, richiedi una demo o un preventivo",
                    label: "blufreccia-sport-event-travel",
                  },
                }),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("section", [_c("NewsletterBusiness")], 1),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }