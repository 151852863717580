<template>
    <div id="page" class="publicPage terms">
        <b-container>
            <section>
                <h1 class="text-center">Termini e condizioni</h1>
                <p>
                  <b>
                    SI PREGA DI LEGGERE ATTENTAMENTE QUESTI TERMINI E CONDIZIONI. ESSI CONTENGONO IMPORTANTI INFORMAZIONI SUL FUNZIONAMENTO DEL SERVIZIO SALABAM E SULLE LIMITAZIONI DI RESPONSABILITÀ DI SALABAM
                  </b>
                </p>
            </section>

            <section>
                <h2 class="text-center">DEFINIZIONI</h2>

                <p>
                  I termini <b>NOI</b>, <b>SOCIETÀ</b> e <b>SALABAM</b> si riferiscono a Salabam srl, una società a responsabilità limitata Italiana con sede in Via del Porto 50, 47841 Cattolica RN, Italia.
                </p>

                <p>
                  L’espressione <b>SITO WEB SALABAM</b> indica i software accessibili all’indirizzo <a :href="siteHost" target="_blank">{{siteHost}}</a> che permettono la generazione e l’acquisto dei Codici Salabam, la visualizzazione pubblica delle disponibilità di pernotti e servizi turistici proposti da Salabam (fase di acquisto del Salabam), la visualizzazione e la prenotazione di pernotti e servizi proposti da Salabam (fase di utilizzo del Salabam).
                </p>

                <p>
                  Il termine <b>DISPONIBILITA'</b> indica le proposte di pernotto e servizi turistici visualizzabili e prenotabili sul Sito Web Salabam. Le disponibilità sono proposte dal Sito Web Salabam in tempo reale, nell’istante esatto di utilizzazione del Sito Web Salabam da parte dell’Utente. Le Disponibilità non sono definite a seguito di una contrattazione e contrattualizzazione tra Salabam e le strutture ricettive stesse ma vengono rese disponibili, online, dagli albergatori o fornitori di servizi turistici verso i partner commerciali di Salabam (Online Travel Agencies, OTA) e resi disponibili da Salabam ai propri Utenti tramite un accordo di partnership con i siti partner stessi (OTA).
                </p>

                <p>
                  L’espressione <b>SALABAM (CODICE SALABAM)</b> indica un voucher caratterizzato da un valore monetario definito il quale dà diritto alla prenotazione,

                  <CopyScadenza
                      default="entro {value} mesi dall’acquisto del Salabam (Codice Salabam)"
                      promoMonths="entro {value} mesi dall’acquisto del Salabam (Codice Salabam) (in promozione fino al {promoValidUntill}, invece dei tradizionali {defaultMonths} mesi)"
                      promoFixed="entro il {value} (in promozione fino al {promoValidUntill}, invece dei tradizionali {defaultMonths} mesi)"
                  />

                  , tramite l’uso dei servizi online forniti sul Sito Web Salabam, di un pernotto o servizi turistici, in un’area geografica definita, per un numero di persone a scelta dell’Utente che arriva fino ad un massimo di partecipanti indicato sul Sito Web Salabam.
                </p>

                <p>
                  Il termine <b>UTENTE</b> si riferisce al cliente che acquista un Salabam (Codice Salabam) ed effettua la prenotazione di un servizio di pernotto o turistico direttamente dal Sito Web Salabam per mezzo del quale forniamo i nostri servizi.
                </p>

                <p>
                  L’espressione <b>ORDINE SALABAM</b> e l’espressione <b>ACQUISTO DEL SALABAM</b> indicano l’operazione con la quale l’Utente acquista un Codice Salabam. L’Utente può effettuare l’acquisto del Salabam (Codice Salabam) direttamente su questo sito pagando con carta di credito oppure ordinandolo su un sito partner convenzionato e pagandolo con il Credito personale a lui messo a disposizione, sul portale partner stesso, e riconosciuto ed accettato come strumento di pagamento da Salabam.
                </p>

                <p>
                  Il termine <b>PRENOTAZIONE</b> indica l’operazione con la quale l’Utente, utilizzando il Codice Salabam in suo possesso, prenota un pernotto tramite i servizi online forniti dal Sito Web Salabam
                </p>

                <p>
                  L’espressione <b>HOTEL VOUCHER SALABAM</b> indica il documento inviato via email all’Utente a seguito della sua Prenotazione e recante il riepilogo dettagliato dei servizi prenotati nonchè un riferimento univoco alla Prenotazione stessa
                </p>

                <p>
                  Con l’espressione <b>STRUTTURA RICETTIVA</b> si intende qualsiasi fornitore di servizi di pernotto. A titolo eminentemente esemplificativo e non esaustivo rientrano nella categoria gli hotel, i bed&breakfast, gli appartamenti, gli agriturismi, i villaggi turistici, i resort, ecc.
                </p>

            </section>

            <section>
              <h2 class="text-center">INDICAZIONI GENERALI</h2>

              <p>
                <b>
                  L’utilizzo di questo sito internet è vincolato all’accettazione senza riserve dei termini, condizioni ed avvertenze ivi contenuti, in vigore al momento della prenotazione. Qualsiasi utilizzo da parte dell’utente o da parte nostra delle informazioni relative al conto personale ed alle prenotazioni effettuate tramite il nostro sito è soggetto ai seguenti termini, condizioni e notifiche. L’utilizzo del sito costituisce la sottoscrizione a tutti i summenzionati termini, condizioni ed avvertenze.
                </b>
              </p>

              <p>
                <b>
                  L’utilizzo del sito è altresì soggetto ai termini e alle condizioni applicate da Expedia Affiliate Network L.P e reperibili al seguente indirizzo <a href="http://developer.ean.com/terms/it/">http://developer.ean.com/terms/it/</a>
                </b>
              </p>

              <p>
                <b>
                  Se l’utente non sottoscrive tali termini e condizioni non è autorizzato ad utilizzare il sito web. Per ogni controversia sarà esclusivamente competente il Foro di Rimini.
                </b>
              </p>

            </section>

            <section>
              <h2 class="text-center">FUNZIONAMENTO E SERVIZI SALABAM</h2>



              <p>
                Il Sito Web Salabam permette all’Utente l’acquisto di un Salabam (Codice Salabam) il quale dà diritto alla prenotazione, sempre sul Sito Web Salabam, di pernotti e servizi turistici, da parte sua o di un terzo al quale il Salabam (Codice Salabam) può essere inviato per email tramite un’apposita funzione del Sito Web Salabam. Il Salabam può essere acquistato anche tramite siti web partner (principalmente portali di Welfare aziendale). Il Sito Web Salabam propone all’Utente disponibilità di pernotto reali e immediatamente confermabili ottenute dinamicamente da partner commerciali (Online Travel Agency, OTA). Il Salabam (Codice Salabam) prevede una possibilità di utilizzo temporale definita (Data di scadenza) nella conferma d’acquisto del Salabam (Codice Salabam) stesso. La Data di Scadenza indica la data entro la quale deve essere effettuato il check-in del pernotto. In fase di prenotazione il Sito Web Salabam permette all’Utente di effettuare integrazioni ed upgrade (numero notti e costo per notte) pagando con carta di credito ed altri servizi di pagamento la differenza rispetto al Salabam (Codice Salabam) originariamente acquistato.
              </p>
              
              <p>
                Al momento della prenotazione dei servizi di viaggio da parte dell'Utente, Salabam agisce come interfaccia tra l'Utente, da una parte, e i partner commerciali di Salabam e le Strutture ricettive e gli altri fornitori di servizi di viaggio, dall'altra. Quando l'Utente effettua una prenotazione per un Servizio di viaggio usando Salabam, egli conclude un contratto con il relativo Fornitore dei servizi di viaggio o Struttura ricettiva per tale Servizio.
              </p>

              <p>
                L’utente riconosce ed accetta il fatto che Salabam eroghi un servizio a fronte di adeguata remunerazione del servizio stesso. Il servizio remunerato è un servizio di prenotazione di pernotti e servizi turistici agevolato da un sistema che permette all’Utente di effettuare in totale libertà ricerche su un altissimo numero di destinazioni (indefinito e nell’ordine di milioni di destinazioni quando si faccia riferimento ai Salabam Mondo, con regimentazioni geografiche estremamente limitate: Italia, Europa, Mondo, Mare, Montagna), su date e numero di partecipanti e di poter gestire il valore del suo credito (Codice Salabam) nell’arco di dodici mesi nonchè a terzi (aziende o organizzazioni) di gestire i loro piani di Welfare Aziendale, o parte di questi, operazioni di promozione commerciale, piani di regalistica e di incentivazione. Il valore del Codice Salabam in possesso dell’Utente infatti include la tariffa applicata dalla struttura ricettiva per le camere prenotate o i servizi turistici prenotati a nome dell’Utente, la commissione di intermediazione applicata dai partner commerciali di Salabam e la remunerazione del servizio Salabam.
              </p>


              <!--<ol>
                <li>
                  un servizio di prenotazione di pernotti e servizi turistici agevolato da un sistema che permette all’Utente di effettuare in totale libertà ricerche su un altissimo numero di destinazioni (indefinito e nell’ordine di milioni di destinazioni), su date e numero di partecipanti
                </li>
                <li>
                  un servizio che permette a terzi (aziende o organizzazioni) di gestire i loro piani di Welfare Aziendale, o parte di questi, operazioni di promozione commerciale, piani di regalistica, di incentivazione e loyalty.
                </li>
              </ol>-->

            </section>

            <section>
              <h2 class="text-center">LIMITAZIONE DI RESPONSABILITA’</h2>

              <p>
                Le informazioni, i software, i prodotti e servizi pubblicati su questo sito possono presentare inaccuratezze o errori tipografici. Più specificatamente, Salabam non garantisce l’accuratezza e declina ogni responsabilità per le inaccuratezze informative concernenti le strutture ricettive o altri fornitori, relativamente a fotografie, descrizioni delle strutture ricettive ed alla descrizione dei servizi disponibili. La maggior parte delle informazioni vengono comunicate a Salabam e ai suoi fornitori, da strutture ricettive e altri fornitori di contenuti, in tempo reale e in via telematica. Per questo motivo le informazioni di disponibilità e le descrizioni dei servizi proposti all’Utente vengono costantemente aggiornate e modificate. Salabam o i rispettivi fornitori possono apportare miglioramenti o modifiche al presente sito in qualsiasi momento. Esaurimento o modifiche delle disponibilità proposte possono verificarsi in qualsiasi momento senza preavviso e senza alcuna responsabilità per Salabam.
              </p>

              <p>
                L’inclusione o la messa in vendita di qualsivoglia prodotto e servizio su questo sito non costituisce in alcun modo approvazione né raccomandazione di tali prodotti o servizi da parte di Salabam.
              </p>

              <p>
                Le classificazioni degli hotel visualizzate sul sito hanno valore puramente orientativo e Salabam non può garantirne l’accuratezza.
              </p>

              <p>
                Gli operatori, le strutture ricettive e gli altri fornitori di viaggi o di altri servizi proposti sul sito Salabam sono contraenti indipendenti e non sono agenti nè impiegati di Salabam. Salabam non è responsabile delle azioni, errori, omissioni, rappresentazioni, garanzie, infrazioni o negligenze di uno dei succitati fornitori, nè di lesioni, morte, danni a proprietà personale o spese e danni che risultino dal ricorso a tali fornitori.
              </p>

              <p>
                Salabam non è responsabile, nè emetterà alcun rimborso, in caso di ritardo, cancellazione, overbooking, sciopero, forza maggiore o altri eventi che non dipendano dal suo controllo diretto, e non è responsabile per ogni altra spesa aggiuntiva, omissione, cambio di itinerario o azioni causati da governi o autorità.
              </p>

              <p>
                In nessun caso Salabam e i rispettivi fornitori, verranno ritenuti responsabili per qualsiasi sanzione, nè per qualsiasi danno diretto, incidentale, specifico o emergente causato da, o in ogni modo connesso a, l’utilizzo di questo sito, o al mancato accesso o accesso ritardato al presente sito.
              </p>

              <p>
                D’altronde Salabam non esclude o limita in alcun modo la propria responsabilità su questioni che non possano essere oggetto di esclusione di responsabilità ai sensi della legge vigente.
              </p>

            </section>

            <section>
              <h2 class="text-center">LINK A SITI DI TERZI</h2>

              <p>
                Questo sito può contenere link ad altri siti, gestiti da società diverse da Salabam. Tali link sono forniti solo per riferimento. Salabam non controlla tali siti e non è responsabile del loro contenuto, né dell’utilizzo che ne fa l’Utente. Il fatto che Salabam includa dei link ai siti summenzionati non implica in alcun modo l’approvazione del materiale presente sui siti linkati né sta ad indicare alcuna associazione con gli operatori di detti siti.
              </p>

            </section>

            <section>
              <h2 class="text-center">SOFTWARE DISPONIBILI SUL SITO</h2>

              <p>
                Qualsiasi sofware o servizio utilizzabile sul Sito Web Salabam è coperto dal diritto di autore di Salabam.
              </p>

              <p>
                Senza limitazione a quanto appena dichiarato, la copia o la riproduzione del software su qualsiasi altro server o supporto per ulteriore riproduzione o diffusione è espressamente proibita.
              </p>

            </section>

            <section>
              <h2 class="text-center">ALTRI TERMINI DI UTILIZZO PER L’APPLICAZIONE SALABAM PER DISPOSITIVI PORTATILI</h2>

              <p>
                Non esiste una corrispondenza perfetta tra le funzioni del sito fruite da desktop e quelle fruite da mobile, tablet o altri dispositivi.
              </p>

            </section>

            <section>
              <h2 class="text-center">RISERVA DEI DIRITTI</h2>

              <p>
                L’utente è consapevole che tutti i diritti di proprietà intellettuale, la proprietà stessa e tutti gli interessi nell’Applicazione e nei Contenuti appartengono a Salabam e ai suoi fornitori. Tali diritti sono tutelati da leggi e trattati internazionali. Tutti questi diritti sono riservati.
              </p>

            </section>

            <section>
              <h2 class="text-center">INFORMAZIONI PER L’UTENTE E PER L’UTILIZZO DELL’APPLICAZIONE</h2>

              <p>
                L’elaborazione delle informazioni sull’Utente è trattata in modo conforme alla nostra Informativa sulla privacy. Utilizzando questa Applicazione, l’Utente acconsente a tale elaborazione. Si consiglia pertanto la lettura integrale della nostra <a :href="privacyPolicyUrl" target="_blank">Informativa sulla privacy</a>.
              </p>

            </section>

            <section>
              <h2 class="text-center">PAGAMENTO ONLINE</h2>

              <p>
                Sul Sito Web Salabam l’utente può acquistare un Salabam ovvero un Codice Salabam che fornisce all’Utente un titolo di credito di valore definito, atto ad effettuare la prenotazione sul Sito Web Salabam di un pernotto per una o più persone in una struttura ubicata in un’area geografica definita, per un determinato numero di notti e per un valore definito di costo per camera per notte. Sul Sito Web Salabam l’Utente può integrare il valore del suo Codice Salabam al fine di aumentare numero notti o costo per notte rispetto a quanto definito dal Codice Salabam in suo possesso. Il pagamento della differenza tra il valore del Codice Salabam in possesso dell’Utente e il costo finale determinato dalle integrazioni selezionate liberamente dall’Utente, deve essere effettuato con carta di credito o altri servizi di pagamento proposti dal Sito Web Salabam.
              </p>

              <p v-if="scalapayEnabled">
                L'utente che effettua la sua prenotazione e paga con Scalapay riceve il suo ordine subito e paga in 3 rate. L'utente prende atto del fatto che le rate verranno cedute a Incremento SPV S.r.l., a soggetti correlati e ai loro cessionari, e che autorizza tale cessione.
              </p>

            </section>

            <section>
              <h2 class="text-center">VARIAZIONI E CANCELLAZIONI</h2>

              <p>
                Le Prenotazioni effettuate tramite il Sito Web Salabam sono rimborsabili quando questo è indicato, e nelle modalità in cui è indicato, nelle CONDIZIONI della specifica camera prenotata.
              </p>

              <p>
                La cancellazione di una prenotazione effettuata con un Salabam, quando questa sia prevista dalle condizioni della soluzione prenotata, genera la riattivazione del voucher che viene reso disponibile per una nuova prenotazione senza modifica dei termini di scadenza originari dello stesso.
              </p>

              <p>
                L'acquisto di un voucher Salabam effettuato tramite credito welfare potrà essere cancellato e il valore riaccreditato sul proprio conto di welfare aziendale solo nei casi in cui questa sia una opzione esplicitamente prevista dalle condizioni d'uso del welfare provider (o portale welfare o partner welfare) della propria azienda e dietro espressa autorizzazione da parte del welfare provider stesso.
              </p>

              <p>
                  La cancellazione di una prenotazione effettuata aggiungendo budget con la propria carta di credito ad un Salabam originario effettuata con l'apposito strumento disponibile in area booking, non prevede il riaccredito della somma sulla carta di credito ma la generazione di un nuovo Salabam del valore complessivo dato dall'addizione del valore del Salabam originario e della somma aggiunta con carta di credito. Il nuovo voucher così creato non potrà essere accorpato ad un altro voucher Salabam.
              </p>
            </section>

            <section>
              <h2 class="text-center">DENUNCE DI VIOLAZIONE DEL COPYRIGHT</h2>

              <p>
                Qualora l’Utente ritenga in buona fede che il materiale ospitato da Salabam violi il proprio copyright, può inviarci, anche tramite un suo rappresentante, una comunicazione scritta che includa le seguenti informazioni. Salabam non prenderà in considerazione le richieste dell’Utente non compilate correttamente o incomplete. Nel caso in cui la comunicazione contenga false dichiarazioni circa il fatto che un determinato contenuto o attività costituisce una violazione, l’Utente potrebbe rispondere dei danni.
              </p>

              <ol>
                <li>
                  Elementi che consentano di identificare chiaramente l’opera protetta dal copyright che sarebbe stato violato.
                </li>
                <li>
                  Elementi che consentano di identificare chiaramente il materiale che costituirebbe violazione dell’opera protetta da copyright, in modo da consentire ad Salabam di individuare tale materiale sul sito Web (ad esempio, un collegamento al suddetto materiale).
                </li>
                <li>
                  Informazioni utili per contattare l’Utente affinché Salabam possa rispondere alla richiesta, inclusi, possibilmente, un indirizzo e-mail e un numero di telefono.
                </li>
                <li>
                  Una dichiarazione con cui l’Utente afferma di “aver ritenuto in buona fede che la pubblicazione del materiale in violazione del copyright non sia autorizzata dal titolare del copyright, da un suo rappresentante o dalla legge.
                </li>
                <li>
                  Una dichiarazione secondo cui “le informazioni riportate nella notifica sono precise e si dichiara sotto giuramento che la parte che presenta denuncia è autorizzata ad agire in nome del titolare del diritto esclusivo che si presume violato”.
                </li>
                <li>
                  La comunicazione deve essere firmata dalla persona autorizzata ad agire in nome del titolare del diritto esclusivo che si presume violato.
                </li>
              </ol>

              <p>
                L’Utente può inviare la propria comunicazione per e-mail all’indirizzo <a :href="mailtoEmailSupport">{{emailSupport}}</a>
              </p>

            </section>


            <section>

              <p>
                Versione rivista e in vigore dal 25 luglio 2018
              </p>

              <p>
                ©2018 Salabam SRL, Tutti i diritti riservati
              </p>

            </section>

        </b-container>
    </div>
</template>

<script>
  import Vue from 'vue'
  import CopyScadenza from '@/components/blocks/common/copy-scadenza'

  export default {
    name: 'terms',
    metaInfo: {
      title: 'Termini e condizioni',
    },
    components: {
      CopyScadenza,
    },
    methods: {
    },
    data(){
      return {
        siteHost: process.env.VUE_APP_SITE_HOST,
        privacyPolicyUrl: Vue.prototype.$config.site.urlPrivacy,
        emailSupport: Vue.prototype.$config.site.emailSupport,
        mailtoEmailSupport: 'mailto:' + Vue.prototype.$config.site.emailSupport,
        scalapayEnabled: (Vue.prototype.$config.guiSettings.integrations.scalapay.enabled)? true : false,
      }
    },
  }
</script>

<style scoped lang="scss">


  ol>li{
    list-style-type: disc;
    margin-bottom: 15px;
  }



</style>