<template>
    <div class="publicPage landingPage landingPinkPalace">
        <div id="hero" :style="'background-image:url(assets/scalapay/bg-pink-palace.svg)'">
            <b-container fluid>
                <b-row>
                    <b-col sm="12" lg="6" class="heroCol"></b-col>
                    <b-col sm="12" lg="6" class="heroCol">
                        <img class="heroLogo" src="assets/scalapay/logo-black.svg" />
                        <p class="hero--title">Salabam fa parte del Pink Palace Scalapay!</p>
                        <p class="hero--subtitle">
                            *Montepremi complessivo concorso € 2000 Iva inclusa
                            <br>
                            *Regolamento su <a target="_blank" href="www.scalapay.com/it/regolamento">www.scalapay.com/it/regolamento</a>
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="pageContent content">
            <section class="first">
                <p class="text-center">
                    <strong>Vinci 2 biglietti per la finale di X Factor!</strong>    
                </p>
                <br>
                <p class="text-center">
                    <strong>Come funziona</strong>    
                </p>
            </section>

            <section>
                <b-row class="icons">
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-scalapay-1.svg" />
                        <p class="text"><b>Scegli online il Salabam che preferisci</b></p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-scalapay-2.svg" />
                        <p class="text"><b>Seleziona Scalapay come metodo di pagamento</b></p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-scalapay-3.svg" />
                        <p class="text"><b>Hai tempo fino al 29 Novembre per partecipare</b></p>
                    </b-col>
                </b-row>
            </section>

            <br><br>

            <p class="text-center">
                <strong>Scopri subito le disponibilità oppure visita il nostro Shop e ordina entro il 29 Novembre con Scalapay!</strong>
                <br><br>
                *Montepremi complessivo concorso € 2000 Iva inclusa
                <br>
                *Regolamento su <a target="_blank" href="www.scalapay.com/it/regolamento">www.scalapay.com/it/regolamento</a>
            </p>

            <section>
                <p class="textAbove linkScalapay text-center">
                    Visita <a href="https://www.scalapay.com" target="_blank">www.scalapay.com</a> per tutte le condizioni.
                </p>
            </section>
            
            <section>
                <div class="boxed text-center">
                    <br>

                    <b-button variant="secondary">
                        <router-link :to="{name: 'homepage'}" class="white">Inizia</router-link>
                    </b-button>

                </div>
            </section>

        </b-container>

    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: 'landing-scalapay',
    components:
    {
    },
    data()
    {
        return {
                config: this.appConfig(),
                scalapay: Vue.prototype.$config.guiSettings.integrations.scalapay,
            }
    },
    mounted()
    {
      if(!this.scalapay.enabled || !this.scalapay.showPromo)
      {
        this.mixinGoTo('homepage', {})
        return
      }
    },
    methods:
    {
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .landingPinkPalace
    {
        .container
        {
            max-width: 1100px !important;
        }
        .content
        {
            padding-top:40px;
            padding-bottom:40px;
        }
        .heroCol
        {
            flex-direction: column;
            text-align:center;
            align-items:center !important;
            .hero--title
            {
                font-size: 18px;
                margin-bottom:20px;
                margin-top:20px;
                @include media-breakpoint-up(md)
                {
                    font-size: 24px;
                    margin-bottom:20px;
                    margin-top:30px;   
                }
            }
            .hero--subtitle
            {
                font-size: 14px;
            }
        }
        .title
        {
            font-size:20px;
            text-align: center;
            margin-bottom:30px;
            @include media-breakpoint-up(md)
            {
                font-size: 28px;
                margin-bottom:40px;
            }
        }
        .heroLogo
        {
            max-width:100%;
            width: 300px;
        }
        .aon
        {
            color: #E31836;
            font-weight:600;
        }
        .white, .white *
        {
            text-decoration:none !important;
        }
        .icons
        {
            text-align: center;
            .icon
            {
                margin-bottom:40px;
            }
            .text
            {
                max-width: 200px;
                margin: auto;                
            }
        }
        .primary
        {
            font-weight: 600;
            font-size: 1.25em;
        }

        .first
        {
            font-size: 1.35em;
        }
    }
</style>