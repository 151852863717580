var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "189",
        height: "200",
        viewBox: "0 0 189 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M143.826 94.4266C143.826 67.1042 121.531 44.8091 94.2084 44.8091C66.8859 44.8091 44.5908 67.1042 44.5908 94.4266C44.5908 98.3611 45.028 102.295 46.1209 106.23C47.4324 112.569 49.8368 116.503 53.1155 121.968C53.7712 123.279 54.6455 124.591 55.5198 126.121C56.6127 127.869 57.4871 129.618 58.58 131.148C62.9515 138.361 65.5745 142.514 65.5745 151.039V171.585C65.5745 176.831 69.2904 180.984 74.3177 181.858C76.5035 193.006 83.0608 200 94.2084 200C105.356 200 112.132 193.006 114.099 181.858C119.126 180.984 122.842 176.612 122.842 171.585V151.039C122.842 142.514 125.465 138.142 129.837 131.148C130.711 129.618 131.804 127.869 132.897 126.121C133.771 124.591 134.646 123.279 135.301 121.968C138.58 116.503 140.984 112.569 142.296 106.23C143.389 102.295 143.826 98.3611 143.826 94.4266ZM114.318 156.284H74.5362V151.257C74.5362 150.164 74.5363 149.29 74.3177 148.416H114.318C114.318 149.29 114.099 150.164 114.099 151.257V156.284H114.318ZM112.35 173.552H76.0663C74.9734 173.552 74.3177 172.678 74.3177 171.804V165.028H114.099V171.804C114.318 172.678 113.443 173.552 112.35 173.552ZM94.2084 191.476C91.804 191.476 85.6838 191.476 83.2794 182.295H105.356C102.951 191.476 96.6127 191.476 94.2084 191.476ZM133.771 104.481C132.678 109.29 130.93 112.569 127.87 117.596C126.995 118.907 126.34 120.219 125.465 121.749C124.372 123.498 123.498 125.246 122.624 126.558C120.001 130.929 117.596 134.864 116.066 139.454H72.7876C71.2576 134.864 69.0718 130.929 66.2302 126.558C65.3559 125.028 64.263 123.498 63.3887 121.749C62.5144 120.219 61.64 118.689 60.9843 117.596C57.9242 112.35 56.1755 109.29 55.0826 104.263C54.2083 100.984 53.7712 97.7053 53.7712 94.4266C53.7712 71.9129 72.1319 53.5523 94.6456 53.5523C117.159 53.5523 135.52 71.9129 135.52 94.4266C135.083 97.7053 134.646 100.984 133.771 104.481Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M184.263 90.0552H166.121C163.716 90.0552 161.749 92.0224 161.749 94.4268C161.749 96.8311 163.716 98.7984 166.121 98.7984H184.263C186.667 98.7984 188.634 96.8311 188.634 94.4268C188.634 92.0224 186.667 90.0552 184.263 90.0552Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M144.919 48.0876C146.012 48.0876 147.105 47.6503 147.98 46.776L160.876 33.8799C162.624 32.1313 162.624 29.5084 160.876 27.7597C159.127 26.0111 156.504 26.0111 154.756 27.7597L141.859 40.6558C140.111 42.4045 140.111 45.0274 141.859 46.776C142.734 47.6503 143.827 48.0876 144.919 48.0876Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M94.2065 26.8852C96.6109 26.8852 98.5781 24.918 98.5781 22.5137V4.37159C98.5781 1.96721 96.6109 0 94.2065 0C91.8022 0 89.835 1.96721 89.835 4.37159V22.5137C89.835 24.918 91.8022 26.8852 94.2065 26.8852Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M40.4372 46.776C41.3115 47.6503 42.4043 48.0876 43.4972 48.0876C44.5901 48.0876 45.6831 47.6503 46.5574 46.776C48.306 45.0274 48.306 42.4045 46.5574 40.6558L33.6612 27.7597C31.9125 26.0111 29.2896 26.0111 27.541 27.7597C25.7923 29.5084 25.7923 32.1313 27.541 33.8799L40.4372 46.776Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.5137 90.0552H4.37159C1.96721 90.0552 0 92.0224 0 94.4268C0 96.8311 1.96721 98.7984 4.37159 98.7984H22.5137C24.918 98.7984 26.8852 96.8311 26.8852 94.4268C26.8852 92.0224 24.918 90.0552 22.5137 90.0552Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M40.4372 142.077L27.541 154.973C25.7923 156.721 25.7923 159.344 27.541 161.093C28.4153 161.967 29.5082 162.404 30.6011 162.404C31.694 162.404 32.7868 161.967 33.6612 161.093L46.5574 148.197C48.306 146.448 48.306 143.825 46.5574 142.077C44.8088 140.328 41.9673 140.328 40.4372 142.077Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M147.98 142.077C146.231 140.328 143.608 140.328 141.859 142.077C140.111 143.825 140.111 146.448 141.859 148.197L154.756 161.093C155.63 161.967 156.723 162.404 157.816 162.404C158.908 162.404 160.001 161.967 160.876 161.093C162.624 159.344 162.624 156.721 160.876 154.973L147.98 142.077Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }