var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "201",
        height: "200",
        viewBox: "0 0 201 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M198.088 70.6423L178.475 51.129C177.823 50.4422 177.041 49.8926 176.175 49.5124C175.308 49.1322 174.374 48.9292 173.428 48.9152C172.481 48.9012 171.542 49.0765 170.664 49.4309C169.787 49.7853 168.989 50.3116 168.317 50.9788C165.766 53.5104 162.316 54.928 158.722 54.9211C155.127 54.9142 151.683 53.4834 149.141 50.9419C146.6 48.4005 145.169 44.9555 145.162 41.3614C145.155 37.7672 146.573 34.3168 149.104 31.7656C149.777 31.111 150.312 30.3273 150.675 29.4615C151.038 28.5956 151.223 27.6654 151.218 26.7265C151.213 25.7875 151.019 24.8592 150.648 23.997C150.276 23.1348 149.734 22.3565 149.054 21.7086L129.791 2.34522C128.401 0.944728 126.539 0.112374 124.569 0.0105748C122.598 -0.0912247 120.661 0.544834 119.134 1.79464L45.4824 74.3449L125.538 154.4L198.638 81.2997C199.899 79.7777 200.542 77.8376 200.44 75.8637C200.339 73.8899 199.499 72.0264 198.088 70.6423Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M115.535 164.403L82.4622 197.476C80.5647 198.959 78.2618 199.832 75.8579 199.978C73.5631 200.126 71.2819 199.529 69.3531 198.277L50.0401 178.964C48.8214 177.328 48.2495 175.3 48.4342 173.268C48.6189 171.237 49.5471 169.345 51.0409 167.956C53.6798 165.278 55.2157 161.706 55.3438 157.949C55.4543 154.368 54.1615 150.885 51.7411 148.242C50.5241 146.974 49.0654 145.962 47.451 145.265C45.8366 144.569 44.0992 144.204 42.3412 144.19C40.5833 144.176 38.8403 144.514 37.215 145.184C35.5897 145.854 34.1151 146.843 32.878 148.092L31.8277 149.043C30.4324 150.483 28.5591 151.365 26.5597 151.521C24.5604 151.678 22.5727 151.099 20.97 149.894L1.60664 130.53C0.415802 128.878 -0.143144 126.854 0.0312894 124.825C0.205723 122.796 1.10197 120.897 2.55729 119.473L36.6309 85.499L115.535 164.403Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }