<template>
  <div id="info" class="publicPage servizi-business">

    <div class="heroContainer">
      <img src="@/assets/images/servizi_business_header.png" id="about">
      <div class="publicBookingEngine">
        <SearchBar
          :loading="false"
          :band="activeBand"
          :focus="''"
          :geo="geo"
          :dates="dates"
          :occupancy="occupancy"
          :hasResults="false"
          :offset="0"
          :lastSearch="''"
          v-on:submit-search="mixinSendMutation('setTriggerSearch',true);mixinGoTo('search',{})"
          v-on:filter-changed="{}"
          :showUserBox="false"
        />
      </div>
    </div>

    <div id="page">

      <b-container>

        <section id="soluzioni-per-il-marketing">

          <b-row>
            <b-col sm="12">

              <br><br>

              <h3>Soluzioni per il marketing, le promozioni, il loyalty e gli incentive</h3>

              <br/>

              <p>
                Le nostre soluzioni permettono di gestire premi e reward di tipo travel in modo totalmente innovativo.
              </p>

              <br/>

              <p>
                Travel branding, customizzazione tematica, drastica riduzione della burocrazia e dei problemi di assistenza ai vincitori/viaggiatori.
              </p>
            </b-col>
          </b-row>


          <br/><br/>


          <b-row>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgPacco />
                <h6>Concorsi a premio</h6>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgTicket />
                <h6>Operazioni a premio certo</h6>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgLoader />
                <h6>Instant win</h6>
              </div>
            </b-col>
          </b-row>

          <br/><br/>

          <b-row>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgHeartOrangeLarge />
                <h6>Loyalty</h6>
              </div>
            </b-col>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgChartArrow />
                <h6>Incentive</h6>
              </div>
            </b-col>
          </b-row>


          <br/><br/>


          <b-row class="nogutter">
            <b-col sm="12" md="6">
              <img src="@/assets/images/servizi_business_1.png">
            </b-col>
            <b-col sm="12" md="6" class="hasBackground">
              <p>
                Possiamo agilmente creare cofanetti viaggio tematici coordinati con il tema e con il mood della tua campagna marketing e con i tuoi valori aziendali
              </p>
            </b-col>
          </b-row>


          <br/><br/>

          <b-row>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgLamp />
                <h6>Innovazione e semplicità</h6>
              </div>
              <p>
                Con Salabam puoi realizzare soluzioni integrate con le tue piattaforme IT e implementare meccaniche totalmente innovative con erogazione dei premi in real time e con una significativa riduzione dei passaggi burocratici e dei costi di gestione
              </p>
            </b-col>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgPalette />
                <h6>Branding</h6>
              </div>
              <p>
                La facile brandizzazione delle nostre soluzioni online permette un'integrazione dei premi all'interno della tua campagna e delle sue piattaforme IT, "senza cuciture" e senza soluzione di continuità.
              </p>
            </b-col>
          </b-row>

          <br/><br/>

          <b-row>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgBrush />
                <h6>Customizzazione</h6>
              </div>
              <p>
                Costruire cofanetti digitali con temi coerenti con i messaggi della tua campagna e con i tuoi valori aziendali con Salabam è facile. Contattaci e scopri come.
              </p>
            </b-col>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgSmile />
                <h6>Cura del viaggiatore</h6>
              </div>
              <p>
                Salabam è tecnologia, Salabam è automatizzazione. Ma Salabam è anche fattore umano. Salabam ha uno staff di assistenza interno all'azienda, che lavora con un approccio multicanale e soprattutto con attenzione e cura al viaggiatore.
              </p>
              <p>
                Abbiamo approntato meccanismi semi-automatizzati di checking delle prenotazioni e selezione dei fornitori di servizi che riducono al minimo il tasso di reclamo e il tasso di insoddisfazione da parte del viaggiatore.
              </p>
            </b-col>
          </b-row>

          <br/><br/>


          <FormContattiBusinness
            :formTitle="'Richiedi una demo o un preventivo'"
            :label="'soluzioni-per-il-marketing'"
          />


          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="per-il-welfare-aziendale">

          <b-row>
            <b-col sm="12">

              <h3>Salabam per il welfare aziendale</h3>

              <br/>

              <p>
                I voucher digitali Salabam sono presenti in tutte le principali piattaforme di welfare aziendale italiane e vengono abitualmente utilizzati per prenotare viaggi online, per sè e per la propria famiglia, da decine di migliaia di lavoratori italiani.
              </p>

            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12" lg="6">
              <img src="@/assets/images/servizi_business_2.png">
            </b-col>
            <b-col sm="12" lg="6">
              <p>
                I lavoratori italiani scelgono di usare il loro credito welfare per viaggiare con noi perchè salabam è semplice da usare ed è sicuro
              </p>
            </b-col>
          </b-row>

          <br/><br/>

          <b-row>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgLoader />
                <h6>Semplice e real time</h6>
              </div>
              <p>
                Salabam è facile da utilizzare perchè permette di acquistare un voucher ed utilizzarlo per prenotare un viaggio, in pochi click.
              </p>
              <p>
                Tutte le nostre disponibilità sono prenotabili online senza necessità di richieste di conferma, preventivi e burocrazia.
              </p>
              <p>
                La spesa viene automaticamente addebitata sul conto welfare del lavoratore
              </p>
            </b-col>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgSmile />
                <h6>Cura del viaggiatore</h6>
              </div>
              <p>
                Salabam è tecnologia, Salabam è automatizzazione. Ma Salabam è anche fattore umano. Abbiamo uno staff di assistenza ai viaggiatori interno all'azienda, che lavora con un approccio multicanale e orientamento al cliente.
              </p>
              <p>
                Salabam ha inoltre approntato meccanismi semi-automatizzati di checking delle prenotazioni e selezione dei fornitori di servizi che riducono al minimo il tasso di reclamo e insoddisfazione da parte del lavoratore.
              </p>
            </b-col>
          </b-row>

          <br/><br/><br/>

          <FormContattiBusinness
            :formTitle="'Contattaci per maggiori informazioni'"
            :label="'per-il-welfare-aziendale'"
          />

          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="soluzioni-per-regali-e-pubbliche-relazioni">


          <b-row>
            <b-col sm="12">

              <h3>Soluzioni per regali e pubbliche relazioni</h3>

              <br/>

              <p>
                Con Salabam è facile omaggiare collaboratori, dipendenti e partner d'affari con straordinari voucher di viaggio.
              </p>

            </b-col>
          </b-row>

          <br/><br/>


          <b-row>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgCursors />
                <h6>Tutti i budget</h6>
              </div>
              <p>
                I voucher Salabam partono da un valore minimo di qualche decina di Euro ma possono arrivare anche a svariate migliaia di Euro.
              </p>
              <p>
                Per questo motivo sono ottime idee regalo sia per vaste operazioni sui dipendenti sia per realizzare regali prestigiosi e di alto valore per clienti e partner d'affari.
              </p>
            </b-col>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgPeople />
                <h6>Tu regali, l'altro sceglie</h6>
              </div>
              <p>
                Regalare viaggi per fare pubbliche relazioni è un'idea che ricorre spesso in azienda salvo poi arenarsi nelle difficoltà di scelta e gestione.
              </p>
              <p>
                Salabam ti permette di fare un regalo che lascia al beneficiario la scelta di come, dove e con chi viaggiare.
              </p>
              <p>
                E con Salabam,a differenza dei controversi cofanetti tradizionali, prenotare è facile perchè tutte le disponibilità sono online e prenotabili in tempo reale con pochi click
              </p>
            </b-col>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgWa />
                <h6>Con cura</h6>
              </div>
              <p>
                Il nostro staff di assistenza, interno all'azienda, si prende cura di qualsiasi eventuale problema dovesse verificarsi durante il viaggio.
              </p>
              <p>
                La nostra assistenza ha un approccio multicanale che va dall'email al telefono passando per webchat e WhatsApp
              </p>
            </b-col>
          </b-row>

          <br/><br/><br/>

          <FormContattiBusinness
            :formTitle="'Richiedi una demo o un preventivo'"
            :label="'soluzioni-per-regali-e-pubbliche-relazioni'"
          />

          <br/>

          <TornaIndietro />

        </section>


        <br/><br/><br/><br/>



        <section id="vivamod-modern-travel">


          <b-row>
            <b-col sm="12" md="12">
              <h3>Vivamod | modern travel</h3>
            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12" md="6">
              <p>
                Vivamod è il nostro servizio online di prenotazione viaggi e soggiorni. Si caratterizza per la sua semplicità e la sua sicurezza.
              </p>
              <p>
                Con Vivamod forniamo soluzioni di travel booking integrabili e brandizzabili per i concorsi a premi, i piani loyalty, i programmi fedeltà, i viaggi incentive e i regali aziendali.
              </p>
            </b-col>
            <b-col sm="12" md="6">

              <div class="incentiveIcons">

                <div class="box">
                  <div class="icon">
                    <SvgPacco />
                  </div>
                  <div class="text">
                    <h6>Concorsi ed operazioni a premio</h6>
                    <p>
                      Con Vivamod, finalmente potrai davvero integrare il travel booking all'interno delle tue operazioni a premio e concorsi. Immediatezza, semplicità e sicurezza per i tuoi migliori clienti.
                    </p>
                  </div>
                </div>

                <div class="box">
                  <div class="icon">
                    <SvgHeartOrangeLarge />
                  </div>
                  <div class="text">
                    <h6>Programmi fedeltà e loyalty</h6>
                    <p>
                      Soluzioni di travel reward flessibili e dinamiche pensate per le operazioni di loyalty più avanzate e innovative.
                    </p>
                  </div>
                </div>

                <div class="box">
                  <div class="icon">
                    <SvgChartArrow />
                  </div>
                  <div class="text">
                    <h6>Viaggi incentive</h6>
                    <p>
                      Soluzioni di incentivazione della forza vendita e della rete distributiva dalla gestione semplice e digitale e caratterizzate da grande flessibilità e soddisfazione dei destinatari.
                    </p>
                  </div>
                </div>

              </div>

            </b-col>
          </b-row>

          <br/><br/>

          <b-row>
            <b-col sm="12" md="12" align="center">

              <p><b>Visita Vivamod | modern travel</b></p>
              <b-button variant="secondary">
                <a href="https://www.vivamod.com" target="_blank" style="color:white!important;">Vai al sito</a>
              </b-button>

            </b-col>
          </b-row>

          <br/><br>

          <br/>
          <TornaIndietro />

        </section>


        <section id="blufreccia-sport-event-travel">


          <b-row>
            <b-col sm="12" md="12">
              <h3>Blufreccia.com | Sport event travel</h3>
            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12" md="6">
              <p>
                Salabam ha recentemente acquisito il marchio Blufreccia, azienda che si occupa di viaggi incentive ed eventi aziendali con un forte focus sul mondo degli eventi sportivi.
              </p>
              <p>
                Blufreccia offre una gamma di servizi di progettazione e realizzazione di viaggi incentive, eventi e formazione costruiti su misura per le tue esigenze di marketing e comunicazione
              </p>
            </b-col>
            <b-col sm="12" md="6">

              <div class="incentiveIcons">
                <div class="box">
                  <div class="icon">
                    <SvgCup />
                  </div>
                  <div class="text">
                    <h6>Viaggi incentive</h6>
                    <p>
                      Viaggi di gruppo e individuali per portare i migliori partner della tua azienda sui palcoscenici dei più grandi eventi sportivi internazionali
                    </p>
                  </div>
                </div>

                <div class="box">
                  <div class="icon">
                    <SvgRocket />
                  </div>
                  <div class="text">
                    <h6>Team building e formazione</h6>
                    <p>
                      Team building, formazione, speech motivazionali con i campioni dello sport.
                    </p>
                  </div>
                </div>
              </div>

            </b-col>
          </b-row>

          <br/><br/>

          <b-row>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgPacco />
                <h6>Concorsi a premio</h6>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgHeartOrangeLarge />
                <h6>Loyalty</h6>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="svgIcon">
                <SvgChartArrow />
                <h6>Incentive</h6>
              </div>
            </b-col>
          </b-row>

          <br/><br/><br/>

          <b-row>
            <b-col sm="12" md="12" align="center">

              <p><b>Comincia da qui</b></p>
              <b-button variant="secondary">
                <a href="https://www.blufreccia.com" target="_blank" style="color:white!important;">Visita Blufreccia.com</a>
              </b-button>

            </b-col>
          </b-row>

          <br/><br><br/><br/>

          <FormContattiBusinness
            :formTitle="'Oppure, richiedi una demo o un preventivo'"
            :label="'blufreccia-sport-event-travel'"
          />

          <br/>
          <TornaIndietro />

        </section>


        <br/><br/>

        <section>
          <NewsletterBusiness />
        </section>


      </b-container>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import FormContattiBusinness from '@/components/blocks/common/form-contatti-businness'
import TornaIndietro from '@/components/blocks/common/torna-indietro'
import SearchBar from '@/components/blocks/search-bar'
import SvgPacco from '@/components/svg/pacco'
import SvgTicket from '@/components/svg/ticket'
import SvgLoader from '@/components/svg/loader'
import SvgHeartOrangeLarge from '@/components/svg/heart-orange-large'
import SvgChartArrow from '@/components/svg/chart-arrow'
import SvgLamp from '@/components/svg/lamp'
import SvgPalette from '@/components/svg/palette'
import SvgBrush from '@/components/svg/brush'
import SvgSmile from '@/components/svg/smile'
import SvgCursors from '@/components/svg/cursors'
import SvgPeople from '@/components/svg/people'
import SvgWa from '@/components/svg/whatsapp-orange-large'
import SvgCup from '@/components/svg/cup'
import SvgRocket from '@/components/svg/rocket'
import NewsletterBusiness from '@/components/blocks/common/newsletter-business'

export default {
  name: 'servizi-business',
  metaInfo: {
    title: 'Soluzioni di travel booking per il business',
    meta: [
      { vmid: 'metaDescription', name: 'Description', content: 'Soluzioni di online travel booking per il marketing, il loyalty e il welfare aziendale - Salabam' },
      { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Soluzioni di online travel booking per il marketing, il loyalty e il welfare aziendale - Salabam' },
    ],
  },
  components: {
    FormContattiBusinness,
    TornaIndietro,
    SearchBar,
    SvgPacco,
    SvgTicket,
    SvgLoader,
    SvgHeartOrangeLarge,
    SvgChartArrow,
    SvgLamp,
    SvgPalette,
    SvgBrush,
    SvgSmile,
    SvgCursors,
    SvgPeople,
    SvgWa,
    SvgCup,
    SvgRocket,
    NewsletterBusiness,
  },
  computed:
  {
    occupancy: {
      get() { return this.$store.state.occupancy },
      set(value) { this.mixinSendMutation('setOccupancy',value) }
    },
    /*
    triggerSearch: {
      get() { return this.$store.state.triggerSearch },
      set(value) { this.mixinSendMutation('setTriggerSearch',value) }
    },*/
    dates: {
      get() { return this.$store.state.dates },
      set(value) { this.mixinSendMutation('setDates',value) }
    },
    geo: {
      get() { return this.$store.state.geo },
      set(value) { this.mixinSendMutation('setGeo',value) }
    },
    activeBand: {
      get() { return this.$store.state.activeBand },
      set(value) {
        this.mixinSendMutation('setActiveBand',value)
      }
    },
  },  
  data() {
    return {
      totalPropertiesCount: Vue.prototype.$config.guiSettings.totalPropertiesCount,
    }
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../../../scss/_custom.scss';
#info
{
  img
  {
    width:100%;
  }
  .incentiveIcons
  {
    .box
    {
      display:flex;
    }
    .icon
    {
      min-width:60px;
      width:60px;
      height:60px;
      margin-right:1rem;
      padding:15px;
      border:1px solid $light-gray;
      border-radius: 50%;
      svg
      {
        width:100%;
        height:100%;
        path
        {
          fill:$black;
        }
      }
    }
  }
}
</style>