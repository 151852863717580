var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { staticClass: "text-center" }, [
    _c("br"),
    _c("br"),
    _vm.orderToken == "" || _vm.orderToken == undefined
      ? _c("div", [
          _vm._v("\n        C'è stato un problema, token non trovato!\n    "),
        ])
      : _c(
          "div",
          [
            _vm.loading && this.$router.currentRoute.query.status == "SUCCESS"
              ? _c("span", [
                  _vm._v(
                    "\n          Attendi, stiamo processando il tuo ordine...\n        "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n          Siamo spiacenti, si è verificato un problema.\n          Contatta l'assistenza indicando il seguente numero ordine Scalapay: "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.orderToken))]),
                ]),
            _c("br"),
            _c("br"),
            _vm.loading ? _c("Spinner") : _vm._e(),
          ],
          1
        ),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }