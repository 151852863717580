var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "285",
        height: "200",
        viewBox: "0 0 285 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M38.7292 133.652V131.43H23.8086V141.906C23.8086 147.938 28.5705 152.7 34.6022 152.7H116.19C116.19 153.017 115.872 153.652 115.872 153.969C115.872 160.001 110.158 169.842 103.491 176.192L94.2848 184.763C87.3007 191.112 89.2054 196.192 98.0943 196.192H135.237H172.38C181.269 196.192 183.174 191.112 176.19 184.763L166.983 176.192C159.999 169.842 154.602 160.001 154.602 153.969C154.602 153.652 154.602 153.017 154.285 152.7H184.126V133.334H38.7292V133.652ZM142.856 143.176H139.047H142.856ZM132.38 143.176H128.571H132.38Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.7292 19.3651H228.888V65.0794L243.809 58.4127V10.7936C243.809 4.7619 239.047 0 233.015 0H34.6022C28.5705 0 23.8086 4.7619 23.8086 10.7936V20.9524H38.7292V19.3651Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M139.68 62.5396L129.204 30.1587L118.728 62.5396H84.7598L112.061 82.5396L101.585 114.921L129.204 94.9206L156.823 114.921L146.347 82.5396L173.966 62.5396H139.68Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M56.8254 115.239V37.1434C56.8254 31.7466 52.381 27.6196 46.9841 27.6196H9.84127C4.44444 27.6196 0 32.0641 0 37.1434V115.239C0 120.636 4.44444 124.762 9.84127 124.762H47.3016C52.381 124.762 56.8254 120.318 56.8254 115.239ZM28.5714 117.461C26.0317 117.461 23.8095 115.239 23.8095 112.699C23.8095 110.159 26.0317 107.937 28.5714 107.937C31.1111 107.937 33.3333 110.159 33.3333 112.699C33.3333 115.556 31.1111 117.461 28.5714 117.461ZM7.93651 103.175V42.2228H48.8889V103.175H7.93651V103.175Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.4888 63.8103H32.0602L28.5682 53.0166L25.0761 63.8103H13.3301L22.8539 70.7944L19.0444 81.588L28.5682 74.9214L37.7745 81.588L34.2825 70.7944L43.4888 63.8103Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M279.049 86.984H277.462V76.5078C277.462 74.9205 276.827 73.3332 275.557 72.3808C274.287 71.4284 272.7 70.7935 271.113 71.111L268.255 71.7459L262.541 73.0157V64.7618C262.541 62.857 261.589 61.2697 260.001 60.3173C258.414 59.3649 256.509 59.0475 254.922 59.9999L193.652 86.984C193.652 86.984 193.335 86.984 193.335 87.3014C193.017 87.3014 193.017 87.6189 192.7 87.6189C192.382 87.9364 192.065 87.9364 191.747 88.2538C191.747 88.2538 191.43 88.5713 191.43 88.8887C191.113 89.2062 191.113 89.5237 190.795 89.8411C190.795 90.1586 190.478 90.1586 190.478 90.476C190.16 91.111 190.16 91.7459 190.16 92.0633V92.6983V193.968V194.603C190.16 197.778 192.7 200 195.557 200H209.208H279.049C281.906 200 284.446 197.46 284.446 194.603V92.3808C284.446 89.5237 281.906 86.984 279.049 86.984ZM201.271 188.889V186.984V185.397V98.0951H273.652V189.206H201.271V188.889ZM251.43 74.9205L243.494 76.5078L251.43 73.0157V74.9205ZM266.351 86.984H246.986L266.351 83.1745V86.984Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M228.256 145.395L222.859 162.538L237.462 152.062L252.065 162.538L246.351 145.395L260.954 134.919H242.859L237.462 117.776L231.748 134.919H213.652L228.256 145.395Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }