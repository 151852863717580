var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "publicPage", attrs: { id: "giftCards" } }, [
    _vm._m(0),
    _c("div", { attrs: { id: "page" } }, [
      _c(
        "div",
        { attrs: { id: "info" } },
        [
          _c("b-container", [
            _c("br"),
            _c("br"),
            _c("br"),
            _c("section", [
              _c(
                "h4",
                {
                  staticClass: "text-center",
                  staticStyle: { "font-weight": "normal" },
                },
                [
                  _vm._v(
                    "\n                        Ecco il nostro cofanetto regalo*\n                    "
                  ),
                ]
              ),
              _c("h3", { staticClass: "text-center" }, [
                _vm._v(
                  "\n                      Semplice e potente come il Salabam online\n                    "
                ),
              ]),
            ]),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              [
                _c("Quote", {
                  attrs: {
                    text: "Regalare un voucher per viaggiare è l'augurio più bello che tu possa fare, in ogni occasione!",
                  },
                }),
                _c("br"),
                _c("br"),
                _c("br"),
                _c(
                  "h3",
                  { staticClass: "text-center" },
                  [
                    _c("CopyScadenza", {
                      attrs: {
                        default: "{value} mesi per viaggiare",
                        promoMonths: "{value} mesi per viaggiare",
                        promoFixed: "Viaggia entro il {value}",
                      },
                    }),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("p", [
                  _vm._v(
                    "Tu regali e l'altro sceglie dove e quando viaggiare."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Un servizio che rivoluziona il concetto di cofanetto regalo perché nasce online e le disponibilità sono davvero prenotabili con pochi click, senza attese, senza richieste di conferma e problemi di disponibilità."
                  ),
                ]),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              [
                _c("h3", [_vm._v("Come funziona?")]),
                _c("br"),
                _c("div", { staticClass: "checklist" }, [
                  _c("ul", [
                    _c("li", [
                      _c("span", { staticClass: "checklist_index" }, [
                        _vm._v("1"),
                      ]),
                      _c("div", { staticClass: "checklist_item" }, [
                        _c("span", [
                          _vm._v("Scegli il voucher che vuoi regalare sullo "),
                          _c("a", { attrs: { href: "/shop" } }, [
                            _vm._v("shop"),
                          ]),
                          _vm._v(" oppure "),
                          _c("a", { attrs: { href: "/ricerca" } }, [
                            _vm._v("facendo ricerche"),
                          ]),
                          _vm._v(" per vedere le disponibilità"),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "checklist_index" }, [
                        _vm._v("2"),
                      ]),
                      _c("div", { staticClass: "checklist_item" }, [
                        _c("span", [
                          _vm._v(
                            'Nella pagina di checkout seleziona l\\\'opzione "È un regalo" e scegli se lo vuoi spedire per mail o vuoi inviare la scatolina regalo e gift card per corriere.'
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "checklist_index" }, [
                        _vm._v("3"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "checklist_item" },
                        [
                          _c("CopyScadenza", {
                            attrs: {
                              default:
                                "Il destinatario del regalo riceverà la scatolina dopo massimo 4 giorni e avrà ben {value} mesi per scegliere quando, dove e in quale hotel andare scegliendo online tra 450.000 hotel in tutto il mondo",
                              promoMonths:
                                "Il destinatario del regalo riceverà la scatolina dopo massimo 4 giorni e avrà ben {value} mesi per scegliere quando, dove e in quale hotel andare scegliendo online tra 450.000 hotel in tutto il mondo",
                              promoFixed:
                                "Il destinatario del regalo riceverà la scatolina dopo massimo 4 giorni e avrà tempo fino al {value} per scegliere quando, dove e in quale hotel andare scegliendo online tra 450.000 hotel in tutto il mondo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          "\n                                E ricorda la promozione: la scatolina, la gift card e la spedizione sono gratis!.\n                            "
                        ),
                      ]),
                    ]),
                    _c("br"),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modalGiftCardsInfo",
                            modifiers: { modalGiftCardsInfo: true },
                          },
                        ],
                        staticClass: "ml-2 mr-2",
                      },
                      [_vm._v("Vedi la gift card")]
                    ),
                    _vm.config.guiSettings.giftCardsEnabled
                      ? _c(
                          "b-button",
                          {
                            staticClass: "ml-2 mr-2",
                            on: {
                              click: function ($event) {
                                return _vm.mixinGoTo("homepage", {})
                              },
                            },
                          },
                          [_vm._v("Vai allo shop")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("ModalGiftCard"),
                _c("br"),
                _c("br"),
                _c("p", [
                  _vm._v(
                    "* Attenzione: non disponibile per acquisti con welfare aziendale"
                  ),
                ]),
              ],
              1
            ),
            !_vm.config.guiSettings.giftCardsEnabled
              ? _c(
                  "section",
                  [
                    _c("br"),
                    _c("br"),
                    _c("br"),
                    _c("FormGiftCard", {
                      attrs: {
                        formTitle:
                          "Resta aggiornato sul lancio che avverrà tra qualche giorno",
                        recipient: _vm.config.site.emailInfo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "business" } },
              [
                _c("h3", [_vm._v("Regalo aziendale?")]),
                _c("br"),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n                        La gift card Salabam è un'ottima idea per regali aziendali ai tuoi migliori clienti, partner e collaboratori. Una gift card Salabam\n                    "
                  ),
                ]),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "text-center",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgDiamond"),
                            _c("h6", [_vm._v("Un regalo importante")]),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                                Regalare un viaggio che il destinatario può scegliere online è un'idea innovativa e originale.\n                                "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                                E con Salabam hai un'ampia scelta, da 75€ fino a 3500€ per ogni esigenza: clienti importanti, partner, collaboratori.\n                            "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "text-center",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgLoader"),
                            _c("h6", [_vm._v("Un regalo smart")]),
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "p",
                          [
                            _c("CopyScadenza", {
                              attrs: {
                                default:
                                  "Salabam è smart perché tu regali un viaggio ma è il destinatario del regalo che avrà {value} mesi per scegliere quando e dove viaggiare.",
                                promoMonths:
                                  "Salabam è smart perché tu regali un viaggio ma è il destinatario del regalo che avrà {value} mesi per scegliere quando e dove viaggiare.",
                                promoFixed:
                                  "Salabam è smart perché tu regali un viaggio ma è il destinatario del regalo che avrà tempo fino al {value} per scegliere quando e dove viaggiare.",
                              },
                            }),
                            _c("br"),
                            _vm._v(
                              "\n                                È smart perché regali un box di cartoncino ecologico e una card di design da collezionare.\n                            "
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "text-center",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgPacco"),
                            _c("h6", [_vm._v("Un cofanetto 2.0")]),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                                Salabam non è il solito cofanetto regalo: lo prenoti online, su disponibilità aggiornate in tempo reale ed a conferma immediata.\n                            "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "text-center",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgRefund"),
                            _c("h6", [_vm._v("Un regalo sicuro")]),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                                Regali una gift card ma hai tutto il valore di Salabam in fatto di sicurezza che ha reso Salabam un servizio immancabile nei piani welfare aziendale delle più innovative e prestigiose aziende italiane.\n                            "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "text-center",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [_c("SvgBranding"), _c("h6", [_vm._v("Branding")])],
                          1
                        ),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                            Ti proponiamo diverse soluzioni di brandizzazione della scatolina, della card e del sito web.\n                          "
                          ),
                        ]),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                            Per regali e promozioni totalmente in linea con la tua immagine coordinata aziendale.\n                          "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "text-center",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgIntegrazione"),
                            _c("h6", [_vm._v("Integrazione")]),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n                            Le nostre soluzioni business sono integrabili con le tue piattaforme IT: siti web, intranet, pagine promozionali e web app.\n                          "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { staticClass: "text-center" },
              [
                _c(
                  "b-button",
                  { attrs: { variant: "secondary" } },
                  [
                    _c(
                      "router-link",
                      {
                        staticStyle: { color: "white!important" },
                        attrs: { to: { name: "servizi-business" } },
                      },
                      [_vm._v("Scopri tutte le nostre soluzioni business")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("section", [
              _c("div", { staticClass: "panel panel-dark-blue" }, [
                _c("div", { staticClass: "panel-inner text-center" }, [
                  _c("strong", [
                    _vm._v(
                      "\n                                Contattaci per un preventivo personalizzato all'indirizzo: "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "primary",
                        attrs: {
                          href: "mailto:" + _vm.config.site.emailBusiness,
                        },
                      },
                      [_vm._v(_vm._s(_vm.config.site.emailBusiness))]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "section",
              [
                _c("h3", [_vm._v("La sicurezza Salabam")]),
                _c("br"),
                _c("p", [
                  _vm._v(
                    "\n                        Che sia un regalo per un tuo caro o per un cliente della tua azienda, la sicurezza delle persone per te importanti è la nostra priorità.\n                    "
                  ),
                ]),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  { staticClass: "nogutter" },
                  [
                    _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/immagine-pagina-prodotto.jpg"),
                        },
                      }),
                    ]),
                    _c(
                      "b-col",
                      {
                        staticClass: "hasBackground block",
                        attrs: { sm: "12", md: "6" },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "\n                                La nostra assistenza: valida e multicanale\n                            "
                          ),
                        ]),
                        _c("div", [
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "\n                                        possibilità di relazionarti in maniera efficace con persone, non con chatbot o algoritmi\n                                    "
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "\n                                        chat attiva, assistenza telefonica, supporto Whatsapp e via email\n                                    "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("b-container", { attrs: { fluid: "" } }, [
            _c("section", [_c("ValuePropositions")], 1),
          ]),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("b-container", [
            _c("section", [
              _c("div", { staticClass: "panel panel-dark-blue" }, [
                _c("div", { staticClass: "panel-inner text-center" }, [
                  _c("strong", [
                    _vm._v(
                      "\n                                Contattaci per un preventivo personalizzato all'indirizzo: "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "primary",
                        attrs: {
                          href: "mailto:" + _vm.config.site.emailBusiness,
                        },
                      },
                      [_vm._v(_vm._s(_vm.config.site.emailBusiness))]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heroContainer" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/giftcard-header.jpg") },
      }),
      _c("div", { staticClass: "captions" }, [
        _c("h2", { staticClass: "secondaryCaption" }, [
          _vm._v(
            "Una bellissima card racchiusa in un cofanetto regalo ecologico e di design. Ma è sempre Salabam: semplice e sicuro. Online!"
          ),
        ]),
        _c("h3", { staticClass: "primaryCaption" }, [
          _c("span", [
            _vm._v("Nessun supplemento."),
            _c("br"),
            _vm._v("SPEDIZIONE GRATUITA!"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }