var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _vm.formTitle != ""
                ? _c("h5", { staticClass: "formTitle" }, [
                    _vm._v(_vm._s(_vm.formTitle)),
                  ])
                : _vm._e(),
              _c("br"),
              _c(
                "b-form-group",
                { attrs: { state: _vm.validateFields() } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sd: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "customInputBlock" },
                          [
                            _c("b-form-input", {
                              ref: "email",
                              attrs: {
                                required: true,
                                type: "email",
                                placeholder: "la tua email",
                                "data-e2e": "salabamInput",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _c("span", { staticClass: "formError" }),
                            _c("span", {
                              staticClass: "formClear",
                              on: {
                                click: function ($event) {
                                  return _vm.clearField("email")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("b-col", { attrs: { sd: "12", md: "6" } }, [
                        _vm.loading
                          ? _c("div", [_c("Spinner")], 1)
                          : _c("div", [
                              _vm.apiResponse !== ""
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          attrs: { variant: "info", show: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.apiResponse) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            disabled: !_vm.validateFields(),
                                          },
                                          on: { click: _vm.submitForm },
                                        },
                                        [_vm._v("RESTA AGGIORNATO")]
                                      ),
                                    ],
                                    1
                                  ),
                            ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "customCheckboxBlock" },
                          [
                            _c("b-form-checkbox", {
                              ref: "privacy",
                              attrs: {
                                type: "checkbox",
                                name: "privacy",
                                id: _vm._uid + "-privacy",
                              },
                              model: {
                                value: _vm.privacy,
                                callback: function ($$v) {
                                  _vm.privacy = $$v
                                },
                                expression: "privacy",
                              },
                            }),
                            _c(
                              "label",
                              { attrs: { for: _vm._uid + "-privacy" } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "secondary",
                                    attrs: {
                                      href: _vm.privacyPolicyUrl,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Ho letto ed accetto la Privacy Policy"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }