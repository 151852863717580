var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "209",
        height: "200",
        viewBox: "0 0 209 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M208.346 15.7651H79.1345V4.01855V0H75.1159H22.8748H18.8563V4.01855V15.7651H0V19.7836V23.8022H18.8563V35.5487V39.5672H22.8748H75.1159H79.1345V35.5487V23.8022H187.944H208.346V19.7836V15.7651Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M208.346 176.198H134.158V164.451V160.433H130.139H78.2071H74.1886V164.451V176.198H0V180.216V184.235H66.1515H74.1886V184.544V195.981V200H78.2071H130.139H134.158V195.981V184.235H196.291H208.346V180.216V176.198Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M0 103.864H20.4019H129.212V115.611V119.629H133.23H185.162H189.181V115.611V103.864H208.346V99.8456V95.827H189.181V84.3896V80.3711H185.162H133.23H129.212V84.3896V95.827H0V99.8456V103.864Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }