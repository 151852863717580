var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage landingPage landingScalapay" },
    [
      _c(
        "div",
        {
          style:
            "background-image:url(" +
            require("../../../assets/landing/hero-landing-default.jpg") +
            ")",
          attrs: { id: "hero" },
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "heroCol", attrs: { sm: "12", lg: "6" } },
                    [
                      _c("img", {
                        staticClass: "heroLogo",
                        attrs: { src: "assets/scalapay/logo-black.svg" },
                      }),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "heroCol", attrs: { sm: "12", lg: "6" } },
                    [
                      _c(
                        "div",
                        { staticClass: "panel panel-white large-radius" },
                        [
                          _c(
                            "div",
                            { staticClass: "panel-content" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "value",
                                      attrs: { sm: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "valueIcon spin" },
                                        [_c("SvgPurchaseSpin")],
                                        1
                                      ),
                                      _c("div", { staticClass: "valueText" }, [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "I Salabam sono voucher per prenotare viaggi online"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "value",
                                      attrs: { sm: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "valueIcon ticket" },
                                        [_c("SvgTicket")],
                                        1
                                      ),
                                      _c("div", { staticClass: "valueText" }, [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "Voucher da 75 a 3.500€ che potrai acquistare pagando in 3 semplici rate con Scalapay"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "value",
                                      attrs: { sm: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "valueIcon bed" },
                                        [_c("SvgPurchaseBed")],
                                        1
                                      ),
                                      _c("div", { staticClass: "valueText" }, [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "450.000 hotel, bed&breakfast e appartamenti in Italia e nel mondo prenotabili con pochi click"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "value",
                                      attrs: { sm: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "valueIcon whatsapp" },
                                        [_c("SvgWhatsapp")],
                                        1
                                      ),
                                      _c("div", { staticClass: "valueText" }, [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "Un servizio di supporto a tua disposizione via chat, Whatsapp, telefono e email"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-container", { staticClass: "pageContent content" }, [
        _c("section", [
          _vm._v(
            "\n            I Salabam sono voucher che permettono di prenotare soggiorni su disponibilità aggiornate in tempo reale e a conferma immediata.\n        "
          ),
        ]),
        _c(
          "section",
          [
            _c("h1", { staticClass: "title" }, [
              _vm._v("Ordina ora, paga poi"),
            ]),
            _c(
              "b-row",
              { staticClass: "icons" },
              [
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-blu-1.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _vm._v("3 COMODE"),
                      _c("br"),
                      _vm._v("RATE"),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-blu-2.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _vm._v("0%"),
                      _c("br"),
                      _vm._v("INTERESSE"),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-blu-3.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _vm._v("SEMPLICE E"),
                      _c("br"),
                      _vm._v("VELOCE"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
        _c("br"),
        _c("h2", { staticClass: "title" }, [
          _vm._v("Come si acquista un voucher Salabam pagando con Scalapay?"),
        ]),
        _c("section", [
          _c("div", { staticClass: "checklist" }, [
            _c("ul", [
              _c("li", [
                _c("span", { staticClass: "checklist_index" }, [_vm._v("1")]),
                _c("div", { staticClass: "checklist_item" }, [
                  _c("p", { staticClass: "primary" }, [
                    _vm._v("SCEGLI IL VOUCHER SALABAM"),
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n                            Clicca sul bottone INIZIA in fondo alla pagina e trova il voucher adatto al tuo budget e alle tue necessità.\n                            "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                            Puoi vedere in tempo reale le disponibilità in oltre 450.000 hotel, B&B, appartamenti in Italia e nel mondo.                            \n                        "
                    ),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "checklist_index" }, [_vm._v("2")]),
                _c("div", { staticClass: "checklist_item" }, [
                  _c("p", { staticClass: "primary" }, [
                    _vm._v(
                      "EFFETTUA L’ORDINE SEELEZIONANDO COME METODO DI PAGAMENTO SCALAPAY"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n                            Seleziona il Salabam che intendi acquistare e clicca su SCEGLI, in fase di conferma dell’ordine potrai selezionare come metodo di pagamento Scalapay.\n                        "
                    ),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "checklist_index" }, [_vm._v("3")]),
                _c("div", { staticClass: "checklist_item" }, [
                  _c("p", { staticClass: "primary" }, [
                    _vm._v("PRENOTA IL TUO VIAGGIO ONLINE"),
                  ]),
                  _c(
                    "p",
                    [
                      _vm._v(
                        "\n                            Riceverai per email il voucher che hai acquistato. Basterà seguire il link per prenotare il il tuo soggiorno. \n                            "
                      ),
                      _c("CopyScadenza", {
                        attrs: {
                          default:
                            "Avrai {value} mesi per effettuare la tua prenotazione e viaggiare scegliendo tra disponibilità aggiornate in tempo reale e a conferma immediata.",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "section",
          [
            _c(
              "b-row",
              { staticClass: "icons pink" },
              [
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-pink-1.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _vm._v("Ricevi il link per il pagamento via email o sms"),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-pink-2.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _vm._v("Crea il tuo account/Login"),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  { staticClass: "box", attrs: { sm: "12", md: "4" } },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "assets/scalapay/icon-pink-3.svg" },
                    }),
                    _c("p", { staticClass: "text" }, [
                      _vm._v("Paga in 3 rate, senza interessi"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("section", [
          _c("p", { staticClass: "textAbove linkScalapay text-center" }, [
            _vm._v("\n                Visita "),
            _c(
              "a",
              { attrs: { href: "https://www.scalapay.com", target: "_blank" } },
              [_vm._v("www.scalapay.com")]
            ),
            _vm._v(" per tutte le condizioni.\n            "),
          ]),
        ]),
        _c("section", [
          _c(
            "div",
            { staticClass: "boxed text-center" },
            [
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Clicca il bottone e scopri i voucher e le disponibilità"
                  ),
                ]),
              ]),
              _c("br"),
              _c(
                "b-button",
                { attrs: { variant: "secondary" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "white",
                      attrs: { to: { name: "homepage" } },
                    },
                    [_vm._v("Inizia")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }