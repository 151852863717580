var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "150",
        height: "150",
        viewBox: "0 0 150 150",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "75",
          cy: "75",
          r: "74",
          fill: "white",
          stroke: "#F2F2F2",
          "stroke-width": "2",
        },
      }),
      _c("path", {
        attrs: {
          d: "M71.5821 58.6451C71.8724 59.129 72.3563 59.3225 72.8401 59.3225C73.324 59.3225 73.8079 59.0322 74.0982 58.6451L81.0659 46.7419C81.453 46.0645 81.2595 45.1935 80.5821 44.7096C79.9047 44.3225 79.0337 44.5161 78.5498 45.1935L72.8401 54.9677L71.0982 51.9677C70.7111 51.2903 69.8401 50.9999 69.0659 51.4838C68.3885 51.8709 68.0982 52.7419 68.5821 53.5161L71.5821 58.6451Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M74.7741 66.5806C82.9032 66.5806 89.4838 60 89.4838 51.871C89.4838 43.7419 82.9032 37.2581 74.7741 37.2581C66.6451 37.2581 60.0645 43.8387 60.0645 51.9677C60.0645 60.0968 66.6451 66.5806 74.7741 66.5806ZM74.7741 40.1613C81.258 40.1613 86.5806 45.4839 86.5806 51.9677C86.5806 58.4516 81.258 63.6774 74.7741 63.6774C68.2903 63.6774 62.9677 58.3548 62.9677 51.871C62.9677 45.3871 68.2903 40.1613 74.7741 40.1613Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M104.097 30H45.4516C44.6774 30 44 30.6774 44 31.4516V118.548C44 119.323 44.6774 120 45.4516 120H104C104.774 120 105.452 119.323 105.452 118.548V31.4516C105.548 30.6774 104.871 30 104.097 30ZM102.645 117.097H46.9032V32.9032H102.548V117.097H102.645Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.9692 105.484H94.7111C95.4853 105.484 96.1627 104.806 96.1627 104.032C96.1627 103.258 95.4853 102.581 94.7111 102.581H53.9692C53.195 102.581 52.5176 103.258 52.5176 104.032C52.5176 104.806 53.195 105.484 53.9692 105.484Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.9692 95.8065H94.7111C95.4853 95.8065 96.1627 95.1291 96.1627 94.3549C96.1627 93.5807 95.4853 92.9033 94.7111 92.9033H53.9692C53.195 92.9033 52.5176 93.5807 52.5176 94.3549C52.5176 95.1291 53.195 95.8065 53.9692 95.8065Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.9692 86.1291H94.7111C95.4853 86.1291 96.1627 85.4516 96.1627 84.6774C96.1627 83.9032 95.4853 83.2258 94.7111 83.2258H53.9692C53.195 83.2258 52.5176 83.9032 52.5176 84.6774C52.5176 85.4516 53.195 86.1291 53.9692 86.1291Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.9692 76.4516H94.7111C95.4853 76.4516 96.1627 75.7741 96.1627 75C96.1627 74.2258 95.4853 73.5483 94.7111 73.5483H53.9692C53.195 73.5483 52.5176 74.2258 52.5176 75C52.5176 75.7741 53.195 76.4516 53.9692 76.4516Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }