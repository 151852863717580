var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "blog-topbar " + _vm.categorySlug }, [
    _c(
      "div",
      {
        on: {
          click: function ($event) {
            return _vm.mixinGoTo(_vm.goto, {})
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "topbar" },
          [
            _c("b-container", { attrs: { fluid: "" } }, [
              _c("h1", {
                staticClass: "page-title pointer",
                domProps: { innerHTML: _vm._s(_vm.title) },
              }),
              _c("p", {
                staticClass: "page-subtitle",
                domProps: { innerHTML: _vm._s(_vm.subtitle) },
              }),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }