<template>
  <div id="info" class="publicPage chi-siamo">
    
    <div class="heroContainer">
      <img src="@/assets/images/chi_siamo_header.jpg" id="about">
      <div class="publicBookingEngine">
        <SearchBar
          :loading="false"
          :band="activeBand"
          :focus="''"
          :geo="geo"
          :dates="dates"
          :occupancy="occupancy"
          :hasResults="false"
          :offset="0"
          :lastSearch="''"
          v-on:submit-search="mixinSendMutation('setTriggerSearch',true);mixinGoTo('search',{})"
          v-on:filter-changed="{}"
          :showUserBox="false"
        />
      </div>
    </div>

    <div id="page">


      <b-container>

        <section>

          <!--<h3 class="text-center">Chi siamo</h3>
          <br><br>-->

          <b-row>
            <b-col sm="12">

              <br/>

              <p>
                Salabam è una azienda italiana che fornisce servizi e soluzioni di online travel booking a viaggiatori e aziende.

                <br><br>

                Impieghiamo, tra Rimini e Verona, professionalità provenienti dal mondo del travel, dell’incentive, della regalistica di consumo e dell’Information Technology.
              </p>
            </b-col>
          </b-row>

        </section>

        <br/><br/><br/><br/>

        <section id="per-i-viaggiatori">

          <b-row>
            <b-col sm="12">
              <h3>Chi siamo: Salabam per i viaggiatori</h3>

              <br/>

              <p>
                Su Salabam.com puoi acquistare voucher per prenotare viaggi. Puoi acquistare voucher per te o per farne un regalo lasciando al destinatario la scelta di quando e dove viaggiare. Tu o il destinatario del regalo avrete sempre tante opzioni di prenotazione perché Salabam attinge alle disponibilità dinamiche di oltre {{totalPropertiesCount}} hotel, B&B e appartamenti in Italia e nel mondo.
              </p>

            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12" lg="6">
              <img src="@/assets/images/chi_siamo_1.png">
            </b-col>
            <b-col sm="12" lg="6">
              <p>
                La prenotazione viene fatta online con conferma in tempo reale, senza necessità di verifiche, telefonate o conferme di disponibilità.
              </p>
            </b-col>
          </b-row>

          <br/><br/>

          <b-row>
            <b-col sm="12">
              <p>
                Puoi comperare un voucher Salabam con la tua carta di credito, con il tuo welfare aziendale<span v-if="mixinUsePromoshopping()">, con i tuoi PromoShopping</span><span v-if="mixinUseTicketCompliments()"> e con Edenred Ticket Compliments</span>.
              </p>
            </b-col>
          </b-row>

          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="per-le-aziende">

          <b-row>
            <b-col sm="12">
              <h3>Chi siamo: Salabam per le aziende</h3>
            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12" lg="6">
              <p>
                Forniamo soluzioni di online travel booking utilizzate nei concorsi a premi, nel loyalty, nelle promozioni e nelle incentivazioni.
              </p>
              <p>
                Le nostre soluzioni, grazie alla straordinaria flessibilità e alla possibilità di integrazione tecnologica con le piattaforme IT dei clienti, permettono un utilizzo rivoluzionario dei viaggi all'interno di campagne di marketing e comunicazione.
              </p>
            </b-col>
            <b-col sm="12" lg="6">
              <img src="@/assets/images/chi_siamo_2.png">
            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12">

              <p>
                Salabam opera anche nell’ambito del welfare aziendale: la nostra è la soluzione più avanzata, semplice e flessibile per inserire viaggi all'interno dei piani welfare. Salabam è stata scelta da operatori leader come AON, Double-You, Edenred EasyWelfare, Eudaimon, Jakala, Jointly, Sodexo, Willis Towers Watson e molti altri.
              </p>

            </b-col>
          </b-row>

          <br/>

          <b-row class="nogutter">
            <b-col sm="12" md="6">
              <img src="@/assets/images/chi_siamo_3.png">
            </b-col>
            <b-col sm="12" md="6" class="hasBackground">
              <p>
                Ogni giorno forniamo un servizio a valore aggiunto ai dipendenti di importanti aziende italiane ed internazionali quali Accenture, Auchan, Banca Intesa, Banco BPM, Chiesi, Ferragamo, Fincantieri, Gucci, IMA, Luxottica, Unicredit, ecc.
              </p>
            </b-col>
          </b-row>

          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="per-gli-hotel-manager">

          <b-row>
            <b-col sm="12">
              <h3>Chi siamo: Salabam per gli hotel manager e i gestori di B&B e appartamenti</h3>

              <br/>

              <p>
                Tramite formali accordi di fornitura e partnership, Salabam attinge alle disponibilità online delle principali Online Travel Agency globali e bed banks.
              </p>

              <br/>

              <p>
                Non è possibile essere inseriti direttamente tra le nostre disponibilità ma probabilmente la tua struttura è presente tra i nostri risultati se hai inserito le tue disponibilità sui channel più importanti e conosciuti.
              </p>

              <br/>

              <Quote :text="'E\' probabile che tu venga contattato dal nostro staff via email o telefonicamente in quanto, nell\'ottica di fornire un servizio a valore aggiunto alle nostre aziende clienti e ai loro viaggiatori, verifichiamo tutte le prenotazioni qualche giorno prima dell\'arrivo del viaggiatore.\n'+
'\n'" />

            </b-col>
          </b-row>

          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="per-le-ota">

          <b-row>
            <b-col sm="12">
              <h3>Chi siamo: Salabam per OTA, bed banks e fornitori di servizi travel</h3>

              <br/>

              <p>
                La nostra è una realtà in rapida crescita nel panorama dell'online travel italiano. Abbiamo un approccio di business che privilegia la fornitura di servizi a valore aggiunto a clienti aziendali sviluppando soluzioni integrate per il corporate welfare, il loyalty e il marketing in generale.
              </p>

              <br/><br/>

              <!-- TODO questa sezione noguter andrebbe invertita (test/immagine) e con sfondo blu -->

              <b-row class="nogutter">

                <b-col sm="12" md="6">
                  <img src="@/assets/images/chi_siamo_4.png">
                </b-col>

                <b-col sm="12" md="6" class="hasBackground">
                  <p>
                    Siamo sempre interessati a valutare la qualità di nuovi canali di fornitura di servizi online di tipo travel che abbiano un approccio tecnologicamente integrabile tramite API (Application Programming Interface)
                  </p>
                </b-col>

              </b-row>

            </b-col>
          </b-row>

        </section>

      </b-container>

    </div>


  </div>
</template>

<script>
import Vue from 'vue'
import Quote from '../../blocks/common/quote'
import TornaIndietro from '@/components/blocks/common/torna-indietro'
import SearchBar from '@/components/blocks/search-bar'

export default {
  name: 'chi-siamo',
  metaInfo: {
    title: 'La nostra azienda',
    meta: [
      { vmid: 'metaDescription', name: 'Description', content: 'Ecco la nostra realtà fatta di competenza, tecnologia e attenzione al cliente. Scopri perchè Salabam è la scelta di centinaia di aziende italiane' },
      { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Ecco la nostra realtà fatta di competenza, tecnologia e attenzione al cliente. Scopri perchè Salabam è la scelta di centinaia di aziende italiane' },
    ],
  },
  components: {
    Quote,
    TornaIndietro,
    SearchBar,
  },
  computed:
  {
    occupancy: {
      get() { return this.$store.state.occupancy },
      set(value) { this.mixinSendMutation('setOccupancy',value) }
    },
    /*
    triggerSearch: {
      get() { return this.$store.state.triggerSearch },
      set(value) { this.mixinSendMutation('setTriggerSearch',value) }
    },*/
    dates: {
      get() { return this.$store.state.dates },
      set(value) { this.mixinSendMutation('setDates',value) }
    },
    geo: {
      get() { return this.$store.state.geo },
      set(value) { this.mixinSendMutation('setGeo',value) }
    },
    activeBand: {
      get() { return this.$store.state.activeBand },
      set(value) {
        this.mixinSendMutation('setActiveBand',value)
      }
    },
  },  
  data() {
    return {
      totalPropertiesCount: Vue.prototype.$config.guiSettings.totalPropertiesCount,
    }
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../../../scss/_custom.scss';
#info
{
  img
  {
    width:100%;
  }
}
</style>