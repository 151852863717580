<template>
  <div id="info" class="publicPage assistenza">  

    <div class="heroContainer">
      <img src="@/assets/images/assistenza_header.jpg" id="about">
      <div class="publicBookingEngine">
        <SearchBar
          :loading="false"
          :band="activeBand"
          :focus="''"
          :geo="geo"
          :dates="dates"
          :occupancy="occupancy"
          :hasResults="false"
          :offset="0"
          :lastSearch="''"
          v-on:submit-search="mixinSendMutation('setTriggerSearch',true);mixinGoTo('search',{})"
          v-on:filter-changed="{}"
          :showUserBox="false"
        />
      </div>
    </div>

    <div id="page">

      <b-container>

        <section id="viaggi-sicuro">
          <br/>

          <h3>Perchè con Salabam Viaggi sicuro</h3>

          <br/><br/>

          <p>
            Il nostro impegno incessante è concentrato ogni giorno su due cose: la tecnologia e la cura del servizio che ti forniamo.
          </p>

          <p>
            Della tecnologia <a href="#tecnologia">parliamo qui</a>. Per quanto riguarda la cura del servizio e della tua esperienza devi sapere che:
          </p>

          <br/>

          <b-row>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgDoubleCheck />
                <h6>Double check</h6>
              </div>
              <p>
                Il nostro staff, supportato da efficienti dispositivi tecnologici, verifica la tua prenotazione pochi giorni prima del tuo arrivo.
              </p>
              <p>
                La tua prenotazione è naturalmente confermata in tempo reale nel momento in cui hai cliccato sul tasto PRENOTA ma, con pignoleria, effettuiamo questo controllo ridondante qualche giorno prima del tuo check-in facendo in modo che al tuo arrivo sia tutto pronto.
              </p>
            </b-col>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgHeartLarge />
                <h6>White list</h6>
              </div>
              <p>
                Con un sapiente mix di tecnologia e competenza umana, effettuiamo una selezione delle strutture che ti proponiamo valutando non solo la qualità della struttura stessa ma anche la sua esperienza e professionalità.
              </p>
              <p>
                Cerchiamo infatti di evitare di proporti B&B, appartamenti magari appena aperti e gestiti da operatori improvvisati e non professionali.
              </p>
            </b-col>
          </b-row>

          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>


        <section id="covid19">

          <h3>Covid-19</h3>
          <br/>

          <p>
            Monitoriamo costantemente la situazione a livello sanitario e normativo e stiamo operando in questo modo al fine di farti viaggiare con sicurezza e tranquillità:
          </p>

          <br/>

            <div class="checklist">
                <ul>
                    <li>
                        <span class="checklist_index">1</span>
                        <div class="checklist_item">
                            <CopyScadenza    />
                        </div>
                    </li>
                    <li>
                        <span class="checklist_index">2</span>
                        <div class="checklist_item">
                            <span>Il nostro sito propone un numero sempre maggiore di soluzioni con Cancellazione gratuita. <!--Alla luce delle incertezze causate dalla pandemia ti consigliamo caldamente di scegliere questo genere di soluzioni che ti permetteranno di cancellare una prenotazione gratuitamente con l'automatica ri-attivazione del voucher.--></span>
                        </div>
                    </li>
                    <li>
                        <span class="checklist_index">3</span>
                        <div class="checklist_item">
                            <span>
                                Abbiamo raccolto e messo in evidenza nella pagina di ogni singola struttura le misure anti-Covid e i protocolli di sicurezza adottati dalla stessa
                            </span>
                        </div>
                    </li>
                </ul>
            </div>

          <br />

          <p>
            Ti ricordiamo che le soluzioni Non rimborsabili non prevedono, in caso di cancellazione, alcun tipo di rimborso. Su tua richiesta e in base a specifici motivi legati alla pandemia saremo felici di darti supporto richiedendo alla struttura un rimborso ma resta inteso che la concessione di un rimborso resta una decisione totalmente discrezionale della struttura stessa sulla quale noi non abbiamo alcuna specifica influenza.
          </p>

          <br />

          <div class="torna-indietro mt-4 mb-4 d-flex justify-content-end">
            <i>ultimo aggiornamento {{lastUpdateDate}}</i>
          </div>

          <br/>

          <TornaIndietro />

        </section>


        <br/><br/><br/><br/>

        <section id="tecnologia">

          <br/>

          <h3>La nostra tecnologia per te</h3>

          <br/>

          <p>
            L'incessante sviluppo tecnologico che caratterizza la nostra azienda e i servizi che forniamo è ispirato da due principi:
          </p>

          <br/><br/>

          <b-row>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgRocket />
                <h6>On the edge</h6>
              </div>
              <p>
                essere sempre sulla frontiera avanzata della tecnologia web e IT con lo scopo di fornirti un servizio di prenotazione efficiente e un'esperienza sicura e divertente
              </p>
            </b-col>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgPersonCircle />
                <h6>Tecnologia accessibile</h6>
              </div>
              <p>
                fornire un servizio online multi-device, con elevati tassi di usabilità all'insegna della semplicità d'uso per qualsiasi tipo di utente anche quello meno abituato agli acquisti online
              </p>
            </b-col>
          </b-row>

          <br/><br/><br/><br/>

          <Quote :text="'Per farlo abbiamo costruito nel tempo un team di sviluppo interno che si avvale di eccezionali sviluppatori web, back e front, web designer, esperti di User Experience (UX) e User Interface (UI).<br/><br/>Il nostro team di sviluppo tecnologico dialoga quotidianamente con il team di sviluppo del business e con quello di customer care.'" />

          <br/>

          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="acquista-con-il-welfare-aziendale">

          <b-row>
            <b-col sm="12">

              <br/>

              <h3>Acquista un Salabam con il welfare aziendale!</h3>

              <br/><br/>

              <p>
                Vuoi utilizzare il tuo credito welfare per acquistare un voucher Salabam? Ecco come fare:
              </p>
              <!--<Checklist :items="checklistItems_acquistaWelfare" :numbers="true" />-->
              <!-- copincollato markup checklist perché non posso passare un <CopyScadenza /> al componente <Checklist />-->
                <div class="checklist">
                    <ul>
                        <li>
                            <span class="checklist_index">1</span>
                            <div class="checklist_item">
                                <span>Accedi al tuo portale welfare e cerca il nostro servizio</span>
                            </div>
                        </li>
                        <li>
                            <span class="checklist_index">2</span>
                            <div class="checklist_item">
                                <span>Una volta individuato Salabam troverai un link per accedere al nostro sito. Cliccando su questo link il sito Salabam riconoscerà il tuo credito e ti permetterà di acquistare il voucher in tempo reale e con un addebito diretto del costo sul tuo conto welfare</span>
                            </div>
                        </li>
                        <li>
                            <span class="checklist_index">3</span>
                            <div class="checklist_item">
                                <span>
                                    Riceverai per email il voucher che hai acquistato. Basterà seguire il link per prenotare il tuo soggiorno. 
                                    <CopyScadenza
                                        default="Avrai comunque {value} mesi per effettuare la tua prenotazione e viaggiare scegliendo tra disponibilità aggiornate in tempo reale e a conferma immediata."
                                    />
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>


              <br/><br/>

              <b-row class="nogutter">
                <b-col sm="12" md="6">
                  <img src="@/assets/images/assistenza.png">
                </b-col>
                <b-col sm="12" md="6" class="hasBackground">
                  <p>
                    Salabam è presente in tutti i principali portali welfare italiani e serve decine di migliaia di lavoratori di centinaia di PMI italiane ed internazionali
                  </p>
                </b-col>
              </b-row>

            </b-col>
          </b-row>

          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="acquista-un-voucher-salabam">

          <b-row>
            <b-col sm="12">

              <h3>Acquista un voucher Salabam!</h3>

              <br/>

              <p>
                Per acquistare un voucher Salabam puoi utilizzare la tua carta di credito, il tuo welfare aziendale o Buoni sconto Salabam<span v-if="mixinUseTicketCompliments()">, Ticket Compliments Edenred</span><span v-if="mixinUsePromoshopping()">, Promoshopping</span>.
              </p>

              <br/>

              <p>
                Farlo è molto semplice:
              </p>

              <br/>
                <div class="checklist">
                    <ul>
                        <li>
                            <span class="checklist_index">1</span>
                            <div class="checklist_item">
                                <span>Cerca le disponibilità scegliendo la destinazione, la data e i partecipanti del tuo viaggio. Ti mostreremo che cosa potrai prenotare acquistando i diversi voucher disponibili</span>
                            </div>
                        </li>
                        <li>
                            <span class="checklist_index">2</span>
                            <div class="checklist_item">
                                <span>Scegli il voucher ed acquistalo</span>
                            </div>
                        </li>
                        <li>
                            <span class="checklist_index">3</span>
                            <div class="checklist_item">
                                <CopyScadenza default="Avrai comunque {value} mesi per effettuare la tua prenotazione e viaggiare scegliendo tra disponibilità aggiornate in tempo reale e a conferma immediata." />
                            </div>
                        </li>
                    </ul>
                </div>

            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12" md="12" align="center">

              <b-button variant="secondary">
                <router-link :to="{name: 'homepage'}">Vai alla ricerca</router-link>
              </b-button>

            </b-col>
          </b-row>

          <br/>

          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="regala-un-salabam">

          <h3>Regala un Salabam!</h3>

          <br/>

          <p>
            Salabam è un ottimo regalo per qualsiasi ricorrenza: si acquista e si invia con pochi click anche all'ultimo minuto. Per il destinatario del regalo, prenotare un viaggio, è semplicissimo.
          </p>

          <br/><br/>

          <b-row>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgPeople />
                <h6>Tu regali, l'altro sceglie</h6>
              </div>
              <p>
                Regalare un viaggio prima di Salabam era difficile perchè bisognava interpretare i gusti del destinatario del regalo.
              </p>
              <p>

                <CopyScadenza
                    default="Con Salabam sarà il destinatario del regalo a scegliere dove, quando e con chi viaggiare, con comodità, online, nell'arco di {value} mesi."
                    promoMonths="Con Salabam sarà il destinatario del regalo a scegliere dove, quando e con chi viaggiare, con comodità, online, nell'arco di {value} mesi. (anzichè dei tradizionali {defaultMonths}, in promozione fino al {promoValidUntil})"
                    promoFixed="Con Salabam sarà il destinatario del regalo a scegliere dove, quando e con chi viaggiare, con comodità, online, nell'arco di {defaultMonths} mesi. Promozione {promoTitle}: acquista oggi ed il tuo Salabam sarà valido fino al {value}"
                />

              </p>
            </b-col>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgLoader />
                <h6>Online e con pochi click</h6>
              </div>
              <p>
                Sia regalare un Salabam che usarlo per prenotare è facile ed efficace.
              </p>
              <p>
                Salabam, infatti, è un servizio online: le disponibilità che vedi sono reali e possono essere prenotate con pochi click.
              </p>
            </b-col>
          </b-row>

          <br/><br/>

          <b-row>
            <b-col sm="12" md="12">
              <div class="svgIcon">
                <SvgSmile />
                <h6>Cura del viaggiatore</h6>
              </div>
              <p>
                Puntiamo tutto su tecnologia e cura del cliente. Per questo abbiamo uno staff di assistenza interno all'azienda, che lavora con un approccio multicanale e soprattutto con attenzione e cura al viaggiatore.
              </p>
              <p>
                Abbiamo approntato meccanismi di checking delle prenotazioni che riducono al minimo i fisiologici tassi di overbooking e di errore in generale.
              </p>
              <p>
                Selezioniamo le strutture che proponiamo non solo in base alla qualità ma anche in base all'esperienza del gestore.
              </p>
            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12" md="12" align="center">

              <p><b>Comincia da qui</b></p>
              <b-button variant="secondary">
                <router-link :to="{name: 'home'}">Vai alla ricerca</router-link>
              </b-button>

            </b-col>
          </b-row>

          <br/><br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>


        <section id="promozione-in-corso" v-if="vv.isPromo">

          <h3 class="section-title">
            <CopyScadenza
                default="Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto"
                promoMonths="Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto"
                promoFixed="Acquisti adesso, prenoti entro il {value}"
            />              
          </h3>

          <br/>

          <p>
            <CopyScadenza  />.
          </p>

          <br/>

          <b-row>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgValidita />
                <h6>
                    <CopyScadenza
                        default="Validità {value} mesi"
                        promoMonths="Validità {value} mesi"
                        promoFixed="Validità fino al {value}"
                    />                    
                </h6>
              </div>
              <p>                
                <CopyScadenza
                    default="Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto"
                    promoMonths="Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto"
                    promoFixed="Acquisti adesso, prenoti entro il {value}"
                />.
              </p>
              <p>
                Ricorda che sia la prenotazione sia il viaggio dovranno essere effettuati entro la data indicata nel voucher.
              </p>
            </b-col>
            <b-col sm="12" md="6">
              <div class="svgIcon">
                <SvgRefund />
                <h6>Viaggia rimborsabile</h6>
              </div>
              <p>
                Su Salabam trovi una grande quantità di soggiorni di tipo rimborsabile.
              </p>
              <p>
                Prenota soggiorni RIMBORSABILI e potrai cancellare ogni prenotazione ottenendo la riattivazione del voucher per una nuova prenotazione.
              </p>
            </b-col>
          </b-row>

          <br/>

          <b-row>
            <b-col sm="12" md="12" align="center">

              <p>Scegli e acquista un voucher Salabam adesso e beneficia della promozione!</p>
              <b-button variant="secondary">
                <router-link :to="{name: 'shop'}" style="color:white!important;">Vai allo shop!</router-link>
              </b-button>

            </b-col>
          </b-row>

          <br/><br/>
          <TornaIndietro />

          <br/><br/><br/><br/>
        </section>


        <section id="prenota-un-viaggio">

          <h3>Usa un Salabam, prenota un viaggio!</h3>

          <br/>

          <p>
            Se sei in possesso di un voucher Salabam e vuoi utilizzarlo per prenotare un viaggio, vai alla pagina:
          </p>

          <router-link :to="{name: 'login-salabam'}">
            <b-button size="sm" variant="primary" class="white">{{'ho un salabam' | uppercase}}</b-button>
          </router-link>

          <br/><br/>
          <p>
              e fai login inserendo:
          </p>

          <br/>

          <Checklist :items="checklistItems_hoUnSalabam" :numbers="true" />

          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="richiedi-un-preventivo">

          <h3>Richiedi un preventivo</h3>

          <br/>

          <p>
            Inviaci qualche informazione sulle tue esigenze ed i tuoi desideri.
          </p>

          <p>
            Il nostro staff di assistenza ti invierà una lista di hotel corrispondenti alle tue richieste indicandoti quale voucher Salabam acquistare per prenotare uno degli hotel proposti.
          </p>

          <br/>

          <FormAssistenzaPreventivo
              :formTitle="'Inviaci la tua richiesta'"
              :label="'richiedi-un-preventivo'"
          />

          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="segnala-un-problema">

          <h3>Segnala un problema</h3>

          <br/>

          <FormSegnalazioneGenerica
              :formTitle="'Inviaci la tua richiesta'"
              :label="'assistenza-segnala-un-problema'"
          />

          <br/>
          <TornaIndietro />

        </section>

        <br/><br/><br/><br/>

        <section id="contatti-contattaci">

          <h3>Contattaci</h3>

          <br/>

          <b-row>
            <b-col md="6">
              <h5 class="contactTitle">Assistenza clienti</h5>
              <p>
                <SvgPhone /><a :href="'phone:'+(config.site.phoneNumber).replace(/\s/g, '')">{{config.site.phoneNumber}}</a>
              </p>
              <p>
                <SvgMail /><a :href="'mail:'+config.site.emailSupport">{{config.site.emailSupport}}</a>
              </p>
            </b-col>
            <b-col md="6">
              <h5 class="contactTitle">Salabam srl</h5>
              <p>
                <SvgPin /> {{config.site.address}}
              </p>
            </b-col>
          </b-row>
          
          <br />

          <b-row>
            <b-col md="6">
              <h5 class="contactTitle">Corporate</h5>
              <p>
                <SvgPhone /><a :href="'phone:'+(config.site.phoneNumber).replace(/\s/g, '')">{{config.site.phoneNumber}}</a>
              </p>
              <p>
                <SvgMail /><a :href="'mail:'+config.site.emailBusiness">{{config.site.emailBusiness}}</a>
              </p>
            </b-col>
          </b-row>

        </section>

        <br/>
        <TornaIndietro />


      </b-container>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import Checklist from '../../blocks/common/checklist'
import FormAssistenzaPreventivo from '@/components/blocks/common/form-assistenza-preventivo'
import FormSegnalazioneGenerica from '@/components/blocks/common/form-segnalazione-generica'
import SvgPeople from '@/components/svg/people'
import SvgLoader from '@/components/svg/loader'
import SvgSmile from '@/components/svg/smile'
import SvgMail from '@/components/svg/mail'
import SvgPhone from '@/components/svg/phone'
import SvgPin from '@/components/svg/pin'
import SvgDoubleCheck from '@/components/svg/double-check'
import SvgHeartLarge from '@/components/svg/heart-large'
import SvgRocket from '@/components/svg/rocket'
import Quote from '../../blocks/common/quote'
import SvgPersonCircle from '@/components/svg/personCircle'
import TornaIndietro from '@/components/blocks/common/torna-indietro'
import SearchBar from '@/components/blocks/search-bar'
import SvgValidita from '@/components/svg/validita'
import SvgRefund from '@/components/svg/refund'
import CopyScadenza from '@/components/blocks/common/copy-scadenza'

export default {
  name: 'assistenza',
  metaInfo: {
    title: 'La nostra assistenza, la tua sicurezza',
    meta: [
      { vmid: 'metaDescription', name: 'Description', content: 'Acquista un voucher e prenota in totale sicurezza grazie alla nostra assistenza via telefono, email, webchat e WhatsApp' },
      { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Acquista un voucher e prenota in totale sicurezza grazie alla nostra assistenza via telefono, email, webchat e WhatsApp' },
    ],
  },
  components: {
    Checklist,
    FormAssistenzaPreventivo,
    FormSegnalazioneGenerica,
    SvgPeople,
    SvgLoader,
    SvgSmile,
    SvgMail,
    SvgPhone,
    SvgPin,
    SvgDoubleCheck,
    SvgHeartLarge,
    SvgRocket,
    Quote,
    SvgPersonCircle,
    TornaIndietro,
    SearchBar,
    SvgValidita,
    SvgRefund,
    CopyScadenza,
  },
  computed:
  {
      occupancy: {
        get() { return this.$store.state.occupancy },
        set(value) { this.mixinSendMutation('setOccupancy',value) }
      },
      /*
      triggerSearch: {
        get() { return this.$store.state.triggerSearch },
        set(value) { this.mixinSendMutation('setTriggerSearch',value) }
      },*/
      dates: {
        get() { return this.$store.state.dates },
        set(value) { this.mixinSendMutation('setDates',value) }
      },
      geo: {
        get() { return this.$store.state.geo },
        set(value) { this.mixinSendMutation('setGeo',value) }
      },
      activeBand: {
        get() { return this.$store.state.activeBand },
        set(value) {
          this.mixinSendMutation('setActiveBand',value)
        }
      },
      lastUpdateDate: function(){

        // ritorna la data di giovedì scorso o lunedì scorso (quella più vicina)

        let dayManualEdit = new Date('2020-13-11') // verrà usata questa data o il luned'/giovedì più recente
        let today = new Date()
        let todayNumber = today.getDay()


        if(todayNumber >= 4 || todayNumber == 0) {
          // tra giovedì e domenica
          if(todayNumber == 0) todayNumber++;
          today.setDate(today.getDate() - Math.abs(todayNumber - 4)) // giovedì scorso
        }else{
          // tra lunedì e mercoledì
          today.setDate( today.getDate() - (todayNumber - 1) ) // lunedì scorso
        }

        if(dayManualEdit > today) today = dayManualEdit;

        return ('0' + today.getDate()).slice(-2) + "-"
            + ('0' + (today.getMonth()+1)).slice(-2)  + "-"
            + today.getFullYear()
      },
  }, 
  data(){
    return {
        /*
      checklistItems_acquistaWelfare: {
        0: "Accedi al tuo portale welfare e cerca il nostro servizio",
        1: "Una volta individuato Salabam troverai un link per accedere al nostro sito. Cliccando su questo link il sito Salabam riconoscerà il tuo credito e ti permetterà di acquistare il voucher in tempo reale e con un addebito diretto del costo sul tuo conto welfare",
        2: "Acquistato il voucher, lo riceverai per email dopo pochi istanti e potrai immediatamente prenotare il viaggio o farlo nell'arco di 12 mesi",
      },
      
      checklistItems_acquistaSalabam: {
        0: "Cerca le disponibilità scegliendo la destinazione, la data e i partecipanti del tuo viaggio. Ti mostreremo che cosa potrai prenotare acquistando i diversi voucher disponibili",
        1: "Scegli il voucher ed acquistalo",
        2: "Riceverai l'email con il link per andare a prenotare in pochi istanti e avrai comunque 12 mesi per utilizzare il voucher",
      },*/
      checklistItems_hoUnSalabam: {
        0: "Il codice indicato sull'email voucher Salabam",
        1: "L'indirizzo email indicato nell'email voucher Salabam",
      },
      /*
      checklistItems_covid19: {
        0: "Per i nuovi acquisti di voucher Salabam effettuati dall'1 ottobre 2020 al 31 gennaio 2021, abbiamo prolungato la validità dei voucher dai tradizionali 12 mesi ai 16 mesi attuali, permettendoti di viaggiare fino al 2022.",
        1: "Il nostro sito propone un numero sempre maggiore di soluzioni con Cancellazione gratuita. Alla luce delle incertezze causate dalla pandemia ti consigliamo caldamente di scegliere questo genere di soluzioni che ti permetteranno di cancellare una prenotazione gratuitamente con l'automatica ri-attivazione del voucher.",
        2: "Abbiamo raccolto e messo in evidenza nella pagina di ogni singola struttura le misure anti-Covid e i protocolli di sicurezza adottati dalla stessa.",
        //3: "Il nostro sito propone tante tariffe RIMBORSABILI. Significa che se per qualsiasi motivo, più o meno legato a Covid-19, dovessi avere bisogno di cancellare la prenotazione, il tuo voucher verrebbe automaticamente riattivato per una nuova prenotazione (con la stessa data di scadenza). Ti invitiamo quindi, quando possibile, a privilegiare la prenotazione di soluzioni di tipo RIMBORSABILE",
        //4: "Sulla pagina web di ogni struttura, cerchiamo di rendere chiare ed evidenti le misure anti-Covid adottate dalla stessa",
      },
      */
      config: Vue.prototype.$config,
      vv: Vue.prototype.$config.guiSettings.vouchersValidity,
    }
  },
  methods:
  {
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../../../scss/_custom.scss';
#info
{
  img
  {
    width:100%;
  }
  
  .router-link-active{
    color: white!important;
  }

  #contatti-contattaci
  {
    font-size: 0.95rem;
    .contactTitle
    {
      font-size: 1rem;
      margin-bottom:1.5rem;
    }
    a
    {
      color:$black;
      cursor:pointer;
      &:hover
      {
        color:$primary;
      }
    }
    svg
    {
      margin-right: 10px;
      width:20px;
      height:auto;
      *
      {
        fill:$primary;
      }
    }
  }
  .sectionTitle:first-letter {
    text-transform: uppercase;
  }
}
</style>