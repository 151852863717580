var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "200",
        height: "200",
        viewBox: "0 0 200 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M99.9999 0C44.8489 0 0 44.8491 0 100C0 155.151 44.8489 200 99.9999 200C155.151 200 200 155.151 200 100C200 44.8491 155.151 0 99.9999 0ZM99.9999 13.0435C148.102 13.0435 186.956 51.8983 186.956 100C186.956 148.102 148.102 186.957 99.9999 186.957C51.898 186.957 13.0435 148.102 13.0435 100C13.0435 51.8983 51.898 13.0435 99.9999 13.0435ZM63.0434 58.6956C55.8395 58.6956 50 64.5354 50 71.7391C50 78.9427 55.8395 84.7826 63.0434 84.7826C70.2471 84.7826 76.0869 78.9427 76.0869 71.7391C76.0869 64.5354 70.2471 58.6956 63.0434 58.6956ZM136.956 58.6956C129.753 58.6956 123.913 64.5354 123.913 71.7391C123.913 78.9427 129.753 84.7826 136.956 84.7826C144.16 84.7826 150 78.9427 150 71.7391C150 64.5354 144.16 58.6956 136.956 58.6956ZM51.4945 119.565C45.7389 119.824 44.113 125.745 46.7391 129.687C58.3969 147.126 77.9271 158.696 99.9999 158.696C122.073 158.696 141.603 147.126 153.261 129.687C155.164 126.842 154.34 122.555 151.494 120.652C148.649 118.749 144.294 119.573 142.391 122.419C132.976 136.503 117.54 145.652 99.9999 145.652C82.4602 145.652 67.0243 136.503 57.6087 122.419C55.843 120.347 53.59 119.443 51.4945 119.565Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }