var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", [
    _c("div", { staticClass: "text-center" }, [
      _vm.canUseEtc
        ? _c(
            "span",
            [
              _c("br"),
              _c("br"),
              _vm._v(
                "\n            Attendi, stiamo effettuando la connessione con Edenred...\n            "
              ),
              _c("br"),
              _c("br"),
              _c("Spinner"),
              _c("br"),
              _c("br"),
            ],
            1
          )
        : _c("span", [
            _c("br"),
            _c("br"),
            _vm._v(
              "\n            Ticket Compliments ® non disponibili.\n            "
            ),
            _c("br"),
            _c("br"),
            _c("br"),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }