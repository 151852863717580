var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "90",
        height: "67",
        viewBox: "0 0 90 67",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M25.7 25.1C25.7 31.2 38.3 32.1 38.3 47.1C38.3 58.1 30.4 66.3 19.4 66.3C7.7 66.2 0 55.7 0 44.6C0 25.4 18 0 29.2 0C32 0 37.1 2 37.1 5.4C37.1 8.8 25.7 14.7 25.7 25.1Z",
          fill: "#FBB03B",
        },
      }),
      _c("path", {
        attrs: {
          d: "M77.3996 25.1C77.3996 31.2 89.9996 32.1 89.9996 47.1C89.9996 58.1 82.0996 66.3 71.0996 66.3C59.2996 66.3 51.5996 55.8 51.5996 44.7C51.5996 25.5 69.5996 0.0999756 80.7996 0.0999756C83.5996 0.0999756 88.6996 2.09998 88.6996 5.49998C88.6996 8.89997 77.3996 14.7 77.3996 25.1Z",
          fill: "#FBB03B",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }