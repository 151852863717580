var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "250",
        height: "143",
        viewBox: "0 0 250 143",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M87.8952 74.2099C84.4741 68.9467 80.0004 64.473 74.7372 61.052C73.9477 60.5256 73.1583 60.5256 72.3688 61.052C65.7898 65.7888 57.6319 68.6836 48.9476 68.6836C40.2634 68.6836 32.1054 65.7888 25.5264 61.052C24.737 60.5256 23.9475 60.5256 23.158 61.052C7.63162 71.052 0 90.5258 0 113.157C0 122.631 37.3686 126.315 65.7898 124.21C67.1056 102.894 75.0004 85.2626 87.8952 74.2099Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.945 58.4213C65.0776 58.4213 78.1557 45.3433 78.1557 29.2107C78.1557 13.0781 65.0776 0 48.945 0C32.8124 0 19.7344 13.0781 19.7344 29.2107C19.7344 45.3433 32.8124 58.4213 48.945 58.4213Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M226.843 61.052C226.053 60.5256 225.264 60.5256 224.474 61.052C217.895 65.7888 209.737 68.6836 201.053 68.6836C192.369 68.6836 184.211 65.7888 177.632 61.052C176.842 60.5256 176.053 60.5256 175.263 61.052C170 64.473 165.79 68.9467 162.105 74.2099C175 85.2626 182.895 102.894 184.211 124.21C212.895 126.315 250.001 122.894 250.001 113.157C250.001 90.5258 242.369 71.052 226.843 61.052Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M201.054 58.4213C217.187 58.4213 230.265 45.3433 230.265 29.2107C230.265 13.0781 217.187 0 201.054 0C184.922 0 171.844 13.0781 171.844 29.2107C171.844 45.3433 184.922 58.4213 201.054 58.4213Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M150.79 78.4216C150 77.8953 149.211 77.8953 148.422 78.4216C141.843 83.1585 133.685 86.0532 125 86.0532C116.316 86.0532 108.158 83.1585 101.579 78.4216C100.79 77.8953 100 77.8953 99.2107 78.4216C83.6844 88.4216 76.0527 107.895 76.0527 130.527C76.0527 146.053 174.211 146.053 174.211 130.527C173.948 108.159 166.316 88.4216 150.79 78.4216Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M125.004 75.7905C141.136 75.7905 154.214 62.7124 154.214 46.5798C154.214 30.4472 141.136 17.3691 125.004 17.3691C108.871 17.3691 95.793 30.4472 95.793 46.5798C95.793 62.7124 108.871 75.7905 125.004 75.7905Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }