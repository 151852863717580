var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "150",
        height: "150",
        viewBox: "0 0 150 150",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "75",
          cy: "75",
          r: "74",
          fill: "white",
          stroke: "#F2F2F2",
          "stroke-width": "2",
        },
      }),
      _c("path", {
        attrs: {
          d: "M76.4599 37.4311C98.7908 38.0533 116.582 56.3609 116.582 78.7076C116.582 101.512 98.0938 120 75.2881 120C55.9208 120 39.0809 106.473 34.9477 87.543C33.8892 82.6368 41.3475 81.0275 42.4061 85.9325C45.7788 101.378 59.4908 112.364 75.2881 112.364C93.8767 112.364 108.944 97.2952 108.944 78.7076C108.944 60.5791 94.5715 45.693 76.4599 45.072V51.5477C76.4599 54.0195 73.6878 55.4293 71.6932 54.0784C66.4188 51.0343 61.1466 47.9823 55.871 44.936C53.8049 43.7494 53.8684 40.7812 55.8993 39.6456C61.2304 36.5676 66.548 33.4917 71.8779 30.4148C73.9281 29.2271 76.4565 30.74 76.4531 33.0509L76.4599 37.4311ZM61.4446 85.6583L67.9125 85.0168C68.648 89.0979 70.8081 91.2988 75.0592 91.2988C77.8539 91.2988 81.4862 90.1666 81.4862 86.7825C81.4862 83.259 75.8094 82.6096 73.1484 81.9421C67.9068 80.6309 62.6845 78.3291 62.6845 72.0528C62.6845 66.4463 67.0749 63.7728 71.8881 63.0633V58.9086H77.6941V63.0758C82.9323 63.8329 86.9567 66.7206 87.1743 72.9096L80.5251 73.1374C79.9324 69.5935 78.0409 68.3684 74.5469 68.3684C72.5239 68.3684 69.0118 68.9736 69.0118 71.6097C69.0118 74.4475 74.021 75.3247 76.0746 75.8131C82.4518 77.3125 88.1365 79.204 88.1365 86.744C88.1365 90.714 85.7985 94.1717 82.1356 95.6949C80.8606 96.2185 79.3816 96.5812 77.6941 96.7614V100.85H71.8881V96.6888C65.9892 95.8547 62.2527 92.4321 61.4446 85.6583ZM37.2098 70.5126C39.8584 70.1658 42.2871 72.0313 42.6327 74.6798C42.9784 77.3284 41.1129 79.7548 38.4655 80.1027C35.817 80.4495 33.3894 78.5841 33.0415 75.9344C32.6947 73.287 34.5612 70.8594 37.2098 70.5126ZM42.3358 57.8354C44.9413 58.4156 46.5869 60.9996 46.0055 63.6062C45.4252 66.214 42.8424 67.8561 40.2335 67.277C37.628 66.6979 35.9847 64.1139 36.5649 61.5062C37.1429 58.8984 39.7292 57.2551 42.3358 57.8354ZM51.4873 47.6752C53.7381 49.1122 54.3988 52.1019 52.9629 54.3549C51.5247 56.6057 48.5362 57.2664 46.2843 55.8294C44.0335 54.3935 43.3728 51.4027 44.8087 49.1508C46.2446 46.9 49.2354 46.2393 51.4873 47.6752Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }