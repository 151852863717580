var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage landingPage landingBackToItaly" },
    [
      _c(
        "div",
        {
          style:
            "background-image:url(" +
            require("../../../assets/landing/hero-landing-default.jpg") +
            ")",
          attrs: { id: "hero" },
        },
        [
          _c(
            "div",
            { staticClass: "heroFullRow" },
            [
              _c("CopyScadenza", {
                attrs: {
                  default:
                    'Ordina ora e hai <span class="stellaMesi"><svg width="133" height="133" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0370029 66.185C0.0364893 67.0984 0.490444 67.8596 1.09603 68.3163L16.6903 79.4296L8.65299 96.9368C8.34964 97.698 8.34913 98.6114 8.80308 99.3726C9.25704 100.134 10.0141 100.591 10.9228 100.743L30.0059 102.417L31.6612 121.599C31.8121 122.513 32.2661 123.274 33.0232 123.731C33.7802 124.187 34.689 124.187 35.4464 123.883L52.869 115.814L63.9168 131.495C64.3708 132.256 65.2794 132.56 66.0367 132.56C66.9455 132.56 67.703 132.104 68.1578 131.495L79.2232 115.814L96.6367 123.883C97.3938 124.187 98.3026 124.187 99.0602 123.731C99.8177 123.274 100.273 122.513 100.425 121.599L102.101 102.417L121.186 100.743C122.095 100.591 122.853 100.134 123.308 99.3726C123.762 98.6114 123.763 97.698 123.46 96.9368L115.443 79.4296L131.05 68.3163C131.807 67.8596 132.111 66.9462 132.111 66.185C132.111 65.4238 131.658 64.5104 131.052 64.0537L115.458 52.9404L123.495 35.4331C123.798 34.6719 123.799 33.7585 123.345 32.9973C122.891 32.2362 122.134 31.7794 121.225 31.6272L102.142 29.9526L100.487 10.7708C100.336 9.85734 99.8819 9.09615 99.1249 8.63944C98.3678 8.18273 97.4591 8.18273 96.7016 8.48721L79.279 16.5558L68.0796 1.0276C67.0202 -0.342533 64.7483 -0.342533 63.8387 1.0276L52.7733 16.708L35.3598 8.63944C34.6026 8.33497 33.6939 8.33497 32.9363 8.79168C32.1788 9.24839 31.7239 10.0096 31.572 10.923L29.8951 30.1048L10.8101 31.7794C9.90123 31.9317 9.14367 32.3884 8.68886 33.1496C8.23405 33.9108 8.23353 34.8242 8.53603 35.5854L16.5536 53.0926L1.09843 64.0537C0.340866 64.5104 0.0375165 65.2716 0.0370029 66.185Z" fill="#292E44"/></svg><span class="value">{value}</span></span> mesi per viaggiare',
                  promoMonths:
                    'Ordina ora e hai <span class="stellaMesi"><svg width="133" height="133" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.0370029 66.185C0.0364893 67.0984 0.490444 67.8596 1.09603 68.3163L16.6903 79.4296L8.65299 96.9368C8.34964 97.698 8.34913 98.6114 8.80308 99.3726C9.25704 100.134 10.0141 100.591 10.9228 100.743L30.0059 102.417L31.6612 121.599C31.8121 122.513 32.2661 123.274 33.0232 123.731C33.7802 124.187 34.689 124.187 35.4464 123.883L52.869 115.814L63.9168 131.495C64.3708 132.256 65.2794 132.56 66.0367 132.56C66.9455 132.56 67.703 132.104 68.1578 131.495L79.2232 115.814L96.6367 123.883C97.3938 124.187 98.3026 124.187 99.0602 123.731C99.8177 123.274 100.273 122.513 100.425 121.599L102.101 102.417L121.186 100.743C122.095 100.591 122.853 100.134 123.308 99.3726C123.762 98.6114 123.763 97.698 123.46 96.9368L115.443 79.4296L131.05 68.3163C131.807 67.8596 132.111 66.9462 132.111 66.185C132.111 65.4238 131.658 64.5104 131.052 64.0537L115.458 52.9404L123.495 35.4331C123.798 34.6719 123.799 33.7585 123.345 32.9973C122.891 32.2362 122.134 31.7794 121.225 31.6272L102.142 29.9526L100.487 10.7708C100.336 9.85734 99.8819 9.09615 99.1249 8.63944C98.3678 8.18273 97.4591 8.18273 96.7016 8.48721L79.279 16.5558L68.0796 1.0276C67.0202 -0.342533 64.7483 -0.342533 63.8387 1.0276L52.7733 16.708L35.3598 8.63944C34.6026 8.33497 33.6939 8.33497 32.9363 8.79168C32.1788 9.24839 31.7239 10.0096 31.572 10.923L29.8951 30.1048L10.8101 31.7794C9.90123 31.9317 9.14367 32.3884 8.68886 33.1496C8.23405 33.9108 8.23353 34.8242 8.53603 35.5854L16.5536 53.0926L1.09843 64.0537C0.340866 64.5104 0.0375165 65.2716 0.0370029 66.185Z" fill="#292E44"/></svg><span class="value">{value}</span></span> mesi per viaggiare',
                  promoFixed: "Ordina ora e viaggia entro il {value}",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("b-container", { staticClass: "pageContent" }, [
        _c("section", [
          _c("div", { staticClass: "boxed" }, [
            _c(
              "p",
              [
                _c("CopyScadenza", {
                  attrs: {
                    promoMonths:
                      "Oggi puoi acquistare i voucher Salabam in totale tranquillità, perchè prolunghiamo la validità a {value} mesi.",
                    promoFixed:
                      "Oggi puoi acquistare i voucher Salabam in totale tranquillità, perchè prolunghiamo la validità fino al {value}",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("section", [
          _c("div", { staticClass: "infoBox noBorder" }, [
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "boxed" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "infoIcon" },
                          [_c("SvgCheckedContractCircle")],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "infoHighlight" },
                          [
                            _c("CopyScadenza", {
                              attrs: {
                                default: "{value} mesi per viaggiare",
                                promoMonths: "{value} mesi per viaggiare",
                                promoFixed: "Validità fino al {value}",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "infoContent" }, [
                          _c("p", [_c("CopyScadenza")], 1),
                          _c("p", [
                            _vm._v(
                              "\n                                        Ricordati che sia la prenotazione sia il viaggio dovranno essere fatti entro la data indicata nel voucher.\n                                    "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "infoIcon" },
                          [_c("SvgRefundCircle")],
                          1
                        ),
                        _c("div", { staticClass: "infoHighlight" }, [
                          _vm._v(
                            "\n                                    Viaggia rimborsabile!\n                                "
                          ),
                        ]),
                        _c("div", { staticClass: "infoContent" }, [
                          _c("p", [
                            _vm._v(
                              "\n                                        Su Salabam da diverse settimane trovi una grande quantità di soggiorni di tipo rimborsabile.\n                                    "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "\n                                        Prenota soggiorni RIMBORSABILI e potrai cancellare ogni prenotazione ottenendo la riattivazione del voucher per un nuovo booking\n                                    "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("section", [
          _c(
            "div",
            { staticClass: "boxed text-center" },
            [
              _c("p", [
                _vm._v(
                  "\n                    Scegli e acquista un voucher Salabam adesso e beneficia della promozione!\n                "
                ),
              ]),
              _c("br"),
              _c(
                "b-button",
                { attrs: { variant: "secondary" } },
                [
                  _c(
                    "router-link",
                    { staticClass: "white", attrs: { to: { name: "home" } } },
                    [_vm._v("INIZIA!")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }