<template>
    <div :class="'blog-category ' + categorySlug">
        <BlogTopbar
            :categorySlug="categorySlug"
            :goto="'blog'"
        />
        <b-container fluid class="content">
            <b-row>
                <b-col sm="12" md="3">

                    <!--<div class="search-posts">
                        <Lens class="search-icon" />
                        <b-form-input size="md" v-model="searchString" type="text" />
                        <b-button :disabled="(searchString.length < 4) || (loadingPosts == true)" @click="searchByString()">Cerca</b-button>
                    </div>-->

                    <Spinner v-if="loadingTags" />
                    <div v-else class="tags">
                        <p class="tags-title">TAGS</p>
                        <div class="tags-list">
                            <div v-for="(tag,index) in tags" v-bind:key="index" :class="'tag ' + (currentTagId == tag.id ? 'active' : '')" @click="searchByTag(tag.id)">#{{tag.name}}</div>
                        </div>
                    </div>
                </b-col>
                <b-col sm="12" md="9" class="postPads">
                    <Spinner v-if="loadingPosts" />
                    <div v-else>
                        <b-row v-if="posts.length <= 12">
                            <b-col v-for="(post,index) in posts" :key="'post_'+index" class="postPad" sm="12" md="6" lg="4">
                                <router-link :to="post.url">
                                    <div
                                        class="postPad__thumbnail"
                                        :style="'background-image:url('+post.featuredMedia.src+');'"
                                    >
                                    </div>
                                </router-link>
                                <div class="postPad__content">
                                    <div>
                                        <div class="postPad__title">{{post.title}}</div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col v-for="(post,index) in posts.slice(0, 12)" :key="'post_'+index" class="postPad" sm="12" md="6" lg="4">
                                <router-link :to="post.url">
                                    <div
                                        class="postPad__thumbnail"
                                        :style="'background-image:url('+post.featuredMedia.src+');'"
                                    >
                                    </div>
                                </router-link>
                                <div class="postPad__content">
                                    <div>
                                        <div class="postPad__title">{{post.title}}</div>
                                    </div>
                                </div>
                            </b-col>
                            <b-col sm="12" class="load-more" v-if="!showMore" @click="showMore = true"><SvgChevronDown /> <span>Vedi altri</span></b-col>
                            <b-col v-for="(post,index) in posts.slice(12, posts.length)" :key="'post_'+index" :class="'postPad ' + (showMore ? '' : 'hidden')" sm="12" md="6" lg="4">
                                <router-link :to="post.url">
                                    <div
                                        class="postPad__thumbnail"
                                        :style="'background-image:url('+post.featuredMedia.src+');'"
                                    >
                                    </div>
                                </router-link>
                                <div class="postPad__content">
                                    <div>
                                        <div class="postPad__title">{{post.title}}</div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>


<script>
    import Vue from 'vue'
    import Api from '../../../api'
    import Spinner from '../../atoms/spinner'
    //import Lens from '../../svg/lens'
    import SvgChevronDown from '../../svg/chevron-down'
    import BlogTopbar from './blog-topbar.vue'

    export default {
        name: 'blog-home-category',
        metaInfo() {
          return {
            title: this.metaTitle,
            meta: [
              { vmid: 'metaTitle', name: 'Title', content: this.metaTitle },
              { vmid: 'metaDescription', name: 'Description', content: this.metaDescription },
            ]
          }
        }, // metaInfo
        components: {
            Spinner,
            //Lens,
            SvgChevronDown,
            BlogTopbar,
        },
        props:
        {
            categorySlug: String,
            metaTitle: {
              type: String,
              default: '',
            },
            metaDescription: {
              type: String,
              default: '',
            }
        },
        computed:
        {
        }, 
        data(){
            return {
                config: Vue.prototype.$config,
                category: -1,
                title: '',
                subtitle: '',
                searchString: '',
                loadingPosts: true,
                loadingTags: true,
                posts: Array(),
                tags: Array(),
                currentTagId: -1,
                showMore: false,
            }
        },
        methods:
        {
            getTags()
            {
                let _this = this
                    _this.loadingTags = true

                Api.getTags({
                    category: _this.category,
                })
                .then((results) => {
                    _this.tags = results.tags
                    _this.loadingTags = false
                })
            },
            getPosts()
            {
                let _this = this
                    _this.loadingPosts = true

                Api.getPosts({
                    category: _this.category,
                })
                .then((results) => {
                    _this.posts = results.posts
                    _this.loadingPosts = false
                })
            },
            searchByTag(tagId)
            {
                if(this.loadingPosts) return // ricerca o caricamento in corso
                
                if(this.currentTagId == tagId) // tolgo la selezione sul tag e ricarico tutti i post
                {
                    this.currentTagId = -1
                    this.getPosts()
                    return
                }

                let _this = this
                    _this.loadingPosts = true
                    _this.currentTagId = tagId
                    _this.searchString = ''

                Api.getPosts({
                    tag: _this.currentTagId,
                    category: _this.category,
                })
                .then((results) => {
                    _this.posts = results.posts
                    _this.loadingPosts = false
                })
            },
            searchByString()
            {
                if(this.loadingPosts) return // ricerca o caricamento in corso

                let _this = this
                    _this.loadingPosts = true
                    _this.currentTagId = -1

                Api.getPosts({
                    search: _this.searchString,
                    category: _this.category,
                })
                .then((results) => {
                    _this.posts = results.posts
                    _this.loadingPosts = false
                })
            }
        },
        mounted()
        {
            switch (this.categorySlug) {
                case 'business':
                    this.category = this.config.posts.categoryBusiness
                    break;
            
                default:
                    this.category = this.config.posts.category
                    break;
            }

            this.getPosts()
            this.getTags()
        }
    }
</script>


<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .blog-category
    {
        .container-fluid
        {
            @include media-breakpoint-up(md)
            {
                padding-left:$grid-gutter-width;
                padding-right:$grid-gutter-width;
            }
        }

        .topbar
        {
            margin-bottom:$grid-gutter-width/2;
            @include media-breakpoint-up(md)
            {
                margin-bottom:$grid-gutter-width;
            }
        }

        .content
        {
            margin-bottom:$grid-gutter-width;
            @include media-breakpoint-up(md)
            {
                margin-bottom:$grid-gutter-width*2;
            }

            .postPad
            {
                margin-bottom:$grid-gutter-width/2;
                @include media-breakpoint-up(md)
                {
                    margin-bottom:$grid-gutter-width;
                }
            }

            .tags-title
            {
                font-size: 1.5rem;
                display: block;
                margin-bottom: 2rem;
                font-weight: bold;
            }

            .tag
            {
                border:1px solid $primary;
                border-radius: 20px;
                padding:2px 18px;
                font-weight: 400;
                font-size: 18px;
                color:$primary;
                display:inline-block;
                margin:0 1.25rem 0.75rem 0;
                cursor:pointer;
                transition:$transition-base;
                background-color: transparent;
                &:hover,
                &.active
                {
                    color:$white;
                    background-color: $primary;
                }
            }

            .search-posts
            {
                display:flex;
                margin-bottom:2rem;
                position:relative;
                input,button
                {
                    border-radius: 1.5rem;
                }
                input
                {
                    box-shadow: none;
                    background:$light-gray;
                    margin-right:1rem;
                    padding-left:4rem;
                    //border:1px solid $medium-gray;
                }
                .search-icon
                {
                    position:absolute;
                    top:50%;
                    left:20px;
                    transform:translateY(-50%);
                    width:36px;
                    height:auto;
                    padding-right:14px;
                    path
                    {
                        fill:$black;
                    }
                    border-right:1px solid $medium-gray;
                }
            }
        }

        .hidden
        {
            display:none;
        }

        .load-more
        {
            min-width: 100%;
            cursor: pointer;
            margin-bottom: $grid-gutter-width;
            color:$blue;
            text-align:right;
            font-weight: bold;
            padding-right:$grid-gutter-width;
            span
            {
                text-decoration:underline;
            }
            svg
            {
                width: 14px;
                height: auto;
                margin-right: 6px;                
                path
                {
                    stroke:$blue;
                }
            }
        }

        &.consumer
        {
            .tag
            {
                color:$primary;
                border-color:$primary;
                &:hover,
                &.active
                {
                    color:$white;
                    background-color: $primary;
                }
            }
        }

        &.business
        {
            .tag
            {
                color:$dark-blue;
                border-color:$dark-blue;
                &:hover,
                &.active
                {
                    color:$white;
                    background-color: $dark-blue;
                }
            }
        }
    }
</style>