var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "newsletterBox",
      attrs: { id: "iscrizione-newsletter-business" },
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("Spinner", { attrs: { msg: "Solo un istante..." } })],
            1
          )
        : _vm.apiResponse !== ""
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("b-alert", { attrs: { show: "", variant: "success" } }, [
                _vm._v("\n      " + _vm._s(_vm.apiResponse) + "\n    "),
              ]),
            ],
            1
          )
        : _c("div", [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "customCheckboxBlock" },
              [
                _c("b-form-checkbox", {
                  ref: "privacy",
                  attrs: {
                    required: "",
                    type: "checkbox",
                    name: "privacy",
                    id: "privacy",
                  },
                  model: {
                    value: _vm.privacy,
                    callback: function ($$v) {
                      _vm.privacy = $$v
                    },
                    expression: "privacy",
                  },
                }),
                _c("label", { attrs: { for: "privacy" } }, [
                  _vm._v(
                    "\n        Inviando questo modulo, confermo di aver letto e accettato l'"
                  ),
                  _c("span", { staticClass: "secondary" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.privacyPolicyUrl, target: "_blank" },
                      },
                      [_vm._v("Informativa sulla Privacy")]
                    ),
                  ]),
                  _vm._v(".\n      "),
                ]),
              ],
              1
            ),
            _c("br"),
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                { staticClass: "customInputBlock emailBlock" },
                [
                  _c("b-form-input", {
                    ref: "email",
                    attrs: {
                      required: true,
                      type: "email",
                      placeholder: "Inserisci la tua email",
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c("span", { staticClass: "formError" }),
                  _c("span", {
                    staticClass: "formClear",
                    on: {
                      click: function ($event) {
                        return _vm.clearField("email")
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "loginBlockLogin" }, [
                _c(
                  "div",
                  { staticClass: "loginButton" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { disabled: !_vm.validateFields() },
                        on: { click: _vm.submitForm },
                      },
                      [_vm._v("ISCRIVITI")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "newsletterTitle" }, [
      _vm._v(
        "\n      RESTA AGGIORNATO SULLE NOSTRE SOLUZIONI BUSINESS\n      "
      ),
      _c("br"),
      _c("span", { staticClass: "small" }, [
        _vm._v(
          "Applicazioni di travel booking per il marketing, soluzioni e casi di successo."
        ),
      ]),
      _c("br"),
      _c("span", { staticClass: "small text-center" }, [_vm._v("No-spam!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }