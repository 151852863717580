var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "doublecheck" },
    [
      _vm.loading
        ? _c("Spinner")
        : _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10", "offset-lg": "1" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-tabs",
                                { attrs: { card: "" } },
                                [
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: { active: _vm.info.lang == "en" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    id: "flag-icon-css-gb",
                                                    viewBox: "0 0 640 480",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "#012169",
                                                      d: "M0 0h640v480H0z",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "#FFF",
                                                      d: "M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "#C8102E",
                                                      d: "M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "#FFF",
                                                      d: "M241 0v480h160V0H241zM0 160v160h640V160H0z",
                                                    },
                                                  }),
                                                  _c("path", {
                                                    attrs: {
                                                      fill: "#C8102E",
                                                      d: "M0 193v96h640v-96H0zM273 0v480h96V0h-96z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "b-card-text",
                                        [
                                          _c("DoublecheckCard", {
                                            attrs: {
                                              lang: "en",
                                              info: _vm.info,
                                              strings: _vm.strings,
                                              suggestionSent:
                                                _vm.suggestionSent,
                                            },
                                            on: {
                                              confirm: _vm.confirm,
                                              submitSuggestion:
                                                _vm.submitSuggestion,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: "IT",
                                        active: _vm.info.lang == "it",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    id: "flag-icon-css-it",
                                                    viewBox: "0 0 640 480",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "g",
                                                    {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        "stroke-width": "1pt",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          fill: "#fff",
                                                          d: "M0 0h640v480H0z",
                                                        },
                                                      }),
                                                      _c("path", {
                                                        attrs: {
                                                          fill: "#009246",
                                                          d: "M0 0h213.3v480H0z",
                                                        },
                                                      }),
                                                      _c("path", {
                                                        attrs: {
                                                          fill: "#ce2b37",
                                                          d: "M426.7 0H640v480H426.7z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "b-card-text",
                                        [
                                          _c("DoublecheckCard", {
                                            attrs: {
                                              lang: "it",
                                              info: _vm.info,
                                              strings: _vm.strings,
                                              suggestionSent:
                                                _vm.suggestionSent,
                                            },
                                            on: {
                                              confirm: _vm.confirm,
                                              submitSuggestion:
                                                _vm.submitSuggestion,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }