var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage faq", attrs: { id: "page" } },
    [
      _c("b-container", [
        _c(
          "section",
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12", lg: "6", "offset-lg": "3" } },
                  [
                    _c("h1", { staticClass: "text-center pageTitle" }, [
                      _vm._v("Domande frequenti"),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  { attrs: { sm: "12", lg: "3" } },
                  [_c("TornaIndietro")],
                  1
                ),
              ],
              1
            ),
            _vm.loading
              ? _c("Spinner")
              : _vm._l(_vm.faqs, function (faq, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("br"),
                      _c("br"),
                      _c("h3", { staticClass: "text-center pageTitle" }, [
                        _vm._v(_vm._s(faq.category)),
                      ]),
                      _c("Accordion", {
                        attrs: {
                          items: faq.items,
                          accordionId: faq.catcode,
                          showTornaIndietro: true,
                        },
                      }),
                    ],
                    1
                  )
                }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }