var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "190",
        height: "200",
        viewBox: "0 0 190 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M99.9994 52.6316C114.533 52.6316 126.315 40.8496 126.315 26.3158C126.315 11.782 114.533 0 99.9994 0C85.4656 0 73.6836 11.782 73.6836 26.3158C73.6836 40.8496 85.4656 52.6316 99.9994 52.6316Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M100 200C108.721 200 115.79 192.931 115.79 184.21C115.79 175.49 108.721 168.421 100 168.421C91.2801 168.421 84.2109 175.49 84.2109 184.21C84.2109 192.931 91.2801 200 100 200Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M155.822 57.3344C160.183 57.3344 163.717 53.7998 163.717 49.4397C163.717 45.0795 160.183 41.5449 155.822 41.5449C151.462 41.5449 147.928 45.0795 147.928 49.4397C147.928 53.7998 151.462 57.3344 155.822 57.3344Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.1769 179.508C54.3506 179.508 62.598 171.26 62.598 161.087C62.598 150.913 54.3506 142.666 44.1769 142.666C34.0032 142.666 25.7559 150.913 25.7559 161.087C25.7559 171.26 34.0032 179.508 44.1769 179.508Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M178.946 115.789C184.76 115.789 189.473 111.077 189.473 105.263C189.473 99.4496 184.76 94.7368 178.946 94.7368C173.133 94.7368 168.42 99.4496 168.42 105.263C168.42 111.077 173.133 115.789 178.946 115.789Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.0526 126.316C32.6797 126.316 42.1053 116.89 42.1053 105.263C42.1053 93.636 32.6797 84.2104 21.0526 84.2104C9.42558 84.2104 0 93.636 0 105.263C0 116.89 9.42558 126.316 21.0526 126.316Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M155.824 174.244C163.091 174.244 168.982 168.354 168.982 161.087C168.982 153.82 163.091 147.929 155.824 147.929C148.557 147.929 142.666 153.82 142.666 161.087C142.666 168.354 148.557 174.244 155.824 174.244Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.1764 73.1238C57.2568 73.1238 67.8606 62.52 67.8606 49.4396C67.8606 36.3592 57.2568 25.7554 44.1764 25.7554C31.096 25.7554 20.4922 36.3592 20.4922 49.4396C20.4922 62.52 31.096 73.1238 44.1764 73.1238Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }