var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "page-error" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { lg: "8", "offset-lg": "2" } }, [
                _c("h4", { staticClass: "secondary" }, [
                  _vm._v("C'è stato un problema"),
                ]),
                _c("br"),
                _c("p", { domProps: { innerHTML: _vm._s(_vm.error) } }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }