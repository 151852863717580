var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { staticClass: "text-center" }, [
    _c("br"),
    _c("br"),
    _vm.orderToken == "" || !_vm.loading
      ? _c("div", [
          _c("span", [_vm._v("\n          Richiesta annullata\n      ")]),
        ])
      : _c("div", [
          _vm.loading
            ? _c(
                "div",
                [
                  _c("span", [
                    _vm._v(
                      "\n          Attendi, stiamo annullando la richiesta...\n        "
                    ),
                  ]),
                  _c("Spinner"),
                ],
                1
              )
            : _vm._e(),
        ]),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }