<template>
    <div id="header-small">
        <img :src="backgroundImage" />
        <ScrollDown v-if="scrollTo !== ''" :targetId="scrollTo" />
    </div>
</template>
<script>
import ScrollDown from '../atoms/scroll-down.vue'
export default {
    name: 'header-small',
    components:
    {
        ScrollDown,
    },
    props:
    {
        backgroundImage: {
            type: String,
            default: '/assets/images/blog/hero-blog.jpg'
        },
        scrollTo: {
            type: String,
            default: ''
        },
    },
    data()
    {
        return {
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    #header-small
    {
        position:relative;
        .scrollDown
        {
            position:absolute;
            left:0;
            right:0;
            margin:0 auto;
            bottom:0;
            transform:translateY(50%);
        }
    }
</style>