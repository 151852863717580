var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "30",
        height: "25",
        viewBox: "0 0 30 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M28.3015 0C27.8781 0.0210627 27.4792 0.204027 27.187 0.511239C22.9069 4.99078 13.2498 15.3409 8.39692 20.4417L2.70026 15.4149C2.37782 15.1294 1.95503 14.9839 1.52516 15.0101C1.09529 15.0362 0.693599 15.2319 0.408115 15.5544C0.12263 15.8768 -0.0231387 16.2993 0.00299378 16.7292C0.0291263 17.159 0.224875 17.5611 0.547316 17.8465L7.43531 23.924C7.75723 24.189 8.16609 24.3252 8.58263 24.3063C8.99917 24.2874 9.39401 24.115 9.69063 23.8219C14.2136 19.0892 25.062 7.43785 29.5415 2.75376C29.7681 2.5205 29.9196 2.22449 29.9757 1.90419C30.0318 1.5839 29.9902 1.25396 29.8565 0.957558C29.7227 0.661153 29.503 0.411757 29.2257 0.241886C28.9484 0.072016 28.6263 -0.0104976 28.3015 0.00499378",
          fill: "#FBB03B",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }