<template>
  <div id="icons">

    <br/><br/>

    SvgPacco
    <SvgPacco />
    <hr>

    SvgTicket
    <SvgTicket />
    <hr>

    SvgLoader
    <SvgLoader />
    <hr>

    SvgHeartOrangeLarge
    <SvgHeartOrangeLarge />
    <hr>

    SvgChartArrow
    <SvgChartArrow />
    <hr>

    SvgLamp
    <SvgLamp />
    <hr>

    SvgPalette
    <SvgPalette />
    <hr>

    SvgBrush
    <SvgBrush />
    <hr>

    SvgSmile
    <SvgSmile />
    <hr>

    SvgCursors
    <SvgCursors />
    <hr>

    SvgPeople
    <SvgPeople />
    <hr>

    SvgWa
    <SvgWa />
    <hr>

    SvgCup
    <SvgCup />
    <hr>

    SvgRocket
    <SvgRocket />
    <hr>

    SvgLogoOrange
    <SvgLogoOrange />
    <hr>

    SvgLogo
    <div style="background-color:gray">
      <SvgLogo />
    </div>
    <hr>


    SvgValidita
    <div>
      <SvgValidita />
    </div>
    <hr>


    SvgRefund
    <div>
      <SvgRefund />
    </div>
    <hr>

    SvgBranding
    <div>
      <SvgBranding />
    </div>
    <hr>

    SvgIntegrazione
    <div>
      <SvgIntegrazione />
    </div>
    <hr>

    SvgDiamond
    <div>
      <SvgDiamond />
    </div>
    <hr>



  </div>
</template>

<script>

import SvgPacco from '@/components/svg/pacco'
import SvgTicket from '@/components/svg/ticket'
import SvgLoader from '@/components/svg/loader'
import SvgHeartOrangeLarge from '@/components/svg/heart-orange-large'
import SvgChartArrow from '@/components/svg/chart-arrow'
import SvgLamp from '@/components/svg/lamp'
import SvgPalette from '@/components/svg/palette'
import SvgBrush from '@/components/svg/brush'
import SvgSmile from '@/components/svg/smile'
import SvgCursors from '@/components/svg/cursors'
import SvgPeople from '@/components/svg/people'
import SvgWa from '@/components/svg/whatsapp-orange-large'
import SvgCup from '@/components/svg/cup'
import SvgRocket from '@/components/svg/rocket'

import SvgLogoOrange from '@/components/svg/logo-orange'
import SvgLogo from '@/components/svg/logo'

import SvgValidita from '@/components/svg/validita'
import SvgRefund from '@/components/svg/refund'

import SvgBranding from '@/components/svg/branding'
import SvgIntegrazione from '@/components/svg/integrazione'
import SvgDiamond from '@/components/svg/diamond'

export default{
  name: 'icons',
  components: {
    SvgPacco,
    SvgTicket,
    SvgLoader,
    SvgHeartOrangeLarge,
    SvgChartArrow,
    SvgLamp,
    SvgPalette,
    SvgBrush,
    SvgSmile,
    SvgCursors,
    SvgPeople,
    SvgWa,
    SvgCup,
    SvgRocket,

    SvgLogoOrange,
    SvgLogo,

    SvgValidita,
    SvgRefund,

    SvgBranding,
    SvgIntegrazione,

    SvgDiamond,

  }
}
</script>