<template>
    <b-container class="text-center">

        <br><br>
        <div v-if="orderToken == '' || orderToken == undefined">
            C'è stato un problema, token non trovato!
        </div>
        
        <div v-else>

            <span v-if="loading && this.$router.currentRoute.query.status =='SUCCESS'">
              Attendi, stiamo processando il tuo ordine...
            </span>

            <span v-else>
              Siamo spiacenti, si è verificato un problema.
              Contatta l'assistenza indicando il seguente numero ordine Scalapay: <b>{{orderToken}}</b>
            </span>

            <br><br>
            <Spinner v-if="loading" />
        </div>
        <br><br>
    </b-container>
</template>

<script>
//import Router from 'vue-router'
import Spinner from '../../atoms/spinner'
import Api from '../../../api'
import Store from "@/vuex/store";
import Vue from "vue";
export default {
    name: 'scalapay-confirm',
    components:
    {
        Spinner,
    },
    props:
    {
    },
    data()
    {
        return {
            orderToken: '',
            status: '',
            loading: true,
            errors: true,
        }
    },
    mounted()
    {
        let _this = this

        _this.orderToken = _this.$router.currentRoute.query.orderToken
        _this.status = _this.$router.currentRoute.query.status

        if(_this.orderToken !== undefined)
        {
            _this.scalapayConfirm()
        }
        else
        {
            _this.loading = false
        }
    },
    methods:
    {
        scalapayConfirm()
        {
            let _this = this

            Api.scalapayConfirm({
              token: Store.state.token,
              orderToken: _this.orderToken,
              status: _this.status,
            })
            .then((results) => {


              if(results && results.integratedOrderId){

                  _this.errors = false


                  if(_this.$gtm){

                    _this.$gtm.trackEvent({
                      event: 'customEvent',
                      category: 'userBehaviour',
                      action: 'confirmedOrder',
                      label: results.email,
                      value: results.price,
                    });

                    _this.$gtm.trackEvent({
                      event: 'customEvent',
                      category: 'partnerBehaviour',
                      action: 'confirmedOrder',
                      label: 'scalapay',
                      value: results.price,
                    });

                  }

                  if(window.LogRocket) window.LogRocket.track('confirmedOrder')
                  if(window.clarity){
                    window.clarity("set", "order", "confirmed")
                    window.clarity("set", "scalapay", "confirmedOrder")
                  }


                  if(results.updateAvailabilityTo)
                  {
                    // bisogna usare Vue.set altrimenti <User /> non vede il cambiamento della chiave availability interna a integration
                    //_this.mixinSendMutation('setAvailability',results.results.updateAvailabilityTo)
                    let newState = Store.state
                    newState.integration.availability = results.results.updateAvailabilityTo
                    Vue.set(newState);
                  }

                  _this.mixinGoTo('confirm-boxset',{
                    //confirmcode: results.confirmcode,
                    //itinerary_id: results.itinerary_id,
                    //msg: results.msg,
                    integratedOrderId: results.integratedOrderId,
                    parsedEmailId: results.parsedEmailId,
                    newAffiliateReferenceId: results.newAffiliateReferenceId,
                    redirectIfConfirmedRedirectTo: false
                  })

                }

                _this.loading = false


            })
        }
    },
}
</script>