var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { staticClass: "text-center" }, [
    _c("br"),
    _c("br"),
    _vm.integratedOrderId == "" || _vm.cancellaraToken == ""
      ? _c("div", [
          _vm._v("\n        C'è stato un problema, token non trovato!\n    "),
        ])
      : _c(
          "div",
          [
            _vm.loading
              ? _c("span", [
                  _vm._v(
                    "\n          Attendi, stiamo verificando il pagamento...\n        "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n          Siamo spiacenti, si è verificato un problema.\n          Contatta l'assistenza indicando il seguente codice: "
                  ),
                  _c("b", [_vm._v("IO-" + _vm._s(_vm.integratedOrderId))]),
                ]),
            _c("br"),
            _c("br"),
            _vm.loading ? _c("Spinner") : _vm._e(),
          ],
          1
        ),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }