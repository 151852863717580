<template>
    <div id="info" class="publicPage">
        <img src="../../../assets/hero-splash-2.jpeg" id="about">
        <div id="page">

            <br><br>

            <b-container>

                <section>
                    <h1 class="text-center">Chi siamo</h1>

                    <br><br>
                    
                    <b-row>
                        <b-col sm="12">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                <br><br>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </b-col>
                    </b-row>
                    
                    <br><br>

                    <b-row>
                        <b-col sm="12" lg="3">
                            <img src="https://via.placeholder.com/300">
                        </b-col>
                        <b-col sm="12" lg="9">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                <br><br>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </b-col>
                    </b-row>

                    <br><br>

                    <b-row>
                        <b-col sm="12" lg="9">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                <br><br>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </b-col>
                        <b-col sm="12" lg="3">
                            <img src="https://via.placeholder.com/300">
                        </b-col>
                    </b-row>

                </section>

                <br><br><br><br>

                <section>

                    <h1 id="come-funziona" class="text-center">Come funziona</h1>
                    <!--<h4 class="text-center">Le cose fondamentali da capire sono:</h4>-->

                    <br><br>

                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>

                    <br><br>

                    <Checklist :items="checklistItems_cosaVadobay" :numbers="true" />

                    <ComeFunzionaSalabam />

                </section>

                <br>

                <section>

                    <h1 id="assistenza" class="text-center">Assistenza</h1>
                    <!--<h4 class="text-center">Le cose fondamentali da capire sono:</h4>-->

                    <br><br>

                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>

                </section>

            </b-container>

            <br><br>

            <Help />
            
            <br><br><br><br>

            <b-container>

                <section>

                    <h1 id="assistenza" class="text-center">Titolo prova</h1>
                    <!--<h4 class="text-center">Le cose fondamentali da capire sono:</h4>-->

                    <br><br>

                    <p class="has-2-column">
                        Lorem ipsum dolor sit amet, <strong>consectetur adipiscing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>

                    <br><br>

                    <p class="has-2-column">
                        Lorem ipsum dolor sit amet, <strong>consectetur adipiscing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>

                    <br><br>

                    <b-row class="nogutter">
                        <b-col sm="12" md="6">
                            <img src="https://via.placeholder.com/300x150">
                        </b-col>
                        <b-col sm="12" md="6" class="hasBackground">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                        </b-col>
                    </b-row>

                </section>

                <br><br><br><br>

                <section>

                    <h1 id="assistenza" class="text-center">Titolo prova</h1>
                    <!--<h4 class="text-center">Le cose fondamentali da capire sono:</h4>-->

                    <br><br>

                    <b-row>
                        <b-col sm="12" md="6">
                            <div class="svgIcon"><SvgCartLarge /></div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                        </b-col>
                        <b-col sm="12" md="6">
                            <div class="svgIcon"><SvgMailLarge /></div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                        </b-col>
                    </b-row>


                </section>

                <br><br>






            
            </b-container>

            <div class="mt-4 mb-4 d-flex justify-content-center">
                <router-link :to="{name: 'homepage'}">
                    <BtnBack text="Torna alla Home" />
                </router-link>
            </div>

        </div>
    </div>
</template>

<script>
  import BtnBack from '../../atoms/btn-back'
  import Checklist from '../../blocks/common/checklist'
  import ComeFunzionaSalabam from '../../blocks/common/come-funziona-salabam'
  import Help from '../../blocks/footer/help'
  import SvgCartLarge from '../../svg/cart-large'
  import SvgMailLarge from '../../svg/mail-large'
  import Vue from "vue";

  export default {
    name: 'page',
    metaInfo: {
      title: 'Informazioni',
    },
    components: {
        Checklist, BtnBack, ComeFunzionaSalabam, Help, SvgCartLarge, SvgMailLarge,
    },
    data() {
        return {
          emailBusiness: Vue.prototype.$config.site.emailBusiness,
          checklistItems_mission : {
            0: "Il continuo sforzo di sviluppo tecnologico degli algoritmi che\n" +
              "selezionano i migliori tra gli oltre 500.000 hotel disponibili a cui\n" +
              "accediamo a livello italiano e internazionale, come anche lo sviluppo\n" +
              "di innovativi meccanismi di double check delle prenotazioni, non\n" +
              "sarebbero nulla senza la gentilezza, la competenza e la disponibilità\n" +
              "del nostro staff di customer care",
            1: "L'incessante sforzo di miglioramento\n" +
              "della user experience del nostro sito web non sarebbe nulla senza la\n" +
              "profonda comprensione, da parte del nostro management, dell'universo\n" +
              "travel nelle sue incessanti evoluzioni e cambiamenti",
          },
          numberedItems_comeFunziona : {
            0: {
                title: "DEVI ACCEDERE PASSANDO DAL PORTALE WELFARE",
                content: "<b>per utilizzare il servizio occorre accedere a VadoBay partendo dal link dentro al proprio portale welfare.</b><br>Se sei arrivato su questo sito da Google o digitando l’indirizzo VadoBay.com sul browser stai visualizzando una versione che non permette di fare ricerche e prenotazioni."
              },
            1: {
                title: "COME PAGARE LA PRENOTAZIONE",
                content: "il <b>pagamento della prenotazione</b> è la cosa più semplice del mondo perchè viene fatto tramite <b>addebito diretto e automatico sul tuo credito welfare.</b> Non è possibile pagare con carta di credito."
              },
              /*
            2: {
                title: "COME PAGARE LA PRENOTAZIONE",
                content: "una volta che sei acceduto a VadoBay partendo dal tuo portale welfare il <b>pagamento della prenotazione</b> è la cosa più semplice del mondo perchè viene fatto tramite <b>addebito diretto e automatico sul proprio credito welfare.</b>"
              },
              */
          },
          checklistItems_cosaVadobay : {
            0: "il nostro sito è <b>integrato</b> con i principali portali di welfare aziendale e permette ai dipendenti delle aziende servite di utilizzare il loro credito welfare per prenotare viaggi in maniera semplice e sicura. L'utente che accede a VadoBay dal portale welfare della sua azienda “trascina” il suo credito welfare e lo può utilizzare per pagare la sua prenotazione con un <b>addebito in tempo reale sul suo conto di welfare aziendale</b>",
            1: "<b>l’attenta selezione delle strutture proposte</b><br>\n" +
              "VadoBay seleziona in modo attento gli hotel e le altre strutture di ospitalità che ti proponiamo. Infatti delle oltre 500.000 strutture a cui abbiamo accesso ne proponiamo solo circa la metà. La chiamiamo <b>White List</b> perchè il meccanismo opera in modo preventivo bloccando il display delle nuove strutture disponibili finché queste non abbiano raggiunto un livello reputazionale sufficiente",
            2: "<b>la cura che poniamo ad ogni singola prenotazione</b><br>\nOgni prenotazione effettuata sul nostro sito, va incontro ad una procedura di <b>Double Check</b> ovvero una seconda verifica che avviene dopo la conferma della prenotazione verificando direttamente con la struttura, alcuni giorni prima del check-in, che non ci siano problemi e che tutto sia confermato come da prenotazione già confermata.",
          }
        }
    },
    mounted() {
    },
    methods:{
    }
  }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    #info
    {
        img
        {
            width:100%;
        }
    }
</style>