<template>
    <div class="riscattaSalabam">
        <section>
        <b-container>
            <b-row>
                <b-col>
                    <h4 class="titleText text-center black">
                        Hai generato un voucher Edenred?
                    </h4>
                </b-col>
            </b-row>     

            <br><br>

            <b-row>
                <b-col sm="12" lg="6">
                    <!--
                    <h4 class="titleText text-center black">
                        Hai generato un voucher Edenred?
                    </h4>

                    <br>-->

                    <h5 class="titleText text-center black">
                        Inseriscilo in questa pagina per ottenere in pochi istanti il tuo Salabam
                    </h5>

                    <br><br>

                    <ul class="listDisc">
                        <li>
                            tutte le disponibilità che vedrai sono reali e confermate senza necessità di telefonate o verifiche con la struttura
                        </li>
                        <li>
                            quando prenoterai il tuo viaggio, controlla se la camera che stai acquistando è rimborsabile o non-rimborsabile
                        </li>
                        <li>
                            anche a causa delle difficoltà a viaggiare causate dal Covid-19 abbiamo prolungato la validità del voucher a 16 mesi
                        </li>
                        <li>
                            per ogni camera disponibile sono specificati i servizi inclusi
                        </li>
                    </ul>

                    <br><br><br>

                    <h5 class="titleText text-center black">
                        Dove trovo il codice voucher Edenred?
                    </h5>

                    <br>

                    <p class="text-center">
                        Trovi il codice nella pagina di generazione del voucher sul sito Edenred o sul PDF che hai ricevuto a seguito della generazione
                    </p>

                    <p class="text-center">
                        <img src="@/assets/images/riscatta-salabam.png" />
                    </p>
                    
                </b-col>

                <b-col sm="12" lg="6">

                    <!--<br><br>-->
                    
                    <h5 class="titleText text-center black">
                        Inserisci i tuoi dati
                    </h5>

                    <p class="text-center">
                        Tutti i campi obbligatori
                    </p>

                    <br>

                    <b-form-group class="redeemFields" :state="validateFields()">

                        <div class="customInputBlock">
                            <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Inserisci il codice voucher generato sul sito Edenred'" />
                            <b-form-input @input="formatRedeemCode" ref="redeemCode" :required="true" v-model="redeemCode" type="text" placeholder="Codice voucher Edenred" />
                            <span class="formError"></span>
                            <span class="formClear" @click="clearField('redeemCode')"></span>
                        </div>

                        <div class="customInputBlock">
                            <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Inserisci il nome dell\'utilizzatore'" />
                            <b-form-input ref="nome" :required="true" v-model="nome" type="text" placeholder="Nome" />
                            <span class="formError"></span>
                            <span class="formClear" @click="clearField('nome')"></span>
                        </div>

                        <div class="customInputBlock">
                            <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Inserisci il cognome dell\'utilizzatore'" />
                            <b-form-input ref="cognome" :required="true" v-model="cognome" type="text" placeholder="Cognome" />
                            <span class="formError"></span>
                            <span class="formClear" @click="clearField('cognome')"></span>
                        </div>

                        <div class="customInputBlock">
                            <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Inserisci l\'indirizzo email dell\'utilizzatore'" />
                            <b-form-input ref="email" :required="true" v-model="email" type="email" placeholder="Indirizzo email" />
                            <span class="formError"></span>
                            <span class="formClear" @click="clearField('email')"></span>
                        </div>

                        <div class="customInputBlock">
                            <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Ripeti l\'indirizzo email dell\'utilizzatore'" />
                            <b-form-input ref="emailConf" :required="true" v-model="emailConf" type="email" placeholder="Ripeti indirizzo email" />
                            <span class="formError"></span>
                            <span class="formClear" @click="clearField('emailConf')"></span>
                        </div>

                        <br>

                        <div class="checkboxes">
                            <div class="customCheckboxBlock">
                                <b-form-checkbox v-model="checkboxPrivacy" ref="checkboxPrivacy" required type="checkbox" name="checkboxPrivacy" id="checkboxPrivacy" />
                                <label for="checkboxPrivacy">
                                    <a :href="privacyPolicyUrl" target="_blank" class="secondary">Ho preso visione della privacy policy e la accetto</a>
                                </label>
                            </div>

                            <div class="customCheckboxBlock">
                                <b-form-checkbox v-model="checkboxTerms" ref="checkboxTerms" required type="checkbox" name="checkboxTerms" id="checkboxTerms" />
                                <label for="checkboxTerms">
                                    <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                        <span class="secondary">Ho preso visione delle condizioni d'uso e le accetto</span>
                                    </router-link>
                                </label>
                            </div>

                            <br>

                            <div class="d-flex justify-content-center" v-if="!loading">
                                <b-button class="btn-block" v-if="recaptchaEnabled" :disabled="!validateFields()" @click="recaptcha">CONFERMA</b-button>
                                <b-button class="btn-block" v-else :disabled="!validateFields()" @click="redeem">CONFERMA</b-button>
                            </div>
                            <Spinner v-else />
                        </div>

                    </b-form-group>

                </b-col>
            </b-row>
        </b-container>
        </section>
    </div>    
</template>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Api from '../../api'
import Spinner from '../atoms/spinner'
import SvgInfoCircle from './../svg/info-circle'

export default {
    name: 'riscatta-salabam',
    components:
    {
        Spinner,
        SvgInfoCircle,
    },
    data()
    {
        return {
            //data: '',
            loading: false,
            nome: '',
            cognome: '',
            email: '',
            emailConf: '',
            redeemCode: '',
            checkboxTerms: false,
            checkboxPrivacy: false,
            recaptchaEnabled: Vue.prototype.$config.recaptcha.enabled,
            recaptchaToken: '',
            privacyPolicyUrl: Vue.prototype.$config.site.urlPrivacy,
        }
    },
    mounted()
    {
        if(Vue.prototype.$config.recaptcha.enabled)
        {
            Vue.use(VueReCaptcha, { siteKey: Vue.prototype.$config.recaptcha.siteKey })
        }        
    },
    methods:
    {
        async recaptcha() {
            this.loading = true
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')
            // Do stuff with the received token.
            this.recaptchaToken = token
            this.redeem()
        },
        clearField(ref)
        {
            this[ref] = ''
            this.$refs[ref].focus()
            this.validateFields()
        },
        formatRedeemCode()
        {
            /*
            let oldredeemCode = (this.redeemCode).replace(/\s/g,''),
                newredeemCode = (oldredeemCode.match(/.{1,4}/g)).join(' ')
            ;
        
            this.redeemCode = newredeemCode

            return true
            */
        },
        validateFields()
        {
            if(Object.keys(this.$refs).length == 0) return false
            return this.mixinValidateFields([
                {
                    ref: this.$refs.nome,
                    type: 'firstName',
                    value: this.nome,
                },
                {
                    ref: this.$refs.cognome,
                    type: 'lastName',
                    value: this.cognome,
                },
                {
                    ref: this.$refs.email,
                    type: 'email',
                    value: this.email,
                    optional: false,
                },
                {
                    ref: this.$refs.emailConf,
                    type: 'emailConfirm',
                    value: (this.email == this.emailConf),
                    optional: false,
                },
                {
                    ref: this.$refs.privacy,
                    type: 'checkbox',
                    value: this.checkboxPrivacy,
                },
                {
                    ref: this.$refs.terms,
                    type: 'checkbox',
                    value: this.checkboxTerms,
                },
                {
                    ref: this.$refs.redeemCode,
                    type: 'redeemCode',
                    value: this.redeemCode,
                }
            ])
        },
        redeem()
        {
            
            let _this = this,
                params = {
                    nome: _this.nome,
                    cognome: _this.cognome,
                    email: _this.email,
                    emailConf: _this.emailConf,
                    redeemCode: _this.redeemCode,
                    recaptcha: _this.recaptchaToken,
                }

            _this.loading = true

            Api.redeemCode(params)
            .then((response) => {
                if(response !== false)
                {
                    _this.$router.push({
                        name: 'confirm-boxset',
                        params: {
                            integratedOrderId: response.integratedOrderId,
                            parsedEmailId: response.parsedEmailId,
                            redirectIfConfirmedRedirectTo: false
                        }
                    })
                }
                else
                {
                    _this.loading = false
                }
            })
        }            
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './src/scss/_custom.scss';
    .riscattaSalabam
    {
        display:block;
        width:100%;
        margin-top:4rem;
        margin-bottom:6rem;
        @include media-breakpoint-down(sm)
        {
            padding-top:2rem;
        }

        .listDisc
        {
            //font-size: 14px;
            li:not(:last-child)
            {
                margin-bottom:10px;
            }
        }

        .redeemFields
        {
            margin:0 auto;
            max-width: 420px;
        }

        .checkboxes
        {
            margin:auto;
            max-width: 320px;
        }
    }
</style>