<template>

  <!--

    questo "form newsletter" è un "surrogato

    sarebbe stato da gestire come l'altra newsletter "consumer" ma non avendo nulla sul backend per
    gestire 2 liste (e serve subito) sfrutto il meccanismo dei form per inviare la mail a Kosta e poi
    la lista se la gestisce lui.

  -->

  <div id="iscrizione-newsletter-business" class="newsletterBox">

    <div v-if="loading" class="text-center">
      <Spinner :msg="'Solo un istante...'" />
    </div>

    <div v-else-if="apiResponse !== ''" class="text-center">
      <b-alert show variant="success">
        {{apiResponse}}
      </b-alert>
    </div>

    <div v-else>
      <p class="newsletterTitle">
        RESTA AGGIORNATO SULLE NOSTRE SOLUZIONI BUSINESS
        <br>

        <span class="small">Applicazioni di travel booking per il marketing, soluzioni e casi di successo.</span><br/>
        <span class="small text-center">No-spam!</span>
      </p>

      <div class="customCheckboxBlock">
        <b-form-checkbox v-model="privacy" ref="privacy" required type="checkbox" name="privacy" id="privacy" />
        <label for="privacy">
          Inviando questo modulo, confermo di aver letto e accettato l'<span class="secondary"><a :href="privacyPolicyUrl" target="_blank">Informativa sulla Privacy</a></span>.
        </label>
      </div>

      <!--
      <div class="customCheckboxBlock">
          <b-form-checkbox v-model="newsletterCheckboxTerms" ref="newsletterCheckboxTerms" required type="checkbox" name="newsletterCheckboxTerms" id="newsletterCheckboxTerms" />
          <label for="newsletterCheckboxTerms">
              <strong>Do il consenso</strong> a ricevere per email news, anche personalizzate, riguardo offerte speciali e idee di viaggio da Salabam srl.
              Potrai disiscriverti in ogni momento usando il link presente in ogni nostra email.
              <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                  <span class="secondary">Termini e condizioni</span>
              </router-link>
          </label>
      </div>
      -->

      <br>

      <div class="d-flex">
        <div class="customInputBlock emailBlock">
          <b-form-input ref="email" :required="true" v-model="email" type="email" placeholder="Inserisci la tua email" />
          <span class="formError"></span>
          <span class="formClear" @click="clearField('email')"></span>
        </div>

        <div class="loginBlockLogin">
          <div class="loginButton">
            <b-button :disabled="!validateFields()" @click="submitForm">ISCRIVITI</b-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Spinner from '../../atoms/spinner'

export default {
  name: 'form-iscrizione-newsletter-business',
  props:
      {
        formTitle:
            {
              type: String,
              default: '', // titolo del form, renderizzato in alto se != ''
            },
        type:
            {
              type: String,
              default: 'form-iscrizione-newsletter-business', // tipo form, usato per distinguere i form e per tracking
            },
        label:
            {
              type: String,
              default: '', // per distinguere lo stesso form messo in posti diversi, usato anche per tracking
            },
        recipient:
            {
              type: String,
              default: 'business@salabam.com', // email destinatario
            }
      },
  components:
      {
        Spinner,
      },
  data()
  {
    return {
      uid: this._uid,
      loading: false,
      recaptchaEnabled: Vue.prototype.$config.recaptcha.enabled,
      recaptchaToken: '',
      email: '',
      privacy: false,
      privacyPolicyUrl: Vue.prototype.$config.site.urlPrivacy,
      apiResponse: '',
    }
  },
  mounted()
  {
    if(Vue.prototype.$config.recaptcha.enabled)
    {
      Vue.use(VueReCaptcha, { siteKey: Vue.prototype.$config.recaptcha.siteKey })
    }
  },
  methods:
      {
        async recaptcha() {
          await this.$recaptchaLoaded()
          const token = await this.$recaptcha('login')
          this.recaptchaToken = token
          this.sendToApi()
        },
        clearField(ref)
        {
          this[ref] = ''
          this.$refs[ref].focus()
          this.validateFields()
        },
        submitForm()
        {
          this.loading = true
          if(this.recaptchaEnabled)
          {
            this.recaptcha()
          }
          else
          {
            this.sendToApi()
          }
        },
        sendToApi()
        {
          let _this = this

          Api.formsSubmitter({
            recaptcha: _this.recaptchaToken,
            fields: {
              email: _this.email,
              info: 'questo indirizzo email deve essere inserito nella mailing list business',
            },
            settings:
                {
                  type: _this.type,
                  label: _this.label,
                  recipient: _this.recipient,
                  formTitle: _this.formTitle,
                }
          })
              .then((data) => {

                if(process.env.VUE_APP_MODE != 'production') window.console.log(data)
                if(process.env.VUE_APP_MODE != 'production') window.console.log(data.msg)

                _this.apiResponse = data.msg
                _this.loading = false

                if(this.$gtm) this.$gtm.trackEvent({
                  event: 'customEvent',
                  category: 'userBehaviour',
                  action: 'formSubmit',
                  label: _this.type,
                  value: _this.label,
                })

              })
        },
        validateFields()
        {
          if(Object.keys(this.$refs).length == 0) return false

          return this.mixinValidateFields([
            {
              ref: this.$refs.email,
              type: 'email',
              value: this.email,
              optional: false,
            },
            {
              ref: this.$refs.privacy,
              type: 'checkbox',
              value: this.privacy,
              optional: false,
            },
          ])
        },
      }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../../../scss/_custom.scss';

.newsletterBox
{
  margin:0 auto;
  width:90%;
  max-width: 700px;
  text-align:left;

  label
  {
    line-height: 140%;
    font-size: 12px;
  }

  .newsletterTitle
  {
    font-size: 20px;
    color:$black;
    font-weight:bold;
    .small
    {
      font-size:16px;
      font-weight:bold;
    }
  }
  .emailBlock
  {
    flex:1;
    min-width: auto;
    max-width: 360px;
    margin-right: 1.5rem;
  }
}

</style>