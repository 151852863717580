<template>
    <b-container class="text-center">

        <br><br>
        <div v-if="jpToken == '' || jpToken == undefined">
            C'è stato un problema, token non trovato!
        </div>
        
        <div v-else>

            <span v-if="this.$router.currentRoute.query.status =='PLACED'">
              Attendi, stiamo attendendo l'autorizzazione dal tuo portale welfare
            </span>

            <span v-else>
              Attendi, stiamo processando la tua richiesta...
            </span>

            <br><br>
            <Spinner v-if="loading" />
        </div>
        <br><br>
    </b-container>
</template>

<script>
import Vue from 'vue'
import Store from '../../../vuex/store'
//import Router from 'vue-router'
import Spinner from '../../atoms/spinner'
import Api from '../../../api'
export default {
    name: 'jp-checkout',
    components:
    {
        Spinner,
    },
    props:
    {
    },
    data()
    {
        return {
            jpToken: '',
            loading: true,
            timing: 5000,
            counter: 0,
        }
    },
    mounted()
    {
        let _this = this

        _this.jpToken = _this.$router.currentRoute.query.jpToken

        if(_this.jpToken !== undefined)
        {
            _this.jpCheckToken()
        }
        else
        {
            _this.loading = false
        }
    },
    methods:
    {
        jpCheckToken()
        {
            let _this = this 

            setTimeout(function(){

                _this.counter = _this.counter + 1
                //window.console.log('counter: '+ _this.counter)
                
                Api.jpCheckToken({
                    jpToken: _this.jpToken,
                    counter: _this.counter
                })
                .then((results) => {


                    if(results.tryAgain) _this.jpCheckToken()
                  
                    if(results.check)
                    {
                      
                        if(_this.$gtm){
  
                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'userBehaviour',
                            action: 'confirmedOrder',
                            //label: Vue.prototype.$config.integration.email,
                            value: results.price,
                          });
  
                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'confirmedOrder',
                            label: Vue.prototype.$config.integration.company,
                            value: results.price,
                          });
  
                        }
  
                        if(window.LogRocket) window.LogRocket.track('confirmedOrder')
                        if(window.clarity) window.clarity("set", "order", "confirmed")
                      
                      
                        if(results.data.updateAvailabilityTo)
                        {
                            // bisogna usare Vue.set altrimenti <User /> non vede il cambiamento della chiave availability interna a integration
                            //_this.mixinSendMutation('setAvailability',results.data.updateAvailabilityTo)
                            let newState = Store.state
                            newState.integration.availability = results.data.updateAvailabilityTo
                            Vue.set(newState);
                        }

                        _this.mixinGoTo('confirm-boxset',{
                            confirmcode: results.data.confirmcode,
                            itinerary_id: results.data.itinerary_id,
                            msg: results.data.msg,
                            integratedOrderId: +results.data.integratedOrderId,
                            parsedEmailId: +results.data.parsedEmailId,
                            newAffiliateReferenceId: results.data.newAffiliateReferenceId,
                            redirectIfConfirmedRedirectTo: false
                        })
                    }
                  

                })
            }, _this.timing)
        }
    },
}
</script>