<template>
    <div class="publicPage landingPage landingScalapay">
        <div id="hero" :style="'background-image:url('+require('../../../assets/landing/hero-landing-default.jpg')+')'">
            <b-container fluid>
                <b-row>
                    <b-col sm="12" lg="6" class="heroCol">
                        <img class="heroLogo" src="assets/scalapay/logo-black.svg" />
                    </b-col>
                    <b-col sm="12" lg="6" class="heroCol">
                        <div class="panel panel-white large-radius">
                            <div class="panel-content">
                                <b-row>
                                    <b-col sm="12" md="6" class="value">
                                        <div class="valueIcon spin"><SvgPurchaseSpin /></div>
                                        <div class="valueText"><p><strong>I Salabam sono voucher per prenotare viaggi online</strong></p></div>
                                    </b-col>
                                    <b-col sm="12" md="6" class="value">
                                        <div class="valueIcon ticket"><SvgTicket /></div>
                                        <div class="valueText"><p><strong>Voucher da 75 a 3.500€ che potrai acquistare pagando in 3 semplici rate con Scalapay</strong></p></div>
                                    </b-col>
                                    <b-col sm="12" md="6" class="value">
                                        <div class="valueIcon bed"><SvgPurchaseBed /></div>
                                        <div class="valueText"><p><strong>450.000 hotel, bed&breakfast e appartamenti in Italia e nel mondo prenotabili con pochi click</strong></p></div>
                                    </b-col>
                                    <b-col sm="12" md="6" class="value">
                                        <div class="valueIcon whatsapp"><SvgWhatsapp /></div>
                                        <div class="valueText"><p><strong>Un servizio di supporto a tua disposizione via chat, Whatsapp, telefono e email</strong></p></div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="pageContent content">
            <section>
                I Salabam sono voucher che permettono di prenotare soggiorni su disponibilità aggiornate in tempo reale e a conferma immediata.
            </section>
            <section>
                <h1 class="title">Ordina ora, paga poi</h1>
                <b-row class="icons">
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-blu-1.svg" />
                        <p class="text">3 COMODE<br>RATE</p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-blu-2.svg" />
                        <p class="text">0%<br>INTERESSE</p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-blu-3.svg" />
                        <p class="text">SEMPLICE E<br>VELOCE</p>
                    </b-col>
                </b-row>
            </section>

            <br><br>

            <h2 class="title">Come si acquista un voucher Salabam pagando con Scalapay?</h2>

            <section>
            <div class="checklist">
                <ul>
                    <li>
                        <span class="checklist_index">1</span>
                        <div class="checklist_item">
                            <p class="primary">SCEGLI IL VOUCHER SALABAM</p>
                            <p>
                                Clicca sul bottone INIZIA in fondo alla pagina e trova il voucher adatto al tuo budget e alle tue necessità.
                                <br>
                                Puoi vedere in tempo reale le disponibilità in oltre 450.000 hotel, B&B, appartamenti in Italia e nel mondo.                            
                            </p>
                        </div>
                    </li>
                    <li>
                        <span class="checklist_index">2</span>
                        <div class="checklist_item">
                            <p class="primary">EFFETTUA L’ORDINE SEELEZIONANDO COME METODO DI PAGAMENTO SCALAPAY</p>
                            <p>
                                Seleziona il Salabam che intendi acquistare e clicca su SCEGLI, in fase di conferma dell’ordine potrai selezionare come metodo di pagamento Scalapay.
                            </p>
                        </div>
                    </li>
                    <li>
                        <span class="checklist_index">3</span>
                        <div class="checklist_item">
                            <p class="primary">PRENOTA IL TUO VIAGGIO ONLINE</p>
                            <p>
                                Riceverai per email il voucher che hai acquistato. Basterà seguire il link per prenotare il il tuo soggiorno. 
                                <CopyScadenza
                                    default="Avrai {value} mesi per effettuare la tua prenotazione e viaggiare scegliendo tra disponibilità aggiornate in tempo reale e a conferma immediata."
                                />
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            </section>

            <section>
                <b-row class="icons pink">
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-pink-1.svg" />
                        <p class="text">Ricevi il link per il pagamento via email o sms</p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-pink-2.svg" />
                        <p class="text">Crea il tuo account/Login</p>
                    </b-col>
                    <b-col sm="12" md="4" class="box">
                        <img class="icon" src="assets/scalapay/icon-pink-3.svg" />
                        <p class="text">Paga in 3 rate, senza interessi</p>
                    </b-col>
                </b-row>
            </section>
            
            <section>
                <p class="textAbove linkScalapay text-center">
                    Visita <a href="https://www.scalapay.com" target="_blank">www.scalapay.com</a> per tutte le condizioni.
                </p>
            </section>
            
            <section>
                <div class="boxed text-center">
                    <p>
                        <strong>Clicca il bottone e scopri i voucher e le disponibilità</strong>
                    </p>

                    <br>

                    <b-button variant="secondary">
                        <router-link :to="{name: 'homepage'}" class="white">Inizia</router-link>
                    </b-button>

                </div>
            </section>

        </b-container>

    </div>
</template>

<script>
import Vue from "vue";
import SvgPurchaseSpin from '../../svg/purchase-spin'
import SvgPurchaseBed from '../../svg/purchase-bed'
import SvgTicket from '../../svg/ticket'
import SvgWhatsapp from '../../svg/whatsapp'
import CopyScadenza from '@/components/blocks/common/copy-scadenza'

export default {
    name: 'landing-scalapay',
    components:
    {
        SvgPurchaseSpin,
        SvgPurchaseBed,
        SvgTicket,
        SvgWhatsapp,
        CopyScadenza,
    },
    data()
    {
        return {
                config: this.appConfig(),
                scalapay: Vue.prototype.$config.guiSettings.integrations.scalapay,
            }
    },
    mounted()
    {
    },
    methods:
    {
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .landingScalapay
    {
        .container
        {
            max-width: 1100px !important;
        }
        .content
        {
            padding-top:40px;
            padding-bottom:40px;
        }
        .title
        {
            font-size:20px;
            text-align: center;
            margin-bottom:30px;
            @include media-breakpoint-up(md)
            {
                font-size: 28px;
                margin-bottom:40px;
            }
        }
        .heroLogo
        {
            max-width:100%;
            width: 300px;
        }
        .aon
        {
            color: #E31836;
            font-weight:600;
        }
        .white, .white *
        {
            text-decoration:none !important;
        }
        .icons
        {
            text-align: center;
            .icon
            {
                margin-bottom:40px;
            }
            .text
            {
                max-width: 200px;
                margin: auto;                
            }
        }
        .primary
        {
            font-weight: 600;
            font-size: 1.25em;
        }
    }
</style>