var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage assistenza", attrs: { id: "info" } },
    [
      _c("div", { staticClass: "heroContainer" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/assistenza_header.jpg"),
            id: "about",
          },
        }),
        _c(
          "div",
          { staticClass: "publicBookingEngine" },
          [
            _c("SearchBar", {
              attrs: {
                loading: false,
                band: _vm.activeBand,
                focus: "",
                geo: _vm.geo,
                dates: _vm.dates,
                occupancy: _vm.occupancy,
                hasResults: false,
                offset: 0,
                lastSearch: "",
                showUserBox: false,
              },
              on: {
                "submit-search": function ($event) {
                  _vm.mixinSendMutation("setTriggerSearch", true)
                  _vm.mixinGoTo("search", {})
                },
                "filter-changed": function ($event) {
                  {
                  }
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { attrs: { id: "page" } },
        [
          _c(
            "b-container",
            [
              _c(
                "section",
                { attrs: { id: "viaggi-sicuro" } },
                [
                  _c("br"),
                  _c("h3", [_vm._v("Perchè con Salabam Viaggi sicuro")]),
                  _c("br"),
                  _c("br"),
                  _c("p", [
                    _vm._v(
                      "\n          Il nostro impegno incessante è concentrato ogni giorno su due cose: la tecnologia e la cura del servizio che ti forniamo.\n        "
                    ),
                  ]),
                  _c("p", [
                    _vm._v("\n          Della tecnologia "),
                    _c("a", { attrs: { href: "#tecnologia" } }, [
                      _vm._v("parliamo qui"),
                    ]),
                    _vm._v(
                      ". Per quanto riguarda la cura del servizio e della tua esperienza devi sapere che:\n        "
                    ),
                  ]),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgDoubleCheck"),
                            _c("h6", [_vm._v("Double check")]),
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "\n              Il nostro staff, supportato da efficienti dispositivi tecnologici, verifica la tua prenotazione pochi giorni prima del tuo arrivo.\n            "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n              La tua prenotazione è naturalmente confermata in tempo reale nel momento in cui hai cliccato sul tasto PRENOTA ma, con pignoleria, effettuiamo questo controllo ridondante qualche giorno prima del tuo check-in facendo in modo che al tuo arrivo sia tutto pronto.\n            "
                          ),
                        ]),
                      ]),
                      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgHeartLarge"),
                            _c("h6", [_vm._v("White list")]),
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "\n              Con un sapiente mix di tecnologia e competenza umana, effettuiamo una selezione delle strutture che ti proponiamo valutando non solo la qualità della struttura stessa ma anche la sua esperienza e professionalità.\n            "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n              Cerchiamo infatti di evitare di proporti B&B, appartamenti magari appena aperti e gestiti da operatori improvvisati e non professionali.\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c(
                "section",
                { attrs: { id: "covid19" } },
                [
                  _c("h3", [_vm._v("Covid-19")]),
                  _c("br"),
                  _c("p", [
                    _vm._v(
                      "\n          Monitoriamo costantemente la situazione a livello sanitario e normativo e stiamo operando in questo modo al fine di farti viaggiare con sicurezza e tranquillità:\n        "
                    ),
                  ]),
                  _c("br"),
                  _c("div", { staticClass: "checklist" }, [
                    _c("ul", [
                      _c("li", [
                        _c("span", { staticClass: "checklist_index" }, [
                          _vm._v("1"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "checklist_item" },
                          [_c("CopyScadenza")],
                          1
                        ),
                      ]),
                      _c("li", [
                        _c("span", { staticClass: "checklist_index" }, [
                          _vm._v("2"),
                        ]),
                        _c("div", { staticClass: "checklist_item" }, [
                          _c("span", [
                            _vm._v(
                              "Il nostro sito propone un numero sempre maggiore di soluzioni con Cancellazione gratuita. "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", { staticClass: "checklist_index" }, [
                          _vm._v("3"),
                        ]),
                        _c("div", { staticClass: "checklist_item" }, [
                          _c("span", [
                            _vm._v(
                              "\n                              Abbiamo raccolto e messo in evidenza nella pagina di ogni singola struttura le misure anti-Covid e i protocolli di sicurezza adottati dalla stessa\n                          "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("br"),
                  _c("p", [
                    _vm._v(
                      "\n          Ti ricordiamo che le soluzioni Non rimborsabili non prevedono, in caso di cancellazione, alcun tipo di rimborso. Su tua richiesta e in base a specifici motivi legati alla pandemia saremo felici di darti supporto richiedendo alla struttura un rimborso ma resta inteso che la concessione di un rimborso resta una decisione totalmente discrezionale della struttura stessa sulla quale noi non abbiamo alcuna specifica influenza.\n        "
                    ),
                  ]),
                  _c("br"),
                  _c(
                    "div",
                    {
                      staticClass:
                        "torna-indietro mt-4 mb-4 d-flex justify-content-end",
                    },
                    [
                      _c("i", [
                        _vm._v(
                          "ultimo aggiornamento " + _vm._s(_vm.lastUpdateDate)
                        ),
                      ]),
                    ]
                  ),
                  _c("br"),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c(
                "section",
                { attrs: { id: "tecnologia" } },
                [
                  _c("br"),
                  _c("h3", [_vm._v("La nostra tecnologia per te")]),
                  _c("br"),
                  _c("p", [
                    _vm._v(
                      "\n          L'incessante sviluppo tecnologico che caratterizza la nostra azienda e i servizi che forniamo è ispirato da due principi:\n        "
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [_c("SvgRocket"), _c("h6", [_vm._v("On the edge")])],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "\n              essere sempre sulla frontiera avanzata della tecnologia web e IT con lo scopo di fornirti un servizio di prenotazione efficiente e un'esperienza sicura e divertente\n            "
                          ),
                        ]),
                      ]),
                      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgPersonCircle"),
                            _c("h6", [_vm._v("Tecnologia accessibile")]),
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "\n              fornire un servizio online multi-device, con elevati tassi di usabilità all'insegna della semplicità d'uso per qualsiasi tipo di utente anche quello meno abituato agli acquisti online\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                  _c("Quote", {
                    attrs: {
                      text: "Per farlo abbiamo costruito nel tempo un team di sviluppo interno che si avvale di eccezionali sviluppatori web, back e front, web designer, esperti di User Experience (UX) e User Interface (UI).<br/><br/>Il nostro team di sviluppo tecnologico dialoga quotidianamente con il team di sviluppo del business e con quello di customer care.",
                    },
                  }),
                  _c("br"),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c(
                "section",
                { attrs: { id: "acquista-con-il-welfare-aziendale" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c("br"),
                          _c("h3", [
                            _vm._v(
                              "Acquista un Salabam con il welfare aziendale!"
                            ),
                          ]),
                          _c("br"),
                          _c("br"),
                          _c("p", [
                            _vm._v(
                              "\n              Vuoi utilizzare il tuo credito welfare per acquistare un voucher Salabam? Ecco come fare:\n            "
                            ),
                          ]),
                          _c("div", { staticClass: "checklist" }, [
                            _c("ul", [
                              _c("li", [
                                _c("span", { staticClass: "checklist_index" }, [
                                  _vm._v("1"),
                                ]),
                                _c("div", { staticClass: "checklist_item" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Accedi al tuo portale welfare e cerca il nostro servizio"
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", { staticClass: "checklist_index" }, [
                                  _vm._v("2"),
                                ]),
                                _c("div", { staticClass: "checklist_item" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Una volta individuato Salabam troverai un link per accedere al nostro sito. Cliccando su questo link il sito Salabam riconoscerà il tuo credito e ti permetterà di acquistare il voucher in tempo reale e con un addebito diretto del costo sul tuo conto welfare"
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", { staticClass: "checklist_index" }, [
                                  _vm._v("3"),
                                ]),
                                _c("div", { staticClass: "checklist_item" }, [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                                  Riceverai per email il voucher che hai acquistato. Basterà seguire il link per prenotare il tuo soggiorno. \n                                  "
                                      ),
                                      _c("CopyScadenza", {
                                        attrs: {
                                          default:
                                            "Avrai comunque {value} mesi per effettuare la tua prenotazione e viaggiare scegliendo tra disponibilità aggiornate in tempo reale e a conferma immediata.",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("br"),
                          _c("br"),
                          _c(
                            "b-row",
                            { staticClass: "nogutter" },
                            [
                              _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/assistenza.png"),
                                  },
                                }),
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "hasBackground",
                                  attrs: { sm: "12", md: "6" },
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "\n                  Salabam è presente in tutti i principali portali welfare italiani e serve decine di migliaia di lavoratori di centinaia di PMI italiane ed internazionali\n                "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c(
                "section",
                { attrs: { id: "acquista-un-voucher-salabam" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12" } }, [
                        _c("h3", [_vm._v("Acquista un voucher Salabam!")]),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n              Per acquistare un voucher Salabam puoi utilizzare la tua carta di credito, il tuo welfare aziendale o Buoni sconto Salabam"
                          ),
                          _vm.mixinUseTicketCompliments()
                            ? _c("span", [
                                _vm._v(", Ticket Compliments Edenred"),
                              ])
                            : _vm._e(),
                          _vm.mixinUsePromoshopping()
                            ? _c("span", [_vm._v(", Promoshopping")])
                            : _vm._e(),
                          _vm._v(".\n            "),
                        ]),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n              Farlo è molto semplice:\n            "
                          ),
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "checklist" }, [
                          _c("ul", [
                            _c("li", [
                              _c("span", { staticClass: "checklist_index" }, [
                                _vm._v("1"),
                              ]),
                              _c("div", { staticClass: "checklist_item" }, [
                                _c("span", [
                                  _vm._v(
                                    "Cerca le disponibilità scegliendo la destinazione, la data e i partecipanti del tuo viaggio. Ti mostreremo che cosa potrai prenotare acquistando i diversi voucher disponibili"
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("li", [
                              _c("span", { staticClass: "checklist_index" }, [
                                _vm._v("2"),
                              ]),
                              _c("div", { staticClass: "checklist_item" }, [
                                _c("span", [
                                  _vm._v("Scegli il voucher ed acquistalo"),
                                ]),
                              ]),
                            ]),
                            _c("li", [
                              _c("span", { staticClass: "checklist_index" }, [
                                _vm._v("3"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "checklist_item" },
                                [
                                  _c("CopyScadenza", {
                                    attrs: {
                                      default:
                                        "Avrai comunque {value} mesi per effettuare la tua prenotazione e viaggiare scegliendo tra disponibilità aggiornate in tempo reale e a conferma immediata.",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", align: "center" } },
                        [
                          _c(
                            "b-button",
                            { attrs: { variant: "secondary" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "homepage" } } },
                                [_vm._v("Vai alla ricerca")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c(
                "section",
                { attrs: { id: "regala-un-salabam" } },
                [
                  _c("h3", [_vm._v("Regala un Salabam!")]),
                  _c("br"),
                  _c("p", [
                    _vm._v(
                      "\n          Salabam è un ottimo regalo per qualsiasi ricorrenza: si acquista e si invia con pochi click anche all'ultimo minuto. Per il destinatario del regalo, prenotare un viaggio, è semplicissimo.\n        "
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgPeople"),
                            _c("h6", [_vm._v("Tu regali, l'altro sceglie")]),
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "\n              Regalare un viaggio prima di Salabam era difficile perchè bisognava interpretare i gusti del destinatario del regalo.\n            "
                          ),
                        ]),
                        _c(
                          "p",
                          [
                            _c("CopyScadenza", {
                              attrs: {
                                default:
                                  "Con Salabam sarà il destinatario del regalo a scegliere dove, quando e con chi viaggiare, con comodità, online, nell'arco di {value} mesi.",
                                promoMonths:
                                  "Con Salabam sarà il destinatario del regalo a scegliere dove, quando e con chi viaggiare, con comodità, online, nell'arco di {value} mesi. (anzichè dei tradizionali {defaultMonths}, in promozione fino al {promoValidUntil})",
                                promoFixed:
                                  "Con Salabam sarà il destinatario del regalo a scegliere dove, quando e con chi viaggiare, con comodità, online, nell'arco di {defaultMonths} mesi. Promozione {promoTitle}: acquista oggi ed il tuo Salabam sarà valido fino al {value}",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgLoader"),
                            _c("h6", [_vm._v("Online e con pochi click")]),
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "\n              Sia regalare un Salabam che usarlo per prenotare è facile ed efficace.\n            "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n              Salabam, infatti, è un servizio online: le disponibilità che vedi sono reali e possono essere prenotate con pochi click.\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12", md: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "svgIcon" },
                          [
                            _c("SvgSmile"),
                            _c("h6", [_vm._v("Cura del viaggiatore")]),
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "\n              Puntiamo tutto su tecnologia e cura del cliente. Per questo abbiamo uno staff di assistenza interno all'azienda, che lavora con un approccio multicanale e soprattutto con attenzione e cura al viaggiatore.\n            "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n              Abbiamo approntato meccanismi di checking delle prenotazioni che riducono al minimo i fisiologici tassi di overbooking e di errore in generale.\n            "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n              Selezioniamo le strutture che proponiamo non solo in base alla qualità ma anche in base all'esperienza del gestore.\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", align: "center" } },
                        [
                          _c("p", [_c("b", [_vm._v("Comincia da qui")])]),
                          _c(
                            "b-button",
                            { attrs: { variant: "secondary" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "home" } } },
                                [_vm._v("Vai alla ricerca")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("br"),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _vm.vv.isPromo
                ? _c(
                    "section",
                    { attrs: { id: "promozione-in-corso" } },
                    [
                      _c(
                        "h3",
                        { staticClass: "section-title" },
                        [
                          _c("CopyScadenza", {
                            attrs: {
                              default:
                                "Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto",
                              promoMonths:
                                "Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto",
                              promoFixed:
                                "Acquisti adesso, prenoti entro il {value}",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("br"),
                      _c("p", [_c("CopyScadenza"), _vm._v(".\n        ")], 1),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "svgIcon" },
                              [
                                _c("SvgValidita"),
                                _c(
                                  "h6",
                                  [
                                    _c("CopyScadenza", {
                                      attrs: {
                                        default: "Validità {value} mesi",
                                        promoMonths: "Validità {value} mesi",
                                        promoFixed: "Validità fino al {value}",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c("CopyScadenza", {
                                  attrs: {
                                    default:
                                      "Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto",
                                    promoMonths:
                                      "Acquisti adesso, prenoti entro {value} mesi dalla data di acquisto",
                                    promoFixed:
                                      "Acquisti adesso, prenoti entro il {value}",
                                  },
                                }),
                                _vm._v(".\n            "),
                              ],
                              1
                            ),
                            _c("p", [
                              _vm._v(
                                "\n              Ricorda che sia la prenotazione sia il viaggio dovranno essere effettuati entro la data indicata nel voucher.\n            "
                              ),
                            ]),
                          ]),
                          _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "svgIcon" },
                              [
                                _c("SvgRefund"),
                                _c("h6", [_vm._v("Viaggia rimborsabile")]),
                              ],
                              1
                            ),
                            _c("p", [
                              _vm._v(
                                "\n              Su Salabam trovi una grande quantità di soggiorni di tipo rimborsabile.\n            "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "\n              Prenota soggiorni RIMBORSABILI e potrai cancellare ogni prenotazione ottenendo la riattivazione del voucher per una nuova prenotazione.\n            "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "12", align: "center" } },
                            [
                              _c("p", [
                                _vm._v(
                                  "Scegli e acquista un voucher Salabam adesso e beneficia della promozione!"
                                ),
                              ]),
                              _c(
                                "b-button",
                                { attrs: { variant: "secondary" } },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticStyle: { color: "white!important" },
                                      attrs: { to: { name: "shop" } },
                                    },
                                    [_vm._v("Vai allo shop!")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c("br"),
                      _c("TornaIndietro"),
                      _c("br"),
                      _c("br"),
                      _c("br"),
                      _c("br"),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "section",
                { attrs: { id: "prenota-un-viaggio" } },
                [
                  _c("h3", [_vm._v("Usa un Salabam, prenota un viaggio!")]),
                  _c("br"),
                  _c("p", [
                    _vm._v(
                      "\n          Se sei in possesso di un voucher Salabam e vuoi utilizzarlo per prenotare un viaggio, vai alla pagina:\n        "
                    ),
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "login-salabam" } } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "white",
                          attrs: { size: "sm", variant: "primary" },
                        },
                        [_vm._v(_vm._s(_vm._f("uppercase")("ho un salabam")))]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("br"),
                  _c("p", [
                    _vm._v("\n            e fai login inserendo:\n        "),
                  ]),
                  _c("br"),
                  _c("Checklist", {
                    attrs: {
                      items: _vm.checklistItems_hoUnSalabam,
                      numbers: true,
                    },
                  }),
                  _c("br"),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c(
                "section",
                { attrs: { id: "richiedi-un-preventivo" } },
                [
                  _c("h3", [_vm._v("Richiedi un preventivo")]),
                  _c("br"),
                  _c("p", [
                    _vm._v(
                      "\n          Inviaci qualche informazione sulle tue esigenze ed i tuoi desideri.\n        "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n          Il nostro staff di assistenza ti invierà una lista di hotel corrispondenti alle tue richieste indicandoti quale voucher Salabam acquistare per prenotare uno degli hotel proposti.\n        "
                    ),
                  ]),
                  _c("br"),
                  _c("FormAssistenzaPreventivo", {
                    attrs: {
                      formTitle: "Inviaci la tua richiesta",
                      label: "richiedi-un-preventivo",
                    },
                  }),
                  _c("br"),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c(
                "section",
                { attrs: { id: "segnala-un-problema" } },
                [
                  _c("h3", [_vm._v("Segnala un problema")]),
                  _c("br"),
                  _c("FormSegnalazioneGenerica", {
                    attrs: {
                      formTitle: "Inviaci la tua richiesta",
                      label: "assistenza-segnala-un-problema",
                    },
                  }),
                  _c("br"),
                  _c("TornaIndietro"),
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c(
                "section",
                { attrs: { id: "contatti-contattaci" } },
                [
                  _c("h3", [_vm._v("Contattaci")]),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "6" } }, [
                        _c("h5", { staticClass: "contactTitle" }, [
                          _vm._v("Assistenza clienti"),
                        ]),
                        _c(
                          "p",
                          [
                            _c("SvgPhone"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "phone:" +
                                    _vm.config.site.phoneNumber.replace(
                                      /\s/g,
                                      ""
                                    ),
                                },
                              },
                              [_vm._v(_vm._s(_vm.config.site.phoneNumber))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c("SvgMail"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mail:" + _vm.config.site.emailSupport,
                                },
                              },
                              [_vm._v(_vm._s(_vm.config.site.emailSupport))]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("b-col", { attrs: { md: "6" } }, [
                        _c("h5", { staticClass: "contactTitle" }, [
                          _vm._v("Salabam srl"),
                        ]),
                        _c(
                          "p",
                          [
                            _c("SvgPin"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.config.site.address) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "6" } }, [
                        _c("h5", { staticClass: "contactTitle" }, [
                          _vm._v("Corporate"),
                        ]),
                        _c(
                          "p",
                          [
                            _c("SvgPhone"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "phone:" +
                                    _vm.config.site.phoneNumber.replace(
                                      /\s/g,
                                      ""
                                    ),
                                },
                              },
                              [_vm._v(_vm._s(_vm.config.site.phoneNumber))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c("SvgMail"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mail:" + _vm.config.site.emailBusiness,
                                },
                              },
                              [_vm._v(_vm._s(_vm.config.site.emailBusiness))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c("TornaIndietro"),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }