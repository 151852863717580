var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "200",
        height: "200",
        viewBox: "0 0 200 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M199.978 21.31C200.124 19.46 199.486 17.632 198.228 16.27C196.964 14.906 195.192 14.134 193.334 14.134H166.412C166.56 11.668 166.672 9.184 166.672 6.666C166.672 2.984 163.68 0 160.002 0H40.0005C36.3185 0 33.3345 2.984 33.3345 6.666C33.3345 9.186 33.4365 11.668 33.5885 14.134H6.66647C4.80847 14.134 3.03647 14.906 1.77447 16.27C0.514475 17.632 -0.125525 19.46 0.0204747 21.31C2.42047 52.562 21.4465 110.764 75.6025 113.846C83.0985 124.86 90.0005 136 90.0005 145.002C90.0005 172.576 52.2065 186.75 51.8205 186.84C48.5265 187.614 46.3285 190.74 46.7145 194.102C47.1045 197.462 49.9445 200 53.3345 200H146.664C146.716 199.996 146.762 200 146.806 200C150.492 200 153.474 197.022 153.474 193.334C153.474 189.964 150.98 187.19 147.75 186.736C143.574 185.642 110 171.122 110 145.002C110 136 116.9 124.86 124.402 113.846C178.56 110.764 197.58 52.562 199.978 21.31ZM14.2145 27.466H35.1445C39.8825 55.756 53.3405 80.008 65.6805 99.026C27.8245 89.862 17.0085 45.236 14.2145 27.466ZM185.794 27.466C182.99 45.236 172.172 89.862 134.32 99.026C146.662 80.008 160.116 55.756 164.848 27.466H185.794Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }