var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "doublecheck-card" }, [
    _c("div", { staticClass: "doublecheck--section" }, [
      _vm.suggestionSent
        ? _c("div", [
            _c("h2", {
              staticClass: "title",
              domProps: {
                innerHTML: _vm._s(_vm.strings.suggestion_sent[_vm.lang]),
              },
            }),
          ])
        : _vm.info.confirmed
        ? _c("div", [
            _c("h2", {
              staticClass: "title",
              domProps: {
                innerHTML: _vm._s(_vm.strings.confirm_title[_vm.lang]),
              },
            }),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(_vm.strings.confirm_subtitle[_vm.lang]),
              },
            }),
          ])
        : _c("div", [
            _c("h2", {
              staticClass: "title",
              domProps: {
                innerHTML: _vm._s(_vm.strings.simple_checkin_title[_vm.lang]),
              },
            }),
          ]),
    ]),
    !_vm.suggestionSent
      ? _c("div", { staticClass: "doublecheck--section" }, [
          _c("p", {
            staticClass: "subtitle",
            domProps: {
              innerHTML: _vm._s(_vm.strings.summary_title[_vm.lang]),
            },
          }),
          _c("table", { staticClass: "info" }, [
            _c("tr", [
              _c("td", {
                staticClass: "name",
                domProps: {
                  innerHTML: _vm._s(_vm.strings.itinerary[_vm.lang]),
                },
              }),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.itinerary_id)),
              ]),
            ]),
            _c("tr", [
              _c("td", {
                staticClass: "name",
                domProps: {
                  innerHTML: _vm._s(_vm.strings.confirmation_number[_vm.lang]),
                },
              }),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.confirmationNumber)),
              ]),
            ]),
            _c("tr", [
              _c("td", {
                staticClass: "name",
                domProps: { innerHTML: _vm._s(_vm.strings.guest[_vm.lang]) },
              }),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.bookingName)),
              ]),
            ]),
            _c("tr", [
              _c("td", {
                staticClass: "name",
                domProps: {
                  innerHTML: _vm._s(_vm.strings.guestPhone[_vm.lang]),
                },
              }),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.guestPhone)),
              ]),
            ]),
            _c("tr", [
              _c("td", {
                staticClass: "name",
                domProps: { innerHTML: _vm._s(_vm.strings.property[_vm.lang]) },
              }),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.property)),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "name" }, [_vm._v("check-in")]),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.checkin)),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "name" }, [_vm._v("check-out")]),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.checkout)),
              ]),
            ]),
            _c("tr", [
              _c("td", {
                staticClass: "name",
                domProps: { innerHTML: _vm._s(_vm.strings.room[_vm.lang]) },
              }),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.room)),
              ]),
            ]),
            _c("tr", [
              _c("td", {
                staticClass: "name",
                domProps: { innerHTML: _vm._s(_vm.strings.adults[_vm.lang]) },
              }),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.adults)),
              ]),
            ]),
            _c("tr", [
              _c("td", {
                staticClass: "name",
                domProps: { innerHTML: _vm._s(_vm.strings.children[_vm.lang]) },
              }),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.summary.children)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.info.confirmed
      ? _c(
          "div",
          { staticClass: "doublecheck--section" },
          [
            _c("p", {
              staticClass: "subtitle",
              domProps: {
                innerHTML: _vm._s(_vm.strings.check_subtitle[_vm.lang]),
              },
            }),
            _c(
              "b-button",
              { attrs: { variant: "primary" }, on: { click: _vm.confirm } },
              [_vm._v(_vm._s(_vm.strings.confirm_btn[_vm.lang]))]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.info.confirmed && _vm.info.askSuggestion
      ? _c(
          "div",
          { staticClass: "doublecheck--section" },
          [
            _c("p", {
              staticClass: "subtitle",
              domProps: {
                innerHTML: _vm._s(_vm.strings.suggestion_subtitle[_vm.lang]),
              },
            }),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(_vm.strings.suggestion_text[_vm.lang]),
              },
            }),
            _c("br"),
            _c(
              "b-form",
              [
                _c("table", { staticClass: "form" }, [
                  _c("tr", [
                    _c("td", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(_vm.strings.suggestion_name_title[_vm.lang]) +
                          "*"
                      ),
                    ]),
                    _c("td", { staticClass: "value" }, [
                      _c(
                        "div",
                        { staticClass: "customInputBlock" },
                        [
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.strings.suggestion_name_text[_vm.lang])
                            ),
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              required: true,
                              placeholder:
                                _vm.strings.suggestion_name_title[_vm.lang],
                            },
                            model: {
                              value: _vm.suggestion,
                              callback: function ($$v) {
                                _vm.suggestion = $$v
                              },
                              expression: "suggestion",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(_vm.strings.suggestion_why_title[_vm.lang]) + "*"
                      ),
                    ]),
                    _c("td", { staticClass: "value" }, [
                      _c(
                        "div",
                        { staticClass: "customInputBlock" },
                        [
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.strings.suggestion_why_text[_vm.lang])
                            ),
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              required: true,
                              placeholder:
                                _vm.strings.suggestion_why_title[_vm.lang],
                            },
                            model: {
                              value: _vm.why,
                              callback: function ($$v) {
                                _vm.why = $$v
                              },
                              expression: "why",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(_vm.strings.suggestion_link_title[_vm.lang])
                      ),
                    ]),
                    _c("td", { staticClass: "value" }, [
                      _c(
                        "div",
                        { staticClass: "customInputBlock" },
                        [
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(
                                _vm.strings.suggestion_link_text[_vm.lang]
                              ) +
                                " (" +
                                _vm._s(_vm.strings.optional[_vm.lang]) +
                                ")"
                            ),
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              required: false,
                              placeholder:
                                _vm.strings.suggestion_link_text[_vm.lang],
                            },
                            model: {
                              value: _vm.link,
                              callback: function ($$v) {
                                _vm.link = $$v
                              },
                              expression: "link",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.strings.your_name_title[_vm.lang])),
                    ]),
                    _c("td", { staticClass: "value" }, [
                      _c(
                        "div",
                        { staticClass: "customInputBlock" },
                        [
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.strings.your_name_title[_vm.lang]) +
                                " (" +
                                _vm._s(_vm.strings.optional[_vm.lang]) +
                                ")"
                            ),
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              required: false,
                              placeholder:
                                _vm.strings.your_name_title[_vm.lang],
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.strings.allow_publish_title[_vm.lang])),
                    ]),
                    _c("td", { staticClass: "value" }, [
                      _c(
                        "div",
                        { staticClass: "customInputBlock" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { required: false },
                              model: {
                                value: _vm.checkboxPublic,
                                callback: function ($$v) {
                                  _vm.checkboxPublic = $$v
                                },
                                expression: "checkboxPublic",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.strings.allow_publish_text[_vm.lang]
                                  ) +
                                  "    \n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "b-button",
                  {
                    attrs: {
                      disabled: !_vm.validateSuggestionFields(),
                      variant: "primary",
                    },
                    on: { click: _vm.submitSuggestion },
                  },
                  [_vm._v(_vm._s(_vm.strings.suggestion_send_btn[_vm.lang]))]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "doublecheck--section" }, [
      _c("p", {
        domProps: {
          innerHTML: _vm._s(_vm.strings.text_always_visible[_vm.lang]),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }