var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "blog-category " + _vm.categorySlug },
    [
      _c("BlogTopbar", {
        attrs: { categorySlug: _vm.categorySlug, goto: "blog" },
      }),
      _c(
        "b-container",
        { staticClass: "content", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "3" } },
                [
                  _vm.loadingTags
                    ? _c("Spinner")
                    : _c("div", { staticClass: "tags" }, [
                        _c("p", { staticClass: "tags-title" }, [
                          _vm._v("TAGS"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "tags-list" },
                          _vm._l(_vm.tags, function (tag, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                class:
                                  "tag " +
                                  (_vm.currentTagId == tag.id ? "active" : ""),
                                on: {
                                  click: function ($event) {
                                    return _vm.searchByTag(tag.id)
                                  },
                                },
                              },
                              [_vm._v("#" + _vm._s(tag.name))]
                            )
                          }),
                          0
                        ),
                      ]),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "postPads", attrs: { sm: "12", md: "9" } },
                [
                  _vm.loadingPosts
                    ? _c("Spinner")
                    : _c(
                        "div",
                        [
                          _vm.posts.length <= 12
                            ? _c(
                                "b-row",
                                _vm._l(_vm.posts, function (post, index) {
                                  return _c(
                                    "b-col",
                                    {
                                      key: "post_" + index,
                                      staticClass: "postPad",
                                      attrs: { sm: "12", md: "6", lg: "4" },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        { attrs: { to: post.url } },
                                        [
                                          _c("div", {
                                            staticClass: "postPad__thumbnail",
                                            style:
                                              "background-image:url(" +
                                              post.featuredMedia.src +
                                              ");",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "postPad__content" },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "postPad__title" },
                                              [_vm._v(_vm._s(post.title))]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c(
                                "b-row",
                                [
                                  _vm._l(
                                    _vm.posts.slice(0, 12),
                                    function (post, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "post_" + index,
                                          staticClass: "postPad",
                                          attrs: { sm: "12", md: "6", lg: "4" },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            { attrs: { to: post.url } },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "postPad__thumbnail",
                                                style:
                                                  "background-image:url(" +
                                                  post.featuredMedia.src +
                                                  ");",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "postPad__content" },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "postPad__title",
                                                  },
                                                  [_vm._v(_vm._s(post.title))]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  !_vm.showMore
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "load-more",
                                          attrs: { sm: "12" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showMore = true
                                            },
                                          },
                                        },
                                        [
                                          _c("SvgChevronDown"),
                                          _c("span", [_vm._v("Vedi altri")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.posts.slice(12, _vm.posts.length),
                                    function (post, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "post_" + index,
                                          class:
                                            "postPad " +
                                            (_vm.showMore ? "" : "hidden"),
                                          attrs: { sm: "12", md: "6", lg: "4" },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            { attrs: { to: post.url } },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "postPad__thumbnail",
                                                style:
                                                  "background-image:url(" +
                                                  post.featuredMedia.src +
                                                  ");",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "postPad__content" },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "postPad__title",
                                                  },
                                                  [_vm._v(_vm._s(post.title))]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }