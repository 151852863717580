<template>
    <b-container>
        <div class="text-center">
            <span v-if="canUseEtc">
                <br><br>
                Attendi, stiamo effettuando la connessione con Edenred...
                <br><br>
                <Spinner />
                <br><br>
            </span>
            <span v-else>
                <br><br>
                Ticket Compliments &reg; non disponibili.
                <br><br><br>
            </span>
        </div>
    </b-container>
</template>

<script>
import Api from '../../../api'
import Spinner from '../../atoms/spinner'


export default {
    name: 'ticket-compliments',
    components:
    {
        Spinner
    },
    data()
    {
        return {
            canUseEtc: true      
        }
    },
    mounted()
    {
        if(!this.mixinUseTicketCompliments())
        {
            this.canUseEtc = false
            return
        }

        if(!this.$route.query.code)
        {
            this.canUseEtc = false
            return
        }
        
        if(this.canUseEtc)
        {
            let code = this.$route.query.code
            if(code)
            {
                this.handleLoginCallback(code)
            }            
        }

    },
    methods:
    {
        handleLoginCallback: function(code)
        {
            Api
                .getAccessToken({code: code})
                .then((data) => {

                    if(this.$gtm) this.$gtm.trackEvent({
                      event: 'customEvent',
                      category: 'partnerBehaviour',
                      action: 'etc',
                      label: 'logged',
                      value: 1,
                    });

                    if(window.LogRocket) window.LogRocket.track('etc_logged')

                    //window.console.log(data)

                    window.parent.postMessage({
                        from: 'loginEdenred',
                        data: data,
                    })

                    // window.location.href = '/?token='+data.token
                    // oppure direttamente:
                    // window.location.href = data.redirectTo
                })
        }
    }
}
</script>

<style lang="scss">
/*
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
*/
</style>