var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "200",
        height: "177",
        viewBox: "0 0 200 177",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M199.406 42.1393L181.672 3.88726C180.514 1.38993 178.012 0 175.262 0H112.196C112.186 0 112.181 0 112.163 0H49.9948C49.9771 0 49.9695 0 49.9594 0H27.7206C25.1198 0 22.7335 1.21083 21.5075 3.50383L0.832615 41.8619C0.222156 43.0121 0.0682795 44.1952 0.118731 45.406C-0.239473 47.3282 0.217112 49.3236 1.43046 50.9279L94.4902 173.961C95.817 175.724 97.9007 176.743 100.115 176.743C102.328 176.743 104.416 175.704 105.746 173.943L198.611 50.8926C199.754 49.3715 200.185 47.4846 199.928 45.6558C200.031 44.4828 199.938 43.2846 199.406 42.1393ZM139.102 15.1353L131.133 33.096L123.157 15.1353H139.102ZM92.3208 15.1353H107.729L119.226 35.3158H80.8229L92.3208 15.1353ZM76.8953 15.1353L68.919 33.096L60.9527 15.1353H76.8953ZM31.9358 15.1353H45.5248L57.0201 35.3158H18.0112L31.9358 15.1353ZM21.2275 50.4512H63.2407L86.0522 136.165L21.2275 50.4512ZM100.017 133.817L77.8438 50.4512H122.188L100.017 133.817ZM114.302 135.938L136.793 50.4512H178.819L114.302 135.938ZM143.037 35.3158L154.53 15.1353H170.75L182.704 35.3158H143.037Z",
          fill: "#E8785A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }