var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "254",
        height: "200",
        viewBox: "0 0 254 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0 54.1899V200H253.631V54.1899V0H0V54.1899ZM11.1732 11.1732H242.458V43.0168H11.1732V11.1732ZM11.1732 54.1899H242.458V188.827H11.1732V54.1899Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.257 36.8714C31.5021 36.8714 35.7542 32.6193 35.7542 27.3742C35.7542 22.129 31.5021 17.877 26.257 17.877C21.0118 17.877 16.7598 22.129 16.7598 27.3742C16.7598 32.6193 21.0118 36.8714 26.257 36.8714Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M55.3078 36.8714C60.5529 36.8714 64.805 32.6193 64.805 27.3742C64.805 22.129 60.5529 17.877 55.3078 17.877C50.0626 17.877 45.8105 22.129 45.8105 27.3742C45.8105 32.6193 50.0626 36.8714 55.3078 36.8714Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M84.0753 36.8714C89.3205 36.8714 93.5725 32.6193 93.5725 27.3742C93.5725 22.129 89.3205 17.877 84.0753 17.877C78.8302 17.877 74.5781 22.129 74.5781 27.3742C74.5781 32.6193 78.8302 36.8714 84.0753 36.8714Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M106.704 84.3579H94.4137C76.816 84.3579 62.0115 97.2071 59.4975 114.246H36.0339C32.9612 114.246 30.4473 116.76 30.4473 119.833C30.4473 122.905 32.9612 125.419 36.0339 125.419H59.4975C62.2908 142.458 76.816 155.308 94.4137 155.308H106.704C109.777 155.308 112.291 152.794 112.291 149.721V89.9445C112.291 86.8719 109.777 84.3579 106.704 84.3579ZM101.118 144.134H94.4137C81.0059 144.134 70.1121 133.241 70.1121 119.833C70.1121 106.425 81.0059 95.5311 94.4137 95.5311H101.118V144.134Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M217.876 114.246H194.413C191.619 97.2071 177.094 84.3579 159.496 84.3579H147.206C144.133 84.3579 141.619 86.8719 141.619 89.9445V99.1624H128.491C125.418 99.1624 122.904 101.676 122.904 104.749C122.904 107.822 125.418 110.336 128.491 110.336H141.619V129.609H128.491C125.418 129.609 122.904 132.123 122.904 135.196C122.904 138.269 125.418 140.782 128.491 140.782H141.619V150C141.619 153.073 144.133 155.587 147.206 155.587H159.496C177.094 155.587 191.899 142.738 194.413 125.699H217.876C220.949 125.699 223.463 123.185 223.463 120.112C223.463 117.039 220.949 114.246 217.876 114.246ZM159.217 144.134H152.513V95.5311H159.217C172.625 95.5311 183.519 106.425 183.519 119.833C183.798 133.241 172.625 144.134 159.217 144.134Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }