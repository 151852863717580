var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "riscattaSalabam" }, [
    _c(
      "section",
      [
        _c(
          "b-container",
          [
            _c(
              "b-row",
              [
                _c("b-col", [
                  _c("h4", { staticClass: "titleText text-center black" }, [
                    _vm._v(
                      "\n                    Hai generato un voucher Edenred?\n                "
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                  _c("h5", { staticClass: "titleText text-center black" }, [
                    _vm._v(
                      "\n                    Inseriscilo in questa pagina per ottenere in pochi istanti il tuo Salabam\n                "
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("ul", { staticClass: "listDisc" }, [
                    _c("li", [
                      _vm._v(
                        "\n                        tutte le disponibilità che vedrai sono reali e confermate senza necessità di telefonate o verifiche con la struttura\n                    "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n                        quando prenoterai il tuo viaggio, controlla se la camera che stai acquistando è rimborsabile o non-rimborsabile\n                    "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n                        anche a causa delle difficoltà a viaggiare causate dal Covid-19 abbiamo prolungato la validità del voucher a 16 mesi\n                    "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n                        per ogni camera disponibile sono specificati i servizi inclusi\n                    "
                      ),
                    ]),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                  _c("h5", { staticClass: "titleText text-center black" }, [
                    _vm._v(
                      "\n                    Dove trovo il codice voucher Edenred?\n                "
                    ),
                  ]),
                  _c("br"),
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n                    Trovi il codice nella pagina di generazione del voucher sul sito Edenred o sul PDF che hai ricevuto a seguito della generazione\n                "
                    ),
                  ]),
                  _c("p", { staticClass: "text-center" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/riscatta-salabam.png"),
                      },
                    }),
                  ]),
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "12", lg: "6" } },
                  [
                    _c("h5", { staticClass: "titleText text-center black" }, [
                      _vm._v(
                        "\n                    Inserisci i tuoi dati\n                "
                      ),
                    ]),
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n                    Tutti i campi obbligatori\n                "
                      ),
                    ]),
                    _c("br"),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "redeemFields",
                        attrs: { state: _vm.validateFields() },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "customInputBlock" },
                          [
                            _c("SvgInfoCircle", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    "Inserisci il codice voucher generato sul sito Edenred",
                                  expression:
                                    "'Inserisci il codice voucher generato sul sito Edenred'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "svgInfo cursor-pointer",
                            }),
                            _c("b-form-input", {
                              ref: "redeemCode",
                              attrs: {
                                required: true,
                                type: "text",
                                placeholder: "Codice voucher Edenred",
                              },
                              on: { input: _vm.formatRedeemCode },
                              model: {
                                value: _vm.redeemCode,
                                callback: function ($$v) {
                                  _vm.redeemCode = $$v
                                },
                                expression: "redeemCode",
                              },
                            }),
                            _c("span", { staticClass: "formError" }),
                            _c("span", {
                              staticClass: "formClear",
                              on: {
                                click: function ($event) {
                                  return _vm.clearField("redeemCode")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "customInputBlock" },
                          [
                            _c("SvgInfoCircle", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value: "Inserisci il nome dell'utilizzatore",
                                  expression:
                                    "'Inserisci il nome dell\\'utilizzatore'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "svgInfo cursor-pointer",
                            }),
                            _c("b-form-input", {
                              ref: "nome",
                              attrs: {
                                required: true,
                                type: "text",
                                placeholder: "Nome",
                              },
                              model: {
                                value: _vm.nome,
                                callback: function ($$v) {
                                  _vm.nome = $$v
                                },
                                expression: "nome",
                              },
                            }),
                            _c("span", { staticClass: "formError" }),
                            _c("span", {
                              staticClass: "formClear",
                              on: {
                                click: function ($event) {
                                  return _vm.clearField("nome")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "customInputBlock" },
                          [
                            _c("SvgInfoCircle", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    "Inserisci il cognome dell'utilizzatore",
                                  expression:
                                    "'Inserisci il cognome dell\\'utilizzatore'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "svgInfo cursor-pointer",
                            }),
                            _c("b-form-input", {
                              ref: "cognome",
                              attrs: {
                                required: true,
                                type: "text",
                                placeholder: "Cognome",
                              },
                              model: {
                                value: _vm.cognome,
                                callback: function ($$v) {
                                  _vm.cognome = $$v
                                },
                                expression: "cognome",
                              },
                            }),
                            _c("span", { staticClass: "formError" }),
                            _c("span", {
                              staticClass: "formClear",
                              on: {
                                click: function ($event) {
                                  return _vm.clearField("cognome")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "customInputBlock" },
                          [
                            _c("SvgInfoCircle", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    "Inserisci l'indirizzo email dell'utilizzatore",
                                  expression:
                                    "'Inserisci l\\'indirizzo email dell\\'utilizzatore'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "svgInfo cursor-pointer",
                            }),
                            _c("b-form-input", {
                              ref: "email",
                              attrs: {
                                required: true,
                                type: "email",
                                placeholder: "Indirizzo email",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _c("span", { staticClass: "formError" }),
                            _c("span", {
                              staticClass: "formClear",
                              on: {
                                click: function ($event) {
                                  return _vm.clearField("email")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "customInputBlock" },
                          [
                            _c("SvgInfoCircle", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    "Ripeti l'indirizzo email dell'utilizzatore",
                                  expression:
                                    "'Ripeti l\\'indirizzo email dell\\'utilizzatore'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "svgInfo cursor-pointer",
                            }),
                            _c("b-form-input", {
                              ref: "emailConf",
                              attrs: {
                                required: true,
                                type: "email",
                                placeholder: "Ripeti indirizzo email",
                              },
                              model: {
                                value: _vm.emailConf,
                                callback: function ($$v) {
                                  _vm.emailConf = $$v
                                },
                                expression: "emailConf",
                              },
                            }),
                            _c("span", { staticClass: "formError" }),
                            _c("span", {
                              staticClass: "formClear",
                              on: {
                                click: function ($event) {
                                  return _vm.clearField("emailConf")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "div",
                          { staticClass: "checkboxes" },
                          [
                            _c(
                              "div",
                              { staticClass: "customCheckboxBlock" },
                              [
                                _c("b-form-checkbox", {
                                  ref: "checkboxPrivacy",
                                  attrs: {
                                    required: "",
                                    type: "checkbox",
                                    name: "checkboxPrivacy",
                                    id: "checkboxPrivacy",
                                  },
                                  model: {
                                    value: _vm.checkboxPrivacy,
                                    callback: function ($$v) {
                                      _vm.checkboxPrivacy = $$v
                                    },
                                    expression: "checkboxPrivacy",
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "checkboxPrivacy" } },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "secondary",
                                        attrs: {
                                          href: _vm.privacyPolicyUrl,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Ho preso visione della privacy policy e la accetto"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "customCheckboxBlock" },
                              [
                                _c("b-form-checkbox", {
                                  ref: "checkboxTerms",
                                  attrs: {
                                    required: "",
                                    type: "checkbox",
                                    name: "checkboxTerms",
                                    id: "checkboxTerms",
                                  },
                                  model: {
                                    value: _vm.checkboxTerms,
                                    callback: function ($$v) {
                                      _vm.checkboxTerms = $$v
                                    },
                                    expression: "checkboxTerms",
                                  },
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "checkboxTerms" } },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "nounderline",
                                        attrs: {
                                          to: { name: "terms" },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "secondary" },
                                          [
                                            _vm._v(
                                              "Ho preso visione delle condizioni d'uso e le accetto"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("br"),
                            !_vm.loading
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center",
                                  },
                                  [
                                    _vm.recaptchaEnabled
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "btn-block",
                                            attrs: {
                                              disabled: !_vm.validateFields(),
                                            },
                                            on: { click: _vm.recaptcha },
                                          },
                                          [_vm._v("CONFERMA")]
                                        )
                                      : _c(
                                          "b-button",
                                          {
                                            staticClass: "btn-block",
                                            attrs: {
                                              disabled: !_vm.validateFields(),
                                            },
                                            on: { click: _vm.redeem },
                                          },
                                          [_vm._v("CONFERMA")]
                                        ),
                                  ],
                                  1
                                )
                              : _c("Spinner"),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }