<template>
    <div class="accordion" role="tablist">
        <b-card v-for="(item,index) in items" v-bind:key="index" no-body>
            <b-card-header header-tag="header" role="tab">
                <b-button block href="#" v-b-toggle="accordionId+'-' + index" variant="gold">{{item.title}}</b-button>
            </b-card-header>
            <b-collapse :id="accordionId+'-'+index" accordion="accordion" role="tabpanel">
                <b-card-body>
                    <b-card-text v-html="item.content"></b-card-text>
                    <!--<TornaIndietro v-if="showTornaIndietro" />-->
                    <p class="text-center">
                        <b-button size="sm" v-b-toggle="accordionId+'-' + index">Chiudi</b-button>
                    </p>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>
<script>
//import TornaIndietro from '@/components/blocks/common/torna-indietro'
export default {
    name: 'accordion',
    props:
    {
        items: Array,
        showTornaIndietro: {
          type: Boolean,
          default: false,
        },
        accordionId: {
          type: String,
          value: 'accordion'
        },
    },
  components:{
    //TornaIndietro,
  }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .accordion
    {
        .card-header
        {
            padding:1rem 0.5rem;
            height:auto;
            a
            {
                box-shadow: none;
                padding:0;
            }
            *
            {
                font-size: 18px;
                color:$secondary;
                font-weight:bold;
            }
        }
        .card-text
        {
            font-size: 16px;
            line-height: 140%;
        }
    }
</style>