<template>
    <div class="publicPage landingPage landingEtcEdenred">
        <div id="hero" :style="'background-image:url('+require('../../../assets/landing/hero-landing-default.jpg')+')'">
            <b-container fluid>
                <b-row>
                    <b-col sm="12" lg="6" class="heroCol">
                        <SvgEdenred />
                    </b-col>
                    <b-col sm="12" lg="6" class="heroCol">
                        <div class="panel panel-white large-radius">
                            <div class="panel-content">
                                <b-row>
                                    <b-col sm="12" md="6" class="value">
                                        <div class="valueIcon spin"><SvgPurchaseSpin /></div>
                                        <div class="valueText"><p><strong>I Salabam sono voucher per prenotare viaggi online</strong></p></div>
                                    </b-col>
                                    <b-col sm="12" md="6" class="value">
                                        <div class="valueIcon ticket"><SvgTicket /></div>
                                        <div class="valueText"><p><strong>Voucher da 75 a 3.500€ che potrai acquistare usando i tuoi Ticket Compliments e la tua carta di credito</strong></p></div>
                                    </b-col>
                                    <b-col sm="12" md="6" class="value">
                                        <div class="valueIcon bed"><SvgPurchaseBed /></div>
                                        <div class="valueText"><p><strong>450.000 hotel, bed&breakfast e appartamenti in Italia e nel mondo prenotabili con pochi click</strong></p></div>
                                    </b-col>
                                    <b-col sm="12" md="6" class="value">
                                        <div class="valueIcon whatsapp"><SvgWhatsapp /></div>
                                        <div class="valueText"><p><strong>Un servizio di supporto a tua disposizione via chat, Whatsapp, telefono e email</strong></p></div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <b-container class="pageContent">

            <section>
                <div class="boxed">
                    <p>
                        I Salabam sono voucher che permettono di prenotare soggiorni su disponibilità aggiornate in tempo reale e a conferma immediata.
                    </p>
                </div>
            </section>

            <section>
                <div class="infoBox">
                    <div class="header">
                        <div class="boxed">
                            <SvgWarningExplamation /> Perché scegliere Salabam?
                        </div>
                    </div>
                    <div class="content">
                        <div class="boxed">
                            <b-row>
                                <b-col sm="12" lg="6">
                                    <div class="infoIcon">
                                        <SvgCheckedContractCircle />
                                    </div>
                                    <div class="infoHighlight">
                                        <CopyScadenza
                                            default="Validità {value} mesi"
                                            promoMonths="Validità {value} mesi"
                                            promoFixed="Validità fino al {value}"
                                        />
                                    </div>
                                    <div class="infoContent">
                                        <p>
                                            <CopyScadenza />
                                        </p>
                                        <p>
                                            Ricordati che sia la prenotazione sia il viaggio dovranno essere fatti entro la data indicata nel voucher.
                                        </p>                                            
                                    </div>
                                </b-col>
                                <b-col sm="12" lg="6">
                                    <div class="infoIcon">
                                        <SvgRefundCircle />
                                    </div>
                                    <div class="infoHighlight">
                                        Viaggia rimborsabile!
                                    </div>
                                    <div class="infoContent">
                                        <p>
                                            Su Salabam da diverse settimane trovi una grande quantità di soggiorni di tipo rimborsabile.
                                        </p>
                                        <p>
                                            Prenota soggiorni RIMBORSABILI e potrai cancellare ogni prenotazione ottenendo la riattivazione del voucher per un nuovo booking
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div class="footer">
                        <!--<div class="boxed">footer</div>-->
                    </div>
                </div>
            </section>

            <section>
                <div class="boxed">
                    <h4>
                        Come acquistare Salabam con i tuoi Ticket Compliments?
                    </h4>

                    <br>

                    <div class="checklist">
                        <ul>
                            <li>
                                <span class="checklist_index">1</span>
                                <div class="checklist_item">
                                    <span class="checklist_title">SCEGLI IL VOUCHER SALABAM</span>
                                    <p>
                                        Clicca sul bottone INIZIA qui sotto, effettua il login con le tue credenziali Edenred e trova il voucher adatto al tuo budget e alle tue necessità.
                                        Puoi vedere già ora le disponibilità in oltre 450.000 hotel, B&B, appartamenti in Italia e nel mondo.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <span class="checklist_index">2</span>
                                <div class="checklist_item">
                                    <span class="checklist_title">ACQUISTALO CON I TUOI TICKET COMPLIMENTS</span>
                                    <p>
                                        Al momento del pagamento, seleziona i Ticket Compliments che vuoi utilizzare.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <span class="checklist_index">3</span>
                                <div class="checklist_item">
                                    <span class="checklist_title">PRENOTA IL TUO VIAGGIO ONLINE</span>
                                    <p>
                                        Riceverai per email il voucher che hai acquistato. Basterà seguire il link per prenotare il il tuo soggiorno. 
                                    </p>

                                    <p>
                                        <CopyScadenza
                                            default="Avrai {value} mesi per effettuare la tua prenotazione e viaggiare scegliendo tra disponibilità aggiornate in tempo reale e a conferma immediata."
                                        />
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div class="boxed text-center">
                    <p>
                        Clicca il bottone per effettuare il login e e scopri i voucher e le disponibilità
                    </p>

                    <br>

                    <EdenredLoginModal :hasButton="'INIZIA'"/>

                </div>
            </section>

        </b-container>
    </div>
</template>

<script>
import CopyScadenza from '../../blocks/common/copy-scadenza'
import EdenredLoginModal from '../../blocks/common/edenred-login-modal'
import SvgPurchaseSpin from '../../svg/purchase-spin'
import SvgPurchaseBed from '../../svg/purchase-bed'
import SvgTicket from '../../svg/ticket'
import SvgWhatsapp from '../../svg/whatsapp'
import SvgCheckedContractCircle from '../../svg/checked-contract-circle'
import SvgRefundCircle from '../../svg/refund-circle'
import SvgWarningExplamation from '../../svg/warning-exclamation'
import SvgEdenred from '@/components/svg/etc-logo-transparent'

export default {
    name: 'landing-etc-edenred',
    components:
    {
        CopyScadenza,
        EdenredLoginModal,
        SvgPurchaseSpin,
        SvgPurchaseBed,
        SvgTicket,
        SvgWhatsapp,
        SvgCheckedContractCircle,
        SvgRefundCircle,
        SvgWarningExplamation,
        SvgEdenred
    },
    data()
    {
        return {
            }
    },
    mounted()
    {
    },
    methods:
    {
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .landingEtcEdenred
    {
        .heroLogo
        {
            width: 300px;
        }
        .edenredLoginModal button
        {
            min-width:200px;
        }
    }
</style>