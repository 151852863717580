<template>
    <b-container class="text-center">

        <br><br>
        <div v-if="data == '' || data == undefined">
            C'è stato un problema, devi accedere dal portale Jointly
        </div>
        
        <div v-else>
            Accesso in corso...
            <br><br>
            <Spinner v-if="loading" />
            <!--
            <div v-else>
                <p>jpToken: {{jpToken}}</p>
            </div>
            -->
        </div>
        <br><br>
    </b-container>
</template>

<script>
import Vue from 'vue'
//import Router from 'vue-router'
import Spinner from '../../atoms/spinner'
import Api from '../../../api'

export default {
    name: 'jp-sso',
    components:
    {
        Spinner,
    },
    props:
    {
    },
    data()
    {
        return {
            data: '',
            loading: true,
        }
    },
    mounted()
    {
        //  http://localhost:8081/jp-sso?data=YXQ9anRfdG9rXzE2MDcwNzkwMDEmc2VydmljZV9pZD0xJmNiPWNiJnRva2VuX3VybD1odHRwcyUzQSUyRiUyRnNhbGFiYW0tZGV2Lm1pZ2l0ZWNoLml0JTJGanBfc2ltdWxhdG9yJTJG
        this.data = this.$router.currentRoute.query.data
        this.jpSso()
    },
    methods:
    {
        jpSso()
        {
            Api.jpSso({
                app: Vue.prototype.$config.app,
                data: this.data,
            })
            .then((results) => {
              window.location.href = '/?token='+results.token
            })
        }
    },
}
</script>