var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "228",
        height: "200",
        viewBox: "0 0 228 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M214.018 172.93C209.398 172.93 204.778 172.93 200.159 172.93C188.517 172.93 176.874 172.93 165.231 172.93C149.89 172.93 134.55 172.93 119.209 172.93C103.494 172.93 87.7785 172.93 72.0637 172.93C59.2993 172.93 46.5348 172.93 33.7704 172.93C27.2807 172.93 20.791 172.93 14.3016 172.93C14.0459 172.93 13.7904 172.93 13.5347 172.93C18.0464 177.442 22.5578 181.953 27.0695 186.465C27.0695 181.213 27.0695 175.96 27.0695 170.708C27.0695 157.929 27.0695 145.15 27.0695 132.371C27.0695 116.532 27.0695 100.693 27.0695 84.8538C27.0695 70.4219 27.0695 55.99 27.0695 41.5581C27.0695 33.0003 27.0695 24.4425 27.0695 15.8845C27.0695 15.1011 27.0695 14.318 27.0695 13.5347C27.0695 6.15661 20.9128 0 13.5347 0C6.15661 0 0 6.15661 0 13.5347C0 18.7869 0 24.0394 0 29.2918C0 42.071 0 54.8502 0 67.6292C0 83.4682 0 99.3069 0 115.146C0 129.578 0 144.01 0 158.442C0 166.999 0 175.557 0 184.115C0 184.899 0 185.682 0 186.465C0 193.843 6.15661 200 13.5347 200C18.1544 200 22.7739 200 27.3936 200C39.0361 200 50.6786 200 62.3211 200C77.662 200 93.0031 200 108.344 200C124.059 200 139.774 200 155.489 200C168.253 200 181.018 200 193.782 200C200.272 200 206.762 200 213.251 200C213.507 200 213.762 200 214.018 200C221.396 200 227.553 193.843 227.553 186.465C227.553 179.087 221.396 172.93 214.018 172.93Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M69.2691 144.555C75.5605 136.605 81.852 128.656 88.1432 120.706C96.2515 110.46 104.36 100.215 112.468 89.9696C112.892 89.4337 113.316 88.8977 113.74 88.3618C107.36 88.3618 100.98 88.3618 94.5993 88.3618C103.791 95.7155 112.984 103.069 122.176 110.423C122.651 110.803 123.127 111.184 123.602 111.564C124.515 112.27 125.429 112.975 126.341 113.681C128.452 114.905 130.73 115.521 133.173 115.529C136.804 115.529 140.148 114.065 142.743 111.564C149.235 105.309 155.727 99.0533 162.219 92.7976C173.118 82.2956 184.016 71.7939 194.915 61.2921C198.278 58.0515 201.641 54.8107 205.004 51.5701C210.316 46.452 210.146 37.5713 205.004 32.4291C199.717 27.1423 191.172 27.3132 185.863 32.4291C179.371 38.6848 172.879 44.9405 166.387 51.196C155.489 61.6979 144.59 72.1997 133.692 82.7014C130.329 85.942 126.966 89.1828 123.603 92.4234C129.983 92.4234 136.363 92.4234 142.743 92.4234C133.551 85.0697 124.359 77.716 115.167 70.3626C114.691 69.9822 114.216 69.6017 113.74 69.2211C112.827 68.5158 111.914 67.8103 111.001 67.1048C108.89 65.8806 106.613 65.2646 104.17 65.2568C100.944 65.2568 96.6967 66.5712 94.5995 69.2211C88.3081 77.171 82.0166 85.1207 75.7254 93.0705C67.6171 103.316 59.5088 113.561 51.4006 123.807C50.9766 124.343 50.5525 124.879 50.1283 125.414C49.423 126.327 48.7175 127.241 48.012 128.153C46.7879 130.264 46.1718 132.542 46.1641 134.985C46.1641 138.56 47.6014 142.029 50.1283 144.555C52.6552 147.082 56.1234 148.52 59.6988 148.52C62.9239 148.52 67.1719 147.205 69.2691 144.555Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M158.429 55.4684C170.187 55.4684 181.946 55.4684 193.704 55.4684C194.312 55.4684 194.921 55.4684 195.529 55.4684C191.017 50.9567 186.506 46.4453 181.994 41.9337C181.994 53.6917 181.994 65.45 181.994 77.2083C181.994 77.8166 181.994 78.425 181.994 79.0333C181.994 86.4114 188.151 92.568 195.529 92.568C202.907 92.568 209.064 86.4114 209.064 79.0333C209.064 67.2753 209.064 55.517 209.064 43.7587C209.064 43.1503 209.064 42.542 209.064 41.9337C209.064 34.5555 202.907 28.3989 195.529 28.3989C183.771 28.3989 172.013 28.3989 160.255 28.3989C159.646 28.3989 159.038 28.3989 158.429 28.3989C151.051 28.3989 144.895 34.5555 144.895 41.9337C144.895 49.3118 151.051 55.4684 158.429 55.4684Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }