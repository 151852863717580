var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage landingPage landingUpDay" },
    [
      _c(
        "div",
        {
          style:
            "background-image:url(" +
            require("../../../assets/landing/hero-landing-default.jpg") +
            ")",
          attrs: { id: "hero" },
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "heroCol", attrs: { sm: "12", lg: "6" } },
                    [
                      _c("img", {
                        staticClass: "heroLogo",
                        attrs: {
                          src: require("../../../assets/landing/up-day.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "heroCol", attrs: { sm: "12", lg: "6" } },
                    [
                      _c(
                        "div",
                        { staticClass: "panel panel-white large-radius" },
                        [
                          _c(
                            "div",
                            { staticClass: "panel-content" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "value",
                                      attrs: { sm: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "valueIcon spin" },
                                        [_c("SvgPurchaseSpin")],
                                        1
                                      ),
                                      _c("div", { staticClass: "valueText" }, [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "I Salabam sono voucher per prenotare viaggi online"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "value",
                                      attrs: { sm: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "valueIcon ticket" },
                                        [_c("SvgTicket")],
                                        1
                                      ),
                                      _c("div", { staticClass: "valueText" }, [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "Voucher da 75 a 3.500€ che potrai acquistare usando il tuo credito welfare Up Day"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "value",
                                      attrs: { sm: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "valueIcon bed" },
                                        [_c("SvgPurchaseBed")],
                                        1
                                      ),
                                      _c("div", { staticClass: "valueText" }, [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "450.000 hotel, bed&breakfast e appartamenti in Italia e nel mondo prenotabili con pochi click"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "value",
                                      attrs: { sm: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "valueIcon whatsapp" },
                                        [_c("SvgWhatsapp")],
                                        1
                                      ),
                                      _c("div", { staticClass: "valueText" }, [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "Un servizio di supporto a tua disposizione via chat, Whatsapp, telefono e email"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-container", { staticClass: "pageContent" }, [
        _c("section", [
          _c("div", { staticClass: "boxed" }, [
            _c("p", [
              _vm._v(
                "\n                    I Salabam sono voucher che permettono di prenotare soggiorni su disponibilità aggiornate in tempo reale e a conferma immediata.\n                "
              ),
            ]),
          ]),
        ]),
        _c("section", [
          _c("div", { staticClass: "infoBox" }, [
            _c("div", { staticClass: "header" }, [
              _c(
                "div",
                { staticClass: "boxed" },
                [
                  _c("SvgWarningExplamation"),
                  _vm._v(" Perché scegliere Salabam?\n                    "),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "boxed" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "infoIcon" },
                          [_c("SvgCheckedContractCircle")],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "infoHighlight" },
                          [
                            _c("CopyScadenza", {
                              attrs: {
                                default: "Validità {value} mesi",
                                promoMonths: "Validità {value} mesi",
                                promoFixed: "Validità fino al {value}",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "infoContent" }, [
                          _c("p", [_c("CopyScadenza")], 1),
                          _c("p", [
                            _vm._v(
                              "\n                                        Ricordati che sia la prenotazione sia il viaggio dovranno essere fatti entro la data indicata nel voucher.\n                                    "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "infoIcon" },
                          [_c("SvgRefundCircle")],
                          1
                        ),
                        _c("div", { staticClass: "infoHighlight" }, [
                          _vm._v(
                            "\n                                    Viaggia rimborsabile!\n                                "
                          ),
                        ]),
                        _c("div", { staticClass: "infoContent" }, [
                          _c("p", [
                            _vm._v(
                              "\n                                        Su Salabam da diverse settimane trovi una grande quantità di soggiorni di tipo rimborsabile.\n                                    "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "\n                                        Prenota soggiorni RIMBORSABILI e potrai cancellare ogni prenotazione ottenendo la riattivazione del voucher per un nuovo booking\n                                    "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "footer" }),
          ]),
        ]),
        _c("section", [
          _c("div", { staticClass: "boxed" }, [
            _c("h4", [
              _vm._v(
                "\n                    Come si acquista un voucher Salabam su Day Welfare?\n                "
              ),
            ]),
            _c("br"),
            _c("div", { staticClass: "checklist" }, [
              _c("ul", [
                _c("li", [
                  _c("span", { staticClass: "checklist_index" }, [_vm._v("1")]),
                  _c("div", { staticClass: "checklist_item" }, [
                    _c("span", { staticClass: "checklist_title" }, [
                      _vm._v("SCEGLI IL VOUCHER SALABAM"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n                                    Clicca sul bottone INIZIA in fondo alla pagina e trova il voucher adatto al tuo budget e alle tue necessità.\n                                    "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n                                    Puoi vedere in tempo reale le disponibilità in oltre 450.000 hotel, B&B, appartamenti in Italia e nel mondo.\n                                "
                      ),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "checklist_index" }, [_vm._v("2")]),
                  _c("div", { staticClass: "checklist_item" }, [
                    _c("span", { staticClass: "checklist_title" }, [
                      _vm._v("EFFETTUA L’ORDINE SUL PORTALE WELFARE"),
                    ]),
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n                                       Torna sul portale Day Welfare e ordina il voucher.\n                                    "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "checklist_index" }, [_vm._v("3")]),
                  _c("div", { staticClass: "checklist_item" }, [
                    _c("span", { staticClass: "checklist_title" }, [
                      _vm._v("PRENOTA IL TUO VIAGGIO ONLINE"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n                                    Riceverai per email il voucher che hai acquistato. Basterà seguire il link per prenotare il il tuo soggiorno. \n                                "
                      ),
                    ]),
                    _c(
                      "p",
                      [
                        _c("CopyScadenza", {
                          attrs: {
                            default:
                              "Avrai {value} mesi per effettuare la tua prenotazione e viaggiare scegliendo tra disponibilità aggiornate in tempo reale e a conferma immediata.",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("section", [
          _c(
            "div",
            { staticClass: "boxed text-center" },
            [
              _c("p", [
                _vm._v(
                  "\n                    Clicca il bottone e scopri i voucher e le disponibilità\n                "
                ),
              ]),
              _c("br"),
              _c(
                "b-button",
                { attrs: { variant: "secondary" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "white",
                      attrs: { to: { name: "homepage" } },
                    },
                    [_vm._v("INIZIA!")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }