<template>
    <div class="doublecheck-card">

        <!-- intestazione (contestuale al fatto se la prenotazione è stata confermata o meno) -->
        <div class="doublecheck--section">
            <div v-if="suggestionSent">
                <h2 class="title" v-html="strings.suggestion_sent[lang]"></h2>
            </div>
            <div v-else-if="info.confirmed">
                <h2 class="title" v-html="strings.confirm_title[lang]"></h2>
                <p v-html="strings.confirm_subtitle[lang]"></p>
            </div>
            <div v-else>
                <h2 class="title" v-html="strings.simple_checkin_title[lang]"></h2>
            </div>
        </div>

        <!-- fascia recap prenotazione sempre visibile tranne dopo aver inviato una suggestion -->
        <div v-if="!suggestionSent" class="doublecheck--section">
            <p class="subtitle" v-html="strings.summary_title[lang]"></p>
            <table class="info">
                <tr>
                    <td class="name" v-html="strings.itinerary[lang]"></td>
                    <td class="value">{{info.summary.itinerary_id}}</td>
                </tr>
                <tr>
                    <td class="name" v-html="strings.confirmation_number[lang]"></td>
                    <td class="value">{{info.summary.confirmationNumber}}</td>
                </tr>
                <tr>
                    <td class="name" v-html="strings.guest[lang]"></td>
                    <td class="value">{{info.summary.bookingName}}</td>
                </tr>
                <tr>
                  <td class="name" v-html="strings.guestPhone[lang]"></td>
                  <td class="value">{{info.summary.guestPhone}}</td>
                </tr>
                <tr>
                    <td class="name" v-html="strings.property[lang]"></td>
                    <td class="value">{{info.summary.property}}</td>
                </tr>
                <tr>
                    <td class="name">check-in</td>
                    <td class="value">{{info.summary.checkin}}</td>
                </tr>
                <tr>
                    <td class="name">check-out</td>
                    <td class="value">{{info.summary.checkout}}</td>
                </tr>
                <tr>
                    <td class="name" v-html="strings.room[lang]"></td>
                    <td class="value">{{info.summary.room}}</td>
                </tr>
                <tr>
                    <td class="name" v-html="strings.adults[lang]"></td>
                    <td class="value">{{info.summary.adults}}</td>
                </tr>
                <tr>
                    <td class="name" v-html="strings.children[lang]"></td>
                    <td class="value">{{info.summary.children}}</td>
                </tr>
            </table>
        </div>

        <!-- fascia conferma prenotazione -->
        <div v-if="!info.confirmed" class="doublecheck--section">
            <p class="subtitle" v-html="strings.check_subtitle[lang]"></p>
            <b-button @click="confirm" variant="primary">{{strings.confirm_btn[lang]}}</b-button>
        </div>

        <!-- fascia suggestion dopo conferma prenotazione -->
        <div v-if="info.confirmed && info.askSuggestion" class="doublecheck--section">
            <p class="subtitle" v-html="strings.suggestion_subtitle[lang]"></p>
            <p v-html="strings.suggestion_text[lang]"></p>

            <br>

            <b-form>
                <table class="form">
                    <tr>
                        <td class="name">{{strings.suggestion_name_title[lang]}}*</td>
                        <td class="value">
                            <div class="customInputBlock">
                                <b-form-text>{{strings.suggestion_name_text[lang]}}</b-form-text>
                                <b-form-input :required="true" v-model="suggestion" :placeholder="strings.suggestion_name_title[lang]" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="name">{{strings.suggestion_why_title[lang]}}*</td>
                        <td class="value">
                            <div class="customInputBlock">
                                <b-form-text>{{strings.suggestion_why_text[lang]}}</b-form-text>
                                <b-form-input :required="true" v-model="why" :placeholder="strings.suggestion_why_title[lang]" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="name">{{strings.suggestion_link_title[lang]}}</td>
                        <td class="value">
                            <div class="customInputBlock">
                                <b-form-text>{{strings.suggestion_link_text[lang]}} ({{strings.optional[lang]}})</b-form-text>
                                <b-form-input :required="false" v-model="link" :placeholder="strings.suggestion_link_text[lang]" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="name">{{strings.your_name_title[lang]}}</td>
                        <td class="value">
                            <div class="customInputBlock">
                                <b-form-text>{{strings.your_name_title[lang]}} ({{strings.optional[lang]}})</b-form-text>
                                <b-form-input :required="false" v-model="name" :placeholder="strings.your_name_title[lang]" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="name">{{strings.allow_publish_title[lang]}}</td>
                        <td class="value">
                            <div class="customInputBlock">
                                <b-form-checkbox :required="false" v-model="checkboxPublic">
                                    {{strings.allow_publish_text[lang]}}    
                                </b-form-checkbox>
                            </div>
                        </td>
                    </tr>
                </table>

                <b-button :disabled="!validateSuggestionFields()" @click="submitSuggestion" variant="primary">{{strings.suggestion_send_btn[lang]}}</b-button>
            </b-form>
        </div>

        <!-- fascia info e contatti sempre visibile -->
        <div class="doublecheck--section">
            <p v-html="strings.text_always_visible[lang]"> </p>
        </div>

    </div>
</template>

<script>
    
    import Vue from 'vue'
    //import Spinner from '@/components/atoms/spinner'

    export default {
        name: 'doublecheck-card',
        components:
        {
            //Spinner,
        },
        props:
        {
            lang: String,
            info: Object,
            strings: Object,
            suggestionSent: Boolean,
        },
        data()
        {
            return {
                config: Vue.prototype.$config,
                loading: true,
                suggestion: '',
                why: '',
                link: '',
                name: '',
                checkboxPublic: false,
            }
        },
        methods:
        {
            validateSuggestionFields()
            {
                if (this.suggestion == '') return false
                if (this.why == '') return false
                //if (!this.checkboxPublic) return false

                return true
            },
            confirm()
            {
                this.$emit('confirm')
            },
            submitSuggestion()
            {
                this.$emit(
                    'submitSuggestion',
                    {
                        suggestion: this.suggestion,
                        why: this.why,
                        link: this.link,
                        name: this.name,
                        checkboxPublic: this.checkboxPublic,
                    }
                )
            },
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .doublecheck
    {
        .content
        {
            padding:2rem 0 3rem 0;
            @include media-breakpoint-up('md')
            {
                padding:4rem 0 6rem 0;
            }
        }

        .title
        {
            color:$primary;
            margin:3.5rem 0;
            text-align: center;
        }

        .subtitle
        {
            color:$black;
            font-weight:bold;
            margin-bottom:2rem;
            font-size: 1.25rem;
        }

        &--section
        {
            display:block;
            text-align: center;
            margin-bottom:2rem;
            @include media-breakpoint-up('md')
            {
                padding:0 5%;
                margin-bottom:4rem;
            }

            table
            {
                margin:0 auto;
                max-width: 600px;
                td
                {
                    padding:0.25rem 1rem;
                    text-align: left;
                }
                .name
                {
                    font-weight:600;
                }
                .customInputBlock
                {
                    margin-bottom:1rem;
                    input
                    {
                        border:1px solid $gray-200;
                    }
                    .form-text
                    {
                        padding-bottom:0.35rem;
                        font-weight:normal;
                    }
                    .custom-checkbox
                    {
                        .custom-control-label
                        {
                            font-size: 0.85rem;
                            line-height: 140%;
                        }
                    }
                }

                &.info
                {
                    td
                    {
                        width:50%;
                    }
                }

                &.form
                {
                    td
                    {
                        vertical-align: middle;
                    }
                    tr:last-child td
                    {
                        padding-top: 1rem;
                        vertical-align: top;
                    }
                    .value
                    {
                        flex:1;
                        padding-bottom:1rem;
                    }
                }
            }
        }
    }
</style>