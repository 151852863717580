var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { staticClass: "text-center" }, [
    _c("br"),
    _c("br"),
    _vm.data == "" || _vm.data == undefined
      ? _c("div", [
          _vm._v(
            "\n        C'è stato un problema, devi accedere dal portale Jointly\n    "
          ),
        ])
      : _c(
          "div",
          [
            _vm._v("\n        Accesso in corso...\n        "),
            _c("br"),
            _c("br"),
            _vm.loading ? _c("Spinner") : _vm._e(),
          ],
          1
        ),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }