<template>
    <div id="page-error">
        <b-container>
            <b-row>
                <b-col lg="8" offset-lg="2">
                    <h4 class="secondary">Info</h4>
                    <br>
                    <p v-html="info"></p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { Base64 } from 'js-base64'
export default {
    name: 'info',
    metaInfo(){
      return {
        title: 'info',
        meta: [
          { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: '404' },
          { vmid: 'robots', name: 'robots', content: 'noindex' },
        ]
      }
    },
    data()
    {
        return {
            info: Base64.decode(this.$route.query.info)
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    #page-error
    {
        padding-top:$grid-gutter-width;
        margin-bottom:6rem;
        @include media-breakpoint-up(lg)
        {
            /*margin-top:$page-header-desktop-height;*/
            margin-bottom:8rem;
        }
    }
</style>